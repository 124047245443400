/* Vendor */
/* Vendors */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Libraries */
@font-face {
  font-family: 'Lausanne';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Lausanne/Lausanne-Light.eot");
  src: url("../fonts/Lausanne/Lausanne-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Lausanne/Lausanne-Light.woff2") format("woff2"), url("../fonts/Lausanne/Lausanne-Light.woff") format("woff"), url("../fonts/Lausanne/Lausanne-Light.ttf") format("truetype"), url("../fonts/Lausanne/Lausanne-Light.svg#codropsicons") format("svg"); }

@font-face {
  font-family: 'Lausanne Extended';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/Lausanne/Lausanne-Light-Extended.eot");
  src: url("../fonts/Lausanne/Lausanne-Light-Extended.eot?#iefix") format("embedded-opentype"), url("../fonts/Lausanne/Lausanne-Light-Extended.woff2") format("woff2"), url("../fonts/Lausanne/Lausanne-Light-Extended.woff") format("woff"), url("../fonts/Lausanne/Lausanne-Light-Extended.ttf") format("truetype"), url("../fonts/Lausanne/Lausanne-Light-Extended.svg#codropsicons") format("svg"); }

@font-face {
  font-family: 'Reckless Neue';
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/RecklessNeue/RecklessNeue-Light.eot");
  src: url("../fonts/RecklessNeue/RecklessNeue-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/RecklessNeue/RecklessNeue-Light.woff2") format("woff2"), url("../fonts/RecklessNeue/RecklessNeue-Light.woff") format("woff"), url("../fonts/RecklessNeue/RecklessNeue-Light.ttf") format("truetype"), url("../fonts/RecklessNeue/RecklessNeue-Light.svg#codropsicons") format("svg"); }

@font-face {
  font-family: 'Futura Maxi';
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/FuturaMaxi/FuturaMaxi-Demi.eot");
  src: url("../fonts/FuturaMaxi/FuturaMaxi-Demi.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaMaxi/FuturaMaxi-Demi.woff2") format("woff2"), url("../fonts/FuturaMaxi/FuturaMaxi-Demi.woff") format("woff"), url("../fonts/FuturaMaxi/FuturaMaxi-Demi.otf") format("opentype"), url("../fonts/FuturaMaxi/FuturaMaxi-Demi.svg#codropsicons") format("svg"); }

@font-face {
  font-family: 'Futura Maxi';
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/FuturaMaxi/FuturaMaxi-Book.eot");
  src: url("../fonts/FuturaMaxi/FuturaMaxi-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaMaxi/FuturaMaxi-Book.woff2") format("woff2"), url("../fonts/FuturaMaxi/FuturaMaxi-Book.woff") format("woff"), url("../fonts/FuturaMaxi/FuturaMaxi-Book.otf") format("opentype"), url("../fonts/FuturaMaxi/FuturaMaxi-Book.svg#codropsicons") format("svg"); }

@font-face {
  font-family: 'Moderat';
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Moderat/Moderat-Regular.eot");
  src: url("../fonts/Moderat/Moderat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Moderat/Moderat-Regular.woff2") format("woff2"), url("../fonts/Moderat/Moderat-Regular.woff") format("woff"), url("../fonts/Moderat/Moderat-Regular.ttf") format("truetype"), url("../fonts/Moderat/Moderat-Regular.svg#codropsicons") format("svg"); }

/* SASS partial for grunt-webfont, autogenerated, do not edit! */
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("eot"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.iconcss {
  font-family: "icons";
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-right:before {
  content: "\EA01"; }

.icon-caret-right:before {
  content: "\EA02"; }

.icon-download:before {
  content: "\EA03"; }

.icon-arrow-right-thin:before {
  content: "\EA04"; }

.icon-discover:before {
  content: "\EA05"; }

.icon-ideate:before {
  content: "\EA06"; }

.icon-wireframe:before {
  content: "\EA07"; }

.icon-prototype:before {
  content: "\EA08"; }

.icon-user-testing:before {
  content: "\EA09"; }

.icon-experience-mapping:before {
  content: "\EA10"; }

.icon-refining:before {
  content: "\EA11"; }

.icon-backlog:before {
  content: "\EA12"; }

.grid {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0 72px;
  max-width: 1440px; }
  .grid__row {
    display: flex;
    margin-bottom: 48px;
    flex-wrap: wrap;
    min-width: 100%;
    width: 100%; }
  .grid__item {
    display: flex;
    flex-direction: column; }
    .grid__item--col-1 {
      min-width: 8.33%;
      width: 8.33%; }
    .grid__item--col-2 {
      min-width: 16.66%;
      width: 16.66%; }
    .grid__item--col-3 {
      min-width: 25%;
      width: 25%; }
    .grid__item--col-4 {
      min-width: 33.33%;
      width: 33.33%; }
    .grid__item--col-5 {
      min-width: 41.66%;
      width: 41.66%; }
    .grid__item--col-6 {
      min-width: 50%;
      width: 50%; }
    .grid__item--col-7 {
      min-width: 58.33%;
      width: 58.33%; }
    .grid__item--col-8 {
      min-width: 66.66%;
      width: 66.66%; }
    .grid__item--col-9 {
      min-width: 75%;
      width: 75%; }
    .grid__item--col-10 {
      min-width: 83.33%;
      width: 83.33%; }
    .grid__item--col-11 {
      min-width: 91.66%;
      width: 91.66%; }
    .grid__item--col-12 {
      min-width: 100%;
      width: 100%; }

body, button, input, select, textarea {
  font-variant-ligatures: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  font-family: "Lausanne", sans-serif;
  letter-spacing: -0.006em; }

html {
  font-size: 8px; }

.h1, .h2, h3, h4, h5, h6, blockquote, p {
  color: inherit; }
  .h1.uppercase, .h2.uppercase, h3.uppercase, h4.uppercase, h5.uppercase, h6.uppercase, blockquote.uppercase, p.uppercase {
    font-family: "Lausanne Extended", sans-serif;
    letter-spacing: 0.025rem;
    text-transform: uppercase; }
  .h1.initial-caps, .h2.initial-caps, h3.initial-caps, h4.initial-caps, h5.initial-caps, h6.initial-caps, blockquote.initial-caps, p.initial-caps {
    text-transform: capitalize; }
  .h1.mr, .h2.mr, h3.mr, h4.mr, h5.mr, h6.mr, blockquote.mr, p.mr {
    margin-right: 8.3333%; }
  .h1.light, .h2.light, h3.light, h4.light, h5.light, h6.light, blockquote.light, p.light {
    font-weight: 400; }
  .active-section section .h1.fade, .active-section section .h2.fade, .active-section section h3.fade, .active-section section h4.fade, .active-section section h5.fade, .active-section section h6.fade, .active-section section blockquote.fade, .active-section section p.fade {
    opacity: 0.48; }
  .h1 .outline, .h2 .outline, h3 .outline, h4 .outline, h5 .outline, h6 .outline, blockquote .outline, p .outline {
    color: transparent;
    -webkit-text-stroke: 1px; }

.h1, .h2, h3, h4, h5, h6 {
  position: relative; }
  .h1 span, .h2 span, h3 span, h4 span, h5 span, h6 span {
    display: inline-block;
    white-space: pre; }

.h1, .h2, h3, h4 {
  font-weight: 400; }

h5, h6 {
  font-family: "Lausanne Extended", sans-serif; }

.h1 {
  font-size: 16rem;
  line-height: 1;
  margin-bottom: 48px;
  letter-spacing: -0.2rem; }

.h2 {
  font-size: 14rem;
  line-height: 1;
  margin-bottom: 36px;
  letter-spacing: -0.15rem; }

h3 {
  font-size: 13rem;
  line-height: 1;
  margin-bottom: 24px;
  letter-spacing: -0.1rem; }

h4 {
  font-size: 5rem;
  line-height: 1;
  margin-bottom: 16px;
  letter-spacing: 0rem; }

h5 {
  font-size: 2.375rem;
  line-height: 1.3;
  margin-bottom: 8px;
  letter-spacing: 0rem; }

h6 {
  font-size: 1.875rem;
  line-height: 1.3;
  margin-bottom: 8px; }

p, blockquote {
  white-space: pre-wrap; }

p {
  font-size: 2.125rem;
  line-height: 1.61803;
  margin-bottom: 24px;
  letter-spacing: 0.025rem; }

blockquote {
  font-size: 2.75rem;
  line-height: 1.61803;
  margin-bottom: 36px;
  letter-spacing: 0.025rem; }

figcaption {
  font-size: 1.75rem;
  margin-top: 24px; }

.drop-caps {
  display: inline-block; }
  .drop-caps:first-letter {
    float: left;
    font-size: 60px;
    font-family: "Lausanne", sans-serif;
    font-weight: 800;
    margin: 0 24px 0 0;
    padding-top: 9px;
    line-height: 48px; }

* {
  box-sizing: border-box; }

html {
  background: #232021; }

body {
  background: #fefefe;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: none !important;
  color: #232021; }

ul {
  margin-left: 30px; }
  ul.ls {
    list-style: disc; }

a {
  cursor: none;
  color: #232021;
  text-decoration: none;
  outline: none; }

hr {
  opacity: 0.05;
  background: #131112;
  height: 1px;
  width: 100%;
  min-width: 60px;
  border: 0;
  display: inline-block; }

video {
  position: relative;
  width: 100%; }

iframe {
  position: relative;
  width: 0;
  min-width: 100%;
  max-width: 100%; }

*::-webkit-scrollbar {
  width: 0px;
  background: transparent; }

.disabled {
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.24;
  pointer-events: none; }

section {
  padding: 120px 0;
  position: relative;
  overflow: hidden;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fefefe;
  background-size: cover;
  background-position: center;
  transition: background-color cubic-bezier(0.19, 1, 0.22, 1) 0.9s; }
  section img {
    width: 100%; }
  section .spacer__sm {
    min-height: 60px; }
  section .spacer__md {
    min-height: 120px; }
  section .spacer__lg {
    min-height: 240px; }
  section .shift-left--lg {
    transform: translateX(-100%); }
  section .shift-left--md {
    transform: translateX(-50%); }
  section .shift-left--sm {
    transform: translateX(-33.3333%); }
  section .shift-left--xs {
    transform: translateX(-25%); }
  section .shift-right--lg {
    transform: translateX(100%); }
  section .shift-right--md {
    transform: translateX(50%); }
  section .shift-right--sm {
    transform: translateX(33.3333%); }
  section .shift-right--xs {
    transform: translateX(25%); }

.scrolling-section > div {
  position: relative; }

.scrolling-section .icon {
  stroke: #131112; }

.scrolling-section__number {
  transform: translate(-24px, 0); }

.scrolling-section .outline {
  -webkit-text-stroke-color: #131112; }

.scrolling-section--black section {
  background-color: #131112; }

.scrolling-section--black h1, .scrolling-section--black h2, .scrolling-section--black h3, .scrolling-section--black h4, .scrolling-section--black h5, .scrolling-section--black h6, .scrolling-section--black p, .scrolling-section--black blockquote, .scrolling-section--black a, .scrolling-section--black figcaption {
  color: #fefefe; }

.scrolling-section--black .outline {
  -webkit-text-stroke-color: #fefefe; }

.scrolling-section--black hr {
  background-color: #fefefe; }

.scrolling-section--black .grid__line {
  background-color: rgba(254, 254, 254, 0.05); }

.scrolling-section--black text {
  fill: #fefefe; }

.scrolling-section--black .icon {
  stroke: #fefefe; }

.scrolling-section--grey section {
  background-color: #f0f0f0; }

.scrolling-section--grey h1, .scrolling-section--grey h2, .scrolling-section--grey h3, .scrolling-section--grey h4, .scrolling-section--grey h5, .scrolling-section--grey h6, .scrolling-section--grey p, .scrolling-section--grey blockquote, .scrolling-section--grey a {
  color: #232021; }

h1 span, h2 span, h3 span, h4 span, p span, blockquote span {
  display: inline-block; }

@keyframes ghostIn {
  0% {
    transform: translate3d(0, 128px, 0) skewY(0deg); }
  100% {
    opacity: 1;
    transform: none; } }

@keyframes ghostOut {
  0% {
    transform: none; }
  100% {
    transform: translate3d(0, 128px, 0) skewY(0deg); } }

.scale-up {
  transition: cubic-bezier(0.19, 1, 0.22, 1) 0.6s transform; }
  .scale-up--sm:hover {
    transform: scale(3); }
  .scale-up--lg:hover {
    transform: scale(6); }

section .image-wrapper {
  opacity: 0;
  transition: none;
  transform: translate3d(0, 50%, 0) skewY(6deg); }

section blockquote {
  transition: none;
  opacity: 0; }

section .grid__item .grid__item:nth-child(1) .image-wrapper,
section .grid__item:nth-child(1) .image-wrapper {
  transition-delay: 1.2s; }

section .grid__item .grid__item:nth-child(1) blockquote,
section .grid__item:nth-child(1) blockquote {
  transition-delay: 0.6s; }

section .grid__item .grid__item:nth-child(2) .image-wrapper,
section .grid__item:nth-child(2) .image-wrapper {
  transition-delay: 1.5s; }

section .grid__item .grid__item:nth-child(2) blockquote,
section .grid__item:nth-child(2) blockquote {
  transition-delay: 0.9s; }

section .grid__item .grid__item:nth-child(3) .image-wrapper,
section .grid__item:nth-child(3) .image-wrapper {
  transition-delay: 1.8s; }

section .grid__item .grid__item:nth-child(3) blockquote,
section .grid__item:nth-child(3) blockquote {
  transition-delay: 1.2s; }

section .grid__item .grid__item:nth-child(4) .image-wrapper,
section .grid__item:nth-child(4) .image-wrapper {
  transition-delay: 2.1s; }

section .grid__item .grid__item:nth-child(4) blockquote,
section .grid__item:nth-child(4) blockquote {
  transition-delay: 1.5s; }

section .grid__item .grid__item:nth-child(5) .image-wrapper,
section .grid__item:nth-child(5) .image-wrapper {
  transition-delay: 2.4s; }

section .grid__item .grid__item:nth-child(5) blockquote,
section .grid__item:nth-child(5) blockquote {
  transition-delay: 1.8s; }

section .grid__item .grid__item:nth-child(6) .image-wrapper,
section .grid__item:nth-child(6) .image-wrapper {
  transition-delay: 2.7s; }

section .grid__item .grid__item:nth-child(6) blockquote,
section .grid__item:nth-child(6) blockquote {
  transition-delay: 2.1s; }

section .grid__item .grid__item:nth-child(7) .image-wrapper,
section .grid__item:nth-child(7) .image-wrapper {
  transition-delay: 3s; }

section .grid__item .grid__item:nth-child(7) blockquote,
section .grid__item:nth-child(7) blockquote {
  transition-delay: 2.4s; }

section .grid__item .grid__item:nth-child(8) .image-wrapper,
section .grid__item:nth-child(8) .image-wrapper {
  transition-delay: 3.3s; }

section .grid__item .grid__item:nth-child(8) blockquote,
section .grid__item:nth-child(8) blockquote {
  transition-delay: 2.7s; }

section .grid__item .grid__item:nth-child(9) .image-wrapper,
section .grid__item:nth-child(9) .image-wrapper {
  transition-delay: 3.6s; }

section .grid__item .grid__item:nth-child(9) blockquote,
section .grid__item:nth-child(9) blockquote {
  transition-delay: 3s; }

section .grid__item .grid__item:nth-child(10) .image-wrapper,
section .grid__item:nth-child(10) .image-wrapper {
  transition-delay: 3.9s; }

section .grid__item .grid__item:nth-child(10) blockquote,
section .grid__item:nth-child(10) blockquote {
  transition-delay: 3.3s; }

section .grid__item .grid__item:nth-child(11) .image-wrapper,
section .grid__item:nth-child(11) .image-wrapper {
  transition-delay: 4.2s; }

section .grid__item .grid__item:nth-child(11) blockquote,
section .grid__item:nth-child(11) blockquote {
  transition-delay: 3.6s; }

section .grid__item .grid__item:nth-child(12) .image-wrapper,
section .grid__item:nth-child(12) .image-wrapper {
  transition-delay: 4.5s; }

section .grid__item .grid__item:nth-child(12) blockquote,
section .grid__item:nth-child(12) blockquote {
  transition-delay: 3.9s; }

section .grid__item .grid__item:nth-child(13) .image-wrapper,
section .grid__item:nth-child(13) .image-wrapper {
  transition-delay: 4.8s; }

section .grid__item .grid__item:nth-child(13) blockquote,
section .grid__item:nth-child(13) blockquote {
  transition-delay: 4.2s; }

section .grid__item .grid__item:nth-child(14) .image-wrapper,
section .grid__item:nth-child(14) .image-wrapper {
  transition-delay: 5.1s; }

section .grid__item .grid__item:nth-child(14) blockquote,
section .grid__item:nth-child(14) blockquote {
  transition-delay: 4.5s; }

section .grid__item .grid__item:nth-child(15) .image-wrapper,
section .grid__item:nth-child(15) .image-wrapper {
  transition-delay: 5.4s; }

section .grid__item .grid__item:nth-child(15) blockquote,
section .grid__item:nth-child(15) blockquote {
  transition-delay: 4.8s; }

section .grid__item .grid__item:nth-child(16) .image-wrapper,
section .grid__item:nth-child(16) .image-wrapper {
  transition-delay: 5.7s; }

section .grid__item .grid__item:nth-child(16) blockquote,
section .grid__item:nth-child(16) blockquote {
  transition-delay: 5.1s; }

section .grid__item .grid__item:nth-child(17) .image-wrapper,
section .grid__item:nth-child(17) .image-wrapper {
  transition-delay: 6s; }

section .grid__item .grid__item:nth-child(17) blockquote,
section .grid__item:nth-child(17) blockquote {
  transition-delay: 5.4s; }

section .grid__item .grid__item:nth-child(18) .image-wrapper,
section .grid__item:nth-child(18) .image-wrapper {
  transition-delay: 6.3s; }

section .grid__item .grid__item:nth-child(18) blockquote,
section .grid__item:nth-child(18) blockquote {
  transition-delay: 5.7s; }

section .grid__item .grid__item:nth-child(19) .image-wrapper,
section .grid__item:nth-child(19) .image-wrapper {
  transition-delay: 6.6s; }

section .grid__item .grid__item:nth-child(19) blockquote,
section .grid__item:nth-child(19) blockquote {
  transition-delay: 6s; }

section .grid__item .grid__item:nth-child(20) .image-wrapper,
section .grid__item:nth-child(20) .image-wrapper {
  transition-delay: 6.9s; }

section .grid__item .grid__item:nth-child(20) blockquote,
section .grid__item:nth-child(20) blockquote {
  transition-delay: 6.3s; }

section .grid__item .grid__item:nth-child(21) .image-wrapper,
section .grid__item:nth-child(21) .image-wrapper {
  transition-delay: 7.2s; }

section .grid__item .grid__item:nth-child(21) blockquote,
section .grid__item:nth-child(21) blockquote {
  transition-delay: 6.6s; }

section .grid__item .grid__item:nth-child(22) .image-wrapper,
section .grid__item:nth-child(22) .image-wrapper {
  transition-delay: 7.5s; }

section .grid__item .grid__item:nth-child(22) blockquote,
section .grid__item:nth-child(22) blockquote {
  transition-delay: 6.9s; }

section .grid__item .grid__item:nth-child(23) .image-wrapper,
section .grid__item:nth-child(23) .image-wrapper {
  transition-delay: 7.8s; }

section .grid__item .grid__item:nth-child(23) blockquote,
section .grid__item:nth-child(23) blockquote {
  transition-delay: 7.2s; }

section .grid__item .grid__item:nth-child(24) .image-wrapper,
section .grid__item:nth-child(24) .image-wrapper {
  transition-delay: 8.1s; }

section .grid__item .grid__item:nth-child(24) blockquote,
section .grid__item:nth-child(24) blockquote {
  transition-delay: 7.5s; }

section .grid__item .grid__item:nth-child(25) .image-wrapper,
section .grid__item:nth-child(25) .image-wrapper {
  transition-delay: 8.4s; }

section .grid__item .grid__item:nth-child(25) blockquote,
section .grid__item:nth-child(25) blockquote {
  transition-delay: 7.8s; }

section .grid__item .grid__item:nth-child(26) .image-wrapper,
section .grid__item:nth-child(26) .image-wrapper {
  transition-delay: 8.7s; }

section .grid__item .grid__item:nth-child(26) blockquote,
section .grid__item:nth-child(26) blockquote {
  transition-delay: 8.1s; }

section .grid__item .grid__item:nth-child(27) .image-wrapper,
section .grid__item:nth-child(27) .image-wrapper {
  transition-delay: 9s; }

section .grid__item .grid__item:nth-child(27) blockquote,
section .grid__item:nth-child(27) blockquote {
  transition-delay: 8.4s; }

section .grid__item .grid__item:nth-child(28) .image-wrapper,
section .grid__item:nth-child(28) .image-wrapper {
  transition-delay: 9.3s; }

section .grid__item .grid__item:nth-child(28) blockquote,
section .grid__item:nth-child(28) blockquote {
  transition-delay: 8.7s; }

section .grid__item .grid__item:nth-child(29) .image-wrapper,
section .grid__item:nth-child(29) .image-wrapper {
  transition-delay: 9.6s; }

section .grid__item .grid__item:nth-child(29) blockquote,
section .grid__item:nth-child(29) blockquote {
  transition-delay: 9s; }

section .grid__item .grid__item:nth-child(30) .image-wrapper,
section .grid__item:nth-child(30) .image-wrapper {
  transition-delay: 9.9s; }

section .grid__item .grid__item:nth-child(30) blockquote,
section .grid__item:nth-child(30) blockquote {
  transition-delay: 9.3s; }

section .grid__item .grid__item:nth-child(31) .image-wrapper,
section .grid__item:nth-child(31) .image-wrapper {
  transition-delay: 10.2s; }

section .grid__item .grid__item:nth-child(31) blockquote,
section .grid__item:nth-child(31) blockquote {
  transition-delay: 9.6s; }

section .grid__item .grid__item:nth-child(32) .image-wrapper,
section .grid__item:nth-child(32) .image-wrapper {
  transition-delay: 10.5s; }

section .grid__item .grid__item:nth-child(32) blockquote,
section .grid__item:nth-child(32) blockquote {
  transition-delay: 9.9s; }

section .grid__item .grid__item:nth-child(33) .image-wrapper,
section .grid__item:nth-child(33) .image-wrapper {
  transition-delay: 10.8s; }

section .grid__item .grid__item:nth-child(33) blockquote,
section .grid__item:nth-child(33) blockquote {
  transition-delay: 10.2s; }

section .grid__item .grid__item:nth-child(34) .image-wrapper,
section .grid__item:nth-child(34) .image-wrapper {
  transition-delay: 11.1s; }

section .grid__item .grid__item:nth-child(34) blockquote,
section .grid__item:nth-child(34) blockquote {
  transition-delay: 10.5s; }

section .grid__item .grid__item:nth-child(35) .image-wrapper,
section .grid__item:nth-child(35) .image-wrapper {
  transition-delay: 11.4s; }

section .grid__item .grid__item:nth-child(35) blockquote,
section .grid__item:nth-child(35) blockquote {
  transition-delay: 10.8s; }

section .grid__item .grid__item:nth-child(36) .image-wrapper,
section .grid__item:nth-child(36) .image-wrapper {
  transition-delay: 11.7s; }

section .grid__item .grid__item:nth-child(36) blockquote,
section .grid__item:nth-child(36) blockquote {
  transition-delay: 11.1s; }

section .grid__item .grid__item:nth-child(37) .image-wrapper,
section .grid__item:nth-child(37) .image-wrapper {
  transition-delay: 12s; }

section .grid__item .grid__item:nth-child(37) blockquote,
section .grid__item:nth-child(37) blockquote {
  transition-delay: 11.4s; }

section .grid__item .grid__item:nth-child(38) .image-wrapper,
section .grid__item:nth-child(38) .image-wrapper {
  transition-delay: 12.3s; }

section .grid__item .grid__item:nth-child(38) blockquote,
section .grid__item:nth-child(38) blockquote {
  transition-delay: 11.7s; }

section .grid__item .grid__item:nth-child(39) .image-wrapper,
section .grid__item:nth-child(39) .image-wrapper {
  transition-delay: 12.6s; }

section .grid__item .grid__item:nth-child(39) blockquote,
section .grid__item:nth-child(39) blockquote {
  transition-delay: 12s; }

section .grid__item .grid__item:nth-child(40) .image-wrapper,
section .grid__item:nth-child(40) .image-wrapper {
  transition-delay: 12.9s; }

section .grid__item .grid__item:nth-child(40) blockquote,
section .grid__item:nth-child(40) blockquote {
  transition-delay: 12.3s; }

section .grid__item .grid__item:nth-child(41) .image-wrapper,
section .grid__item:nth-child(41) .image-wrapper {
  transition-delay: 13.2s; }

section .grid__item .grid__item:nth-child(41) blockquote,
section .grid__item:nth-child(41) blockquote {
  transition-delay: 12.6s; }

section .grid__item .grid__item:nth-child(42) .image-wrapper,
section .grid__item:nth-child(42) .image-wrapper {
  transition-delay: 13.5s; }

section .grid__item .grid__item:nth-child(42) blockquote,
section .grid__item:nth-child(42) blockquote {
  transition-delay: 12.9s; }

section .grid__item .grid__item:nth-child(43) .image-wrapper,
section .grid__item:nth-child(43) .image-wrapper {
  transition-delay: 13.8s; }

section .grid__item .grid__item:nth-child(43) blockquote,
section .grid__item:nth-child(43) blockquote {
  transition-delay: 13.2s; }

section .grid__item .grid__item:nth-child(44) .image-wrapper,
section .grid__item:nth-child(44) .image-wrapper {
  transition-delay: 14.1s; }

section .grid__item .grid__item:nth-child(44) blockquote,
section .grid__item:nth-child(44) blockquote {
  transition-delay: 13.5s; }

section .grid__item .grid__item:nth-child(45) .image-wrapper,
section .grid__item:nth-child(45) .image-wrapper {
  transition-delay: 14.4s; }

section .grid__item .grid__item:nth-child(45) blockquote,
section .grid__item:nth-child(45) blockquote {
  transition-delay: 13.8s; }

section .grid__item .grid__item:nth-child(46) .image-wrapper,
section .grid__item:nth-child(46) .image-wrapper {
  transition-delay: 14.7s; }

section .grid__item .grid__item:nth-child(46) blockquote,
section .grid__item:nth-child(46) blockquote {
  transition-delay: 14.1s; }

section .grid__item .grid__item:nth-child(47) .image-wrapper,
section .grid__item:nth-child(47) .image-wrapper {
  transition-delay: 15s; }

section .grid__item .grid__item:nth-child(47) blockquote,
section .grid__item:nth-child(47) blockquote {
  transition-delay: 14.4s; }

section .grid__item .grid__item:nth-child(48) .image-wrapper,
section .grid__item:nth-child(48) .image-wrapper {
  transition-delay: 15.3s; }

section .grid__item .grid__item:nth-child(48) blockquote,
section .grid__item:nth-child(48) blockquote {
  transition-delay: 14.7s; }

section .grid__item .grid__item:nth-child(49) .image-wrapper,
section .grid__item:nth-child(49) .image-wrapper {
  transition-delay: 15.6s; }

section .grid__item .grid__item:nth-child(49) blockquote,
section .grid__item:nth-child(49) blockquote {
  transition-delay: 15s; }

section .grid__item .grid__item:nth-child(50) .image-wrapper,
section .grid__item:nth-child(50) .image-wrapper {
  transition-delay: 15.9s; }

section .grid__item .grid__item:nth-child(50) blockquote,
section .grid__item:nth-child(50) blockquote {
  transition-delay: 15.3s; }

section .grid__item .grid__item:nth-child(51) .image-wrapper,
section .grid__item:nth-child(51) .image-wrapper {
  transition-delay: 16.2s; }

section .grid__item .grid__item:nth-child(51) blockquote,
section .grid__item:nth-child(51) blockquote {
  transition-delay: 15.6s; }

section .grid__item .grid__item:nth-child(52) .image-wrapper,
section .grid__item:nth-child(52) .image-wrapper {
  transition-delay: 16.5s; }

section .grid__item .grid__item:nth-child(52) blockquote,
section .grid__item:nth-child(52) blockquote {
  transition-delay: 15.9s; }

section .grid__item .grid__item:nth-child(53) .image-wrapper,
section .grid__item:nth-child(53) .image-wrapper {
  transition-delay: 16.8s; }

section .grid__item .grid__item:nth-child(53) blockquote,
section .grid__item:nth-child(53) blockquote {
  transition-delay: 16.2s; }

section .grid__item .grid__item:nth-child(54) .image-wrapper,
section .grid__item:nth-child(54) .image-wrapper {
  transition-delay: 17.1s; }

section .grid__item .grid__item:nth-child(54) blockquote,
section .grid__item:nth-child(54) blockquote {
  transition-delay: 16.5s; }

section .grid__item .grid__item:nth-child(55) .image-wrapper,
section .grid__item:nth-child(55) .image-wrapper {
  transition-delay: 17.4s; }

section .grid__item .grid__item:nth-child(55) blockquote,
section .grid__item:nth-child(55) blockquote {
  transition-delay: 16.8s; }

section .grid__item .grid__item:nth-child(56) .image-wrapper,
section .grid__item:nth-child(56) .image-wrapper {
  transition-delay: 17.7s; }

section .grid__item .grid__item:nth-child(56) blockquote,
section .grid__item:nth-child(56) blockquote {
  transition-delay: 17.1s; }

section .grid__item .grid__item:nth-child(57) .image-wrapper,
section .grid__item:nth-child(57) .image-wrapper {
  transition-delay: 18s; }

section .grid__item .grid__item:nth-child(57) blockquote,
section .grid__item:nth-child(57) blockquote {
  transition-delay: 17.4s; }

section .grid__item .grid__item:nth-child(58) .image-wrapper,
section .grid__item:nth-child(58) .image-wrapper {
  transition-delay: 18.3s; }

section .grid__item .grid__item:nth-child(58) blockquote,
section .grid__item:nth-child(58) blockquote {
  transition-delay: 17.7s; }

section .grid__item .grid__item:nth-child(59) .image-wrapper,
section .grid__item:nth-child(59) .image-wrapper {
  transition-delay: 18.6s; }

section .grid__item .grid__item:nth-child(59) blockquote,
section .grid__item:nth-child(59) blockquote {
  transition-delay: 18s; }

section .grid__item .grid__item:nth-child(60) .image-wrapper,
section .grid__item:nth-child(60) .image-wrapper {
  transition-delay: 18.9s; }

section .grid__item .grid__item:nth-child(60) blockquote,
section .grid__item:nth-child(60) blockquote {
  transition-delay: 18.3s; }

section .carousel--stacking .carousel__item:nth-child(1) .image-wrapper {
  transition-delay: 0.54s; }

section .carousel--stacking .carousel__item:nth-child(2) .image-wrapper {
  transition-delay: 0.78s; }

section .carousel--stacking .carousel__item:nth-child(3) .image-wrapper {
  transition-delay: 1.02s; }

section .carousel--stacking .carousel__item:nth-child(4) .image-wrapper {
  transition-delay: 1.26s; }

section .carousel--stacking .carousel__item:nth-child(5) .image-wrapper {
  transition-delay: 1.5s; }

section .carousel--stacking .carousel__item:nth-child(6) .image-wrapper {
  transition-delay: 1.74s; }

section .carousel--stacking .carousel__item:nth-child(7) .image-wrapper {
  transition-delay: 1.98s; }

section .carousel--stacking .carousel__item:nth-child(8) .image-wrapper {
  transition-delay: 2.22s; }

section .carousel--stacking .carousel__item:nth-child(9) .image-wrapper {
  transition-delay: 2.46s; }

section .carousel--stacking .carousel__item:nth-child(10) .image-wrapper {
  transition-delay: 2.7s; }

section .carousel--stacking .carousel__item:nth-child(11) .image-wrapper {
  transition-delay: 2.94s; }

section .carousel--stacking .carousel__item:nth-child(12) .image-wrapper {
  transition-delay: 3.18s; }

section .carousel--stacking .carousel__item:nth-child(13) .image-wrapper {
  transition-delay: 3.42s; }

section .carousel--stacking .carousel__item:nth-child(14) .image-wrapper {
  transition-delay: 3.66s; }

section .carousel--stacking .carousel__item:nth-child(15) .image-wrapper {
  transition-delay: 3.9s; }

section .carousel--stacking .carousel__item:nth-child(16) .image-wrapper {
  transition-delay: 4.14s; }

section .carousel--stacking .carousel__item:nth-child(17) .image-wrapper {
  transition-delay: 4.38s; }

section .carousel--stacking .carousel__item:nth-child(18) .image-wrapper {
  transition-delay: 4.62s; }

section .carousel--stacking .carousel__item:nth-child(19) .image-wrapper {
  transition-delay: 4.86s; }

section .carousel--stacking .carousel__item:nth-child(20) .image-wrapper {
  transition-delay: 5.1s; }

section .carousel--stacking .carousel__item:nth-child(21) .image-wrapper {
  transition-delay: 5.34s; }

section .carousel--stacking .carousel__item:nth-child(22) .image-wrapper {
  transition-delay: 5.58s; }

section .carousel--stacking .carousel__item:nth-child(23) .image-wrapper {
  transition-delay: 5.82s; }

section .carousel--stacking .carousel__item:nth-child(24) .image-wrapper {
  transition-delay: 6.06s; }

section .carousel--stacking .carousel__item:nth-child(25) .image-wrapper {
  transition-delay: 6.3s; }

section .carousel--stacking .carousel__item:nth-child(26) .image-wrapper {
  transition-delay: 6.54s; }

section .carousel--stacking .carousel__item:nth-child(27) .image-wrapper {
  transition-delay: 6.78s; }

section .carousel--stacking .carousel__item:nth-child(28) .image-wrapper {
  transition-delay: 7.02s; }

section .carousel--stacking .carousel__item:nth-child(29) .image-wrapper {
  transition-delay: 7.26s; }

section .carousel--stacking .carousel__item:nth-child(30) .image-wrapper {
  transition-delay: 7.5s; }

section .carousel--stacking .carousel__item:nth-child(31) .image-wrapper {
  transition-delay: 7.74s; }

section .carousel--stacking .carousel__item:nth-child(32) .image-wrapper {
  transition-delay: 7.98s; }

section .carousel--stacking .carousel__item:nth-child(33) .image-wrapper {
  transition-delay: 8.22s; }

section .carousel--stacking .carousel__item:nth-child(34) .image-wrapper {
  transition-delay: 8.46s; }

section .carousel--stacking .carousel__item:nth-child(35) .image-wrapper {
  transition-delay: 8.7s; }

section .carousel--stacking .carousel__item:nth-child(36) .image-wrapper {
  transition-delay: 8.94s; }

section .carousel--stacking .carousel__item:nth-child(37) .image-wrapper {
  transition-delay: 9.18s; }

section .carousel--stacking .carousel__item:nth-child(38) .image-wrapper {
  transition-delay: 9.42s; }

section .carousel--stacking .carousel__item:nth-child(39) .image-wrapper {
  transition-delay: 9.66s; }

section .carousel--stacking .carousel__item:nth-child(40) .image-wrapper {
  transition-delay: 9.9s; }

section .carousel--stacking .carousel__item:nth-child(41) .image-wrapper {
  transition-delay: 10.14s; }

section .carousel--stacking .carousel__item:nth-child(42) .image-wrapper {
  transition-delay: 10.38s; }

section .carousel--stacking .carousel__item:nth-child(43) .image-wrapper {
  transition-delay: 10.62s; }

section .carousel--stacking .carousel__item:nth-child(44) .image-wrapper {
  transition-delay: 10.86s; }

section .carousel--stacking .carousel__item:nth-child(45) .image-wrapper {
  transition-delay: 11.1s; }

section .carousel--stacking .carousel__item:nth-child(46) .image-wrapper {
  transition-delay: 11.34s; }

section .carousel--stacking .carousel__item:nth-child(47) .image-wrapper {
  transition-delay: 11.58s; }

section .carousel--stacking .carousel__item:nth-child(48) .image-wrapper {
  transition-delay: 11.82s; }

section .carousel--stacking .carousel__item:nth-child(49) .image-wrapper {
  transition-delay: 12.06s; }

section .carousel--stacking .carousel__item:nth-child(50) .image-wrapper {
  transition-delay: 12.3s; }

section .carousel--stacking .carousel__item:nth-child(51) .image-wrapper {
  transition-delay: 12.54s; }

section .carousel--stacking .carousel__item:nth-child(52) .image-wrapper {
  transition-delay: 12.78s; }

section .carousel--stacking .carousel__item:nth-child(53) .image-wrapper {
  transition-delay: 13.02s; }

section .carousel--stacking .carousel__item:nth-child(54) .image-wrapper {
  transition-delay: 13.26s; }

section .carousel--stacking .carousel__item:nth-child(55) .image-wrapper {
  transition-delay: 13.5s; }

section .carousel--stacking .carousel__item:nth-child(56) .image-wrapper {
  transition-delay: 13.74s; }

section .carousel--stacking .carousel__item:nth-child(57) .image-wrapper {
  transition-delay: 13.98s; }

section .carousel--stacking .carousel__item:nth-child(58) .image-wrapper {
  transition-delay: 14.22s; }

section .carousel--stacking .carousel__item:nth-child(59) .image-wrapper {
  transition-delay: 14.46s; }

section .carousel--stacking .carousel__item:nth-child(60) .image-wrapper {
  transition-delay: 14.7s; }

section .split--word, section .split--letter {
  overflow: hidden; }
  section .split--word > span, section .split--letter > span {
    transition: none;
    opacity: 0; }

section h2, section h3, section h4, section h5, section h6 {
  transition: none;
  opacity: 0; }
  section h2 .split--word > span, section h2 .split--letter > span, section h3 .split--word > span, section h3 .split--letter > span, section h4 .split--word > span, section h4 .split--letter > span, section h5 .split--word > span, section h5 .split--letter > span, section h6 .split--word > span, section h6 .split--letter > span {
    transform: translate3d(0, 90px, 0) skewX(9deg); }

section p .split--word > span, section p .split--letter > span, section blockquote .split--word > span, section blockquote .split--letter > span {
  opacity: 0;
  transform: translate3d(0, 100%, 0) skewX(6deg); }

.active-section section h1 > span {
  overflow: hidden; }
  .active-section section h1 > span:nth-child(1) span {
    animation-delay: 0.72s; }
  .active-section section h1 > span:nth-child(2) span {
    animation-delay: 0.84s; }
  .active-section section h1 > span:nth-child(3) span {
    animation-delay: 0.96s; }
  .active-section section h1 > span:nth-child(4) span {
    animation-delay: 1.08s; }
  .active-section section h1 > span:nth-child(5) span {
    animation-delay: 1.2s; }
  .active-section section h1 > span:nth-child(6) span {
    animation-delay: 1.32s; }
  .active-section section h1 > span:nth-child(7) span {
    animation-delay: 1.44s; }
  .active-section section h1 > span:nth-child(8) span {
    animation-delay: 1.56s; }
  .active-section section h1 > span:nth-child(9) span {
    animation-delay: 1.68s; }
  .active-section section h1 > span:nth-child(10) span {
    animation-delay: 1.8s; }
  .active-section section h1 > span:nth-child(11) span {
    animation-delay: 1.92s; }
  .active-section section h1 > span:nth-child(12) span {
    animation-delay: 2.04s; }
  .active-section section h1 > span:nth-child(13) span {
    animation-delay: 2.16s; }
  .active-section section h1 > span:nth-child(14) span {
    animation-delay: 2.28s; }
  .active-section section h1 > span:nth-child(15) span {
    animation-delay: 2.4s; }
  .active-section section h1 > span:nth-child(16) span {
    animation-delay: 2.52s; }
  .active-section section h1 > span:nth-child(17) span {
    animation-delay: 2.64s; }
  .active-section section h1 > span:nth-child(18) span {
    animation-delay: 2.76s; }
  .active-section section h1 > span:nth-child(19) span {
    animation-delay: 2.88s; }
  .active-section section h1 > span:nth-child(20) span {
    animation-delay: 3s; }
  .active-section section h1 > span:nth-child(21) span {
    animation-delay: 3.12s; }
  .active-section section h1 > span:nth-child(22) span {
    animation-delay: 3.24s; }
  .active-section section h1 > span:nth-child(23) span {
    animation-delay: 3.36s; }
  .active-section section h1 > span:nth-child(24) span {
    animation-delay: 3.48s; }
  .active-section section h1 > span:nth-child(25) span {
    animation-delay: 3.6s; }
  .active-section section h1 > span:nth-child(26) span {
    animation-delay: 3.72s; }
  .active-section section h1 > span:nth-child(27) span {
    animation-delay: 3.84s; }
  .active-section section h1 > span:nth-child(28) span {
    animation-delay: 3.96s; }
  .active-section section h1 > span:nth-child(29) span {
    animation-delay: 4.08s; }
  .active-section section h1 > span:nth-child(30) span {
    animation-delay: 4.2s; }
  .active-section section h1 > span:nth-child(31) span {
    animation-delay: 4.32s; }
  .active-section section h1 > span:nth-child(32) span {
    animation-delay: 4.44s; }
  .active-section section h1 > span:nth-child(33) span {
    animation-delay: 4.56s; }
  .active-section section h1 > span:nth-child(34) span {
    animation-delay: 4.68s; }
  .active-section section h1 > span:nth-child(35) span {
    animation-delay: 4.8s; }
  .active-section section h1 > span:nth-child(36) span {
    animation-delay: 4.92s; }
  .active-section section h1 > span:nth-child(37) span {
    animation-delay: 5.04s; }
  .active-section section h1 > span:nth-child(38) span {
    animation-delay: 5.16s; }
  .active-section section h1 > span:nth-child(39) span {
    animation-delay: 5.28s; }
  .active-section section h1 > span:nth-child(40) span {
    animation-delay: 5.4s; }
  .active-section section h1 > span:nth-child(41) span {
    animation-delay: 5.52s; }
  .active-section section h1 > span:nth-child(42) span {
    animation-delay: 5.64s; }
  .active-section section h1 > span:nth-child(43) span {
    animation-delay: 5.76s; }
  .active-section section h1 > span:nth-child(44) span {
    animation-delay: 5.88s; }
  .active-section section h1 > span:nth-child(45) span {
    animation-delay: 6s; }
  .active-section section h1 > span:nth-child(46) span {
    animation-delay: 6.12s; }
  .active-section section h1 > span:nth-child(47) span {
    animation-delay: 6.24s; }
  .active-section section h1 > span:nth-child(48) span {
    animation-delay: 6.36s; }
  .active-section section h1 > span:nth-child(49) span {
    animation-delay: 6.48s; }
  .active-section section h1 > span:nth-child(50) span {
    animation-delay: 6.6s; }
  .active-section section h1 > span:nth-child(51) span {
    animation-delay: 6.72s; }
  .active-section section h1 > span:nth-child(52) span {
    animation-delay: 6.84s; }
  .active-section section h1 > span:nth-child(53) span {
    animation-delay: 6.96s; }
  .active-section section h1 > span:nth-child(54) span {
    animation-delay: 7.08s; }
  .active-section section h1 > span:nth-child(55) span {
    animation-delay: 7.2s; }
  .active-section section h1 > span:nth-child(56) span {
    animation-delay: 7.32s; }
  .active-section section h1 > span:nth-child(57) span {
    animation-delay: 7.44s; }
  .active-section section h1 > span:nth-child(58) span {
    animation-delay: 7.56s; }
  .active-section section h1 > span:nth-child(59) span {
    animation-delay: 7.68s; }
  .active-section section h1 > span:nth-child(60) span {
    animation-delay: 7.8s; }

.active-section section h1 > span > span {
  animation: ghostIn 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: both; }

.active-section section h2, .active-section section h3, .active-section section h4, .active-section section h5, .active-section section h6 {
  opacity: 1;
  transition: transform 0.9s 0.09s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.9s 0.09s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .active-section section h2 .split--word > span, .active-section section h2 .split--letter > span, .active-section section h3 .split--word > span, .active-section section h3 .split--letter > span, .active-section section h4 .split--word > span, .active-section section h4 .split--letter > span, .active-section section h5 .split--word > span, .active-section section h5 .split--letter > span, .active-section section h6 .split--word > span, .active-section section h6 .split--letter > span {
    transition: transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    transform: translate3d(0, -8%, 0);
    transform: none; }

.active-section section h2, .active-section section h3 {
  transition-delay: 0.06s; }

.active-section section h4 {
  transition-delay: 0.18s; }

.active-section section h5, .active-section section h6 {
  transition-delay: 0.24s; }

.active-section section h2 .split--word:nth-child(1) span, .active-section section h3 .split--word:nth-child(1) span, .active-section section h4 .split--word:nth-child(1) span, .active-section section h5 .split--word:nth-child(1) span, .active-section section h6 .split--word:nth-child(1) span {
  transition-delay: 0.78s; }

.active-section section h2 .split--word:nth-child(2) span, .active-section section h3 .split--word:nth-child(2) span, .active-section section h4 .split--word:nth-child(2) span, .active-section section h5 .split--word:nth-child(2) span, .active-section section h6 .split--word:nth-child(2) span {
  transition-delay: 0.96s; }

.active-section section h2 .split--word:nth-child(3) span, .active-section section h3 .split--word:nth-child(3) span, .active-section section h4 .split--word:nth-child(3) span, .active-section section h5 .split--word:nth-child(3) span, .active-section section h6 .split--word:nth-child(3) span {
  transition-delay: 1.14s; }

.active-section section h2 .split--word:nth-child(4) span, .active-section section h3 .split--word:nth-child(4) span, .active-section section h4 .split--word:nth-child(4) span, .active-section section h5 .split--word:nth-child(4) span, .active-section section h6 .split--word:nth-child(4) span {
  transition-delay: 1.32s; }

.active-section section h2 .split--word:nth-child(5) span, .active-section section h3 .split--word:nth-child(5) span, .active-section section h4 .split--word:nth-child(5) span, .active-section section h5 .split--word:nth-child(5) span, .active-section section h6 .split--word:nth-child(5) span {
  transition-delay: 1.5s; }

.active-section section h2 .split--word:nth-child(6) span, .active-section section h3 .split--word:nth-child(6) span, .active-section section h4 .split--word:nth-child(6) span, .active-section section h5 .split--word:nth-child(6) span, .active-section section h6 .split--word:nth-child(6) span {
  transition-delay: 1.68s; }

.active-section section h2 .split--word:nth-child(7) span, .active-section section h3 .split--word:nth-child(7) span, .active-section section h4 .split--word:nth-child(7) span, .active-section section h5 .split--word:nth-child(7) span, .active-section section h6 .split--word:nth-child(7) span {
  transition-delay: 1.86s; }

.active-section section h2 .split--word:nth-child(8) span, .active-section section h3 .split--word:nth-child(8) span, .active-section section h4 .split--word:nth-child(8) span, .active-section section h5 .split--word:nth-child(8) span, .active-section section h6 .split--word:nth-child(8) span {
  transition-delay: 2.04s; }

.active-section section h2 .split--word:nth-child(9) span, .active-section section h3 .split--word:nth-child(9) span, .active-section section h4 .split--word:nth-child(9) span, .active-section section h5 .split--word:nth-child(9) span, .active-section section h6 .split--word:nth-child(9) span {
  transition-delay: 2.22s; }

.active-section section h2 .split--word:nth-child(10) span, .active-section section h3 .split--word:nth-child(10) span, .active-section section h4 .split--word:nth-child(10) span, .active-section section h5 .split--word:nth-child(10) span, .active-section section h6 .split--word:nth-child(10) span {
  transition-delay: 2.4s; }

.active-section section h2 .split--word:nth-child(11) span, .active-section section h3 .split--word:nth-child(11) span, .active-section section h4 .split--word:nth-child(11) span, .active-section section h5 .split--word:nth-child(11) span, .active-section section h6 .split--word:nth-child(11) span {
  transition-delay: 2.58s; }

.active-section section h2 .split--word:nth-child(12) span, .active-section section h3 .split--word:nth-child(12) span, .active-section section h4 .split--word:nth-child(12) span, .active-section section h5 .split--word:nth-child(12) span, .active-section section h6 .split--word:nth-child(12) span {
  transition-delay: 2.76s; }

.active-section section h2 .split--word:nth-child(13) span, .active-section section h3 .split--word:nth-child(13) span, .active-section section h4 .split--word:nth-child(13) span, .active-section section h5 .split--word:nth-child(13) span, .active-section section h6 .split--word:nth-child(13) span {
  transition-delay: 2.94s; }

.active-section section h2 .split--word:nth-child(14) span, .active-section section h3 .split--word:nth-child(14) span, .active-section section h4 .split--word:nth-child(14) span, .active-section section h5 .split--word:nth-child(14) span, .active-section section h6 .split--word:nth-child(14) span {
  transition-delay: 3.12s; }

.active-section section h2 .split--word:nth-child(15) span, .active-section section h3 .split--word:nth-child(15) span, .active-section section h4 .split--word:nth-child(15) span, .active-section section h5 .split--word:nth-child(15) span, .active-section section h6 .split--word:nth-child(15) span {
  transition-delay: 3.3s; }

.active-section section h2 .split--word:nth-child(16) span, .active-section section h3 .split--word:nth-child(16) span, .active-section section h4 .split--word:nth-child(16) span, .active-section section h5 .split--word:nth-child(16) span, .active-section section h6 .split--word:nth-child(16) span {
  transition-delay: 3.48s; }

.active-section section h2 .split--word:nth-child(17) span, .active-section section h3 .split--word:nth-child(17) span, .active-section section h4 .split--word:nth-child(17) span, .active-section section h5 .split--word:nth-child(17) span, .active-section section h6 .split--word:nth-child(17) span {
  transition-delay: 3.66s; }

.active-section section h2 .split--word:nth-child(18) span, .active-section section h3 .split--word:nth-child(18) span, .active-section section h4 .split--word:nth-child(18) span, .active-section section h5 .split--word:nth-child(18) span, .active-section section h6 .split--word:nth-child(18) span {
  transition-delay: 3.84s; }

.active-section section h2 .split--word:nth-child(19) span, .active-section section h3 .split--word:nth-child(19) span, .active-section section h4 .split--word:nth-child(19) span, .active-section section h5 .split--word:nth-child(19) span, .active-section section h6 .split--word:nth-child(19) span {
  transition-delay: 4.02s; }

.active-section section h2 .split--word:nth-child(20) span, .active-section section h3 .split--word:nth-child(20) span, .active-section section h4 .split--word:nth-child(20) span, .active-section section h5 .split--word:nth-child(20) span, .active-section section h6 .split--word:nth-child(20) span {
  transition-delay: 4.2s; }

.active-section section h2 .split--word:nth-child(21) span, .active-section section h3 .split--word:nth-child(21) span, .active-section section h4 .split--word:nth-child(21) span, .active-section section h5 .split--word:nth-child(21) span, .active-section section h6 .split--word:nth-child(21) span {
  transition-delay: 4.38s; }

.active-section section h2 .split--word:nth-child(22) span, .active-section section h3 .split--word:nth-child(22) span, .active-section section h4 .split--word:nth-child(22) span, .active-section section h5 .split--word:nth-child(22) span, .active-section section h6 .split--word:nth-child(22) span {
  transition-delay: 4.56s; }

.active-section section h2 .split--word:nth-child(23) span, .active-section section h3 .split--word:nth-child(23) span, .active-section section h4 .split--word:nth-child(23) span, .active-section section h5 .split--word:nth-child(23) span, .active-section section h6 .split--word:nth-child(23) span {
  transition-delay: 4.74s; }

.active-section section h2 .split--word:nth-child(24) span, .active-section section h3 .split--word:nth-child(24) span, .active-section section h4 .split--word:nth-child(24) span, .active-section section h5 .split--word:nth-child(24) span, .active-section section h6 .split--word:nth-child(24) span {
  transition-delay: 4.92s; }

.active-section section h2 .split--word:nth-child(25) span, .active-section section h3 .split--word:nth-child(25) span, .active-section section h4 .split--word:nth-child(25) span, .active-section section h5 .split--word:nth-child(25) span, .active-section section h6 .split--word:nth-child(25) span {
  transition-delay: 5.1s; }

.active-section section h2 .split--word:nth-child(26) span, .active-section section h3 .split--word:nth-child(26) span, .active-section section h4 .split--word:nth-child(26) span, .active-section section h5 .split--word:nth-child(26) span, .active-section section h6 .split--word:nth-child(26) span {
  transition-delay: 5.28s; }

.active-section section h2 .split--word:nth-child(27) span, .active-section section h3 .split--word:nth-child(27) span, .active-section section h4 .split--word:nth-child(27) span, .active-section section h5 .split--word:nth-child(27) span, .active-section section h6 .split--word:nth-child(27) span {
  transition-delay: 5.46s; }

.active-section section h2 .split--word:nth-child(28) span, .active-section section h3 .split--word:nth-child(28) span, .active-section section h4 .split--word:nth-child(28) span, .active-section section h5 .split--word:nth-child(28) span, .active-section section h6 .split--word:nth-child(28) span {
  transition-delay: 5.64s; }

.active-section section h2 .split--word:nth-child(29) span, .active-section section h3 .split--word:nth-child(29) span, .active-section section h4 .split--word:nth-child(29) span, .active-section section h5 .split--word:nth-child(29) span, .active-section section h6 .split--word:nth-child(29) span {
  transition-delay: 5.82s; }

.active-section section h2 .split--word:nth-child(30) span, .active-section section h3 .split--word:nth-child(30) span, .active-section section h4 .split--word:nth-child(30) span, .active-section section h5 .split--word:nth-child(30) span, .active-section section h6 .split--word:nth-child(30) span {
  transition-delay: 6s; }

.active-section section h2 .split--word:nth-child(31) span, .active-section section h3 .split--word:nth-child(31) span, .active-section section h4 .split--word:nth-child(31) span, .active-section section h5 .split--word:nth-child(31) span, .active-section section h6 .split--word:nth-child(31) span {
  transition-delay: 6.18s; }

.active-section section h2 .split--word:nth-child(32) span, .active-section section h3 .split--word:nth-child(32) span, .active-section section h4 .split--word:nth-child(32) span, .active-section section h5 .split--word:nth-child(32) span, .active-section section h6 .split--word:nth-child(32) span {
  transition-delay: 6.36s; }

.active-section section h2 .split--word:nth-child(33) span, .active-section section h3 .split--word:nth-child(33) span, .active-section section h4 .split--word:nth-child(33) span, .active-section section h5 .split--word:nth-child(33) span, .active-section section h6 .split--word:nth-child(33) span {
  transition-delay: 6.54s; }

.active-section section h2 .split--word:nth-child(34) span, .active-section section h3 .split--word:nth-child(34) span, .active-section section h4 .split--word:nth-child(34) span, .active-section section h5 .split--word:nth-child(34) span, .active-section section h6 .split--word:nth-child(34) span {
  transition-delay: 6.72s; }

.active-section section h2 .split--word:nth-child(35) span, .active-section section h3 .split--word:nth-child(35) span, .active-section section h4 .split--word:nth-child(35) span, .active-section section h5 .split--word:nth-child(35) span, .active-section section h6 .split--word:nth-child(35) span {
  transition-delay: 6.9s; }

.active-section section h2 .split--word:nth-child(36) span, .active-section section h3 .split--word:nth-child(36) span, .active-section section h4 .split--word:nth-child(36) span, .active-section section h5 .split--word:nth-child(36) span, .active-section section h6 .split--word:nth-child(36) span {
  transition-delay: 7.08s; }

.active-section section h2 .split--word:nth-child(37) span, .active-section section h3 .split--word:nth-child(37) span, .active-section section h4 .split--word:nth-child(37) span, .active-section section h5 .split--word:nth-child(37) span, .active-section section h6 .split--word:nth-child(37) span {
  transition-delay: 7.26s; }

.active-section section h2 .split--word:nth-child(38) span, .active-section section h3 .split--word:nth-child(38) span, .active-section section h4 .split--word:nth-child(38) span, .active-section section h5 .split--word:nth-child(38) span, .active-section section h6 .split--word:nth-child(38) span {
  transition-delay: 7.44s; }

.active-section section h2 .split--word:nth-child(39) span, .active-section section h3 .split--word:nth-child(39) span, .active-section section h4 .split--word:nth-child(39) span, .active-section section h5 .split--word:nth-child(39) span, .active-section section h6 .split--word:nth-child(39) span {
  transition-delay: 7.62s; }

.active-section section h2 .split--word:nth-child(40) span, .active-section section h3 .split--word:nth-child(40) span, .active-section section h4 .split--word:nth-child(40) span, .active-section section h5 .split--word:nth-child(40) span, .active-section section h6 .split--word:nth-child(40) span {
  transition-delay: 7.8s; }

.active-section section h2 .split--word:nth-child(41) span, .active-section section h3 .split--word:nth-child(41) span, .active-section section h4 .split--word:nth-child(41) span, .active-section section h5 .split--word:nth-child(41) span, .active-section section h6 .split--word:nth-child(41) span {
  transition-delay: 7.98s; }

.active-section section h2 .split--word:nth-child(42) span, .active-section section h3 .split--word:nth-child(42) span, .active-section section h4 .split--word:nth-child(42) span, .active-section section h5 .split--word:nth-child(42) span, .active-section section h6 .split--word:nth-child(42) span {
  transition-delay: 8.16s; }

.active-section section h2 .split--word:nth-child(43) span, .active-section section h3 .split--word:nth-child(43) span, .active-section section h4 .split--word:nth-child(43) span, .active-section section h5 .split--word:nth-child(43) span, .active-section section h6 .split--word:nth-child(43) span {
  transition-delay: 8.34s; }

.active-section section h2 .split--word:nth-child(44) span, .active-section section h3 .split--word:nth-child(44) span, .active-section section h4 .split--word:nth-child(44) span, .active-section section h5 .split--word:nth-child(44) span, .active-section section h6 .split--word:nth-child(44) span {
  transition-delay: 8.52s; }

.active-section section h2 .split--word:nth-child(45) span, .active-section section h3 .split--word:nth-child(45) span, .active-section section h4 .split--word:nth-child(45) span, .active-section section h5 .split--word:nth-child(45) span, .active-section section h6 .split--word:nth-child(45) span {
  transition-delay: 8.7s; }

.active-section section h2 .split--word:nth-child(46) span, .active-section section h3 .split--word:nth-child(46) span, .active-section section h4 .split--word:nth-child(46) span, .active-section section h5 .split--word:nth-child(46) span, .active-section section h6 .split--word:nth-child(46) span {
  transition-delay: 8.88s; }

.active-section section h2 .split--word:nth-child(47) span, .active-section section h3 .split--word:nth-child(47) span, .active-section section h4 .split--word:nth-child(47) span, .active-section section h5 .split--word:nth-child(47) span, .active-section section h6 .split--word:nth-child(47) span {
  transition-delay: 9.06s; }

.active-section section h2 .split--word:nth-child(48) span, .active-section section h3 .split--word:nth-child(48) span, .active-section section h4 .split--word:nth-child(48) span, .active-section section h5 .split--word:nth-child(48) span, .active-section section h6 .split--word:nth-child(48) span {
  transition-delay: 9.24s; }

.active-section section h2 .split--word:nth-child(49) span, .active-section section h3 .split--word:nth-child(49) span, .active-section section h4 .split--word:nth-child(49) span, .active-section section h5 .split--word:nth-child(49) span, .active-section section h6 .split--word:nth-child(49) span {
  transition-delay: 9.42s; }

.active-section section h2 .split--word:nth-child(50) span, .active-section section h3 .split--word:nth-child(50) span, .active-section section h4 .split--word:nth-child(50) span, .active-section section h5 .split--word:nth-child(50) span, .active-section section h6 .split--word:nth-child(50) span {
  transition-delay: 9.6s; }

.active-section section h2 .split--word:nth-child(51) span, .active-section section h3 .split--word:nth-child(51) span, .active-section section h4 .split--word:nth-child(51) span, .active-section section h5 .split--word:nth-child(51) span, .active-section section h6 .split--word:nth-child(51) span {
  transition-delay: 9.78s; }

.active-section section h2 .split--word:nth-child(52) span, .active-section section h3 .split--word:nth-child(52) span, .active-section section h4 .split--word:nth-child(52) span, .active-section section h5 .split--word:nth-child(52) span, .active-section section h6 .split--word:nth-child(52) span {
  transition-delay: 9.96s; }

.active-section section h2 .split--word:nth-child(53) span, .active-section section h3 .split--word:nth-child(53) span, .active-section section h4 .split--word:nth-child(53) span, .active-section section h5 .split--word:nth-child(53) span, .active-section section h6 .split--word:nth-child(53) span {
  transition-delay: 10.14s; }

.active-section section h2 .split--word:nth-child(54) span, .active-section section h3 .split--word:nth-child(54) span, .active-section section h4 .split--word:nth-child(54) span, .active-section section h5 .split--word:nth-child(54) span, .active-section section h6 .split--word:nth-child(54) span {
  transition-delay: 10.32s; }

.active-section section h2 .split--word:nth-child(55) span, .active-section section h3 .split--word:nth-child(55) span, .active-section section h4 .split--word:nth-child(55) span, .active-section section h5 .split--word:nth-child(55) span, .active-section section h6 .split--word:nth-child(55) span {
  transition-delay: 10.5s; }

.active-section section h2 .split--word:nth-child(56) span, .active-section section h3 .split--word:nth-child(56) span, .active-section section h4 .split--word:nth-child(56) span, .active-section section h5 .split--word:nth-child(56) span, .active-section section h6 .split--word:nth-child(56) span {
  transition-delay: 10.68s; }

.active-section section h2 .split--word:nth-child(57) span, .active-section section h3 .split--word:nth-child(57) span, .active-section section h4 .split--word:nth-child(57) span, .active-section section h5 .split--word:nth-child(57) span, .active-section section h6 .split--word:nth-child(57) span {
  transition-delay: 10.86s; }

.active-section section h2 .split--word:nth-child(58) span, .active-section section h3 .split--word:nth-child(58) span, .active-section section h4 .split--word:nth-child(58) span, .active-section section h5 .split--word:nth-child(58) span, .active-section section h6 .split--word:nth-child(58) span {
  transition-delay: 11.04s; }

.active-section section h2 .split--word:nth-child(59) span, .active-section section h3 .split--word:nth-child(59) span, .active-section section h4 .split--word:nth-child(59) span, .active-section section h5 .split--word:nth-child(59) span, .active-section section h6 .split--word:nth-child(59) span {
  transition-delay: 11.22s; }

.active-section section h2 .split--word:nth-child(60) span, .active-section section h3 .split--word:nth-child(60) span, .active-section section h4 .split--word:nth-child(60) span, .active-section section h5 .split--word:nth-child(60) span, .active-section section h6 .split--word:nth-child(60) span {
  transition-delay: 11.4s; }

.active-section section h2 .split--letter > span:nth-child(1), .active-section section h3 .split--letter > span:nth-child(1), .active-section section h4 .split--letter > span:nth-child(1), .active-section section h5 .split--letter > span:nth-child(1), .active-section section h6 .split--letter > span:nth-child(1) {
  transition-delay: 0.69s; }

.active-section section h2 .split--letter > span:nth-child(2), .active-section section h3 .split--letter > span:nth-child(2), .active-section section h4 .split--letter > span:nth-child(2), .active-section section h5 .split--letter > span:nth-child(2), .active-section section h6 .split--letter > span:nth-child(2) {
  transition-delay: 0.78s; }

.active-section section h2 .split--letter > span:nth-child(3), .active-section section h3 .split--letter > span:nth-child(3), .active-section section h4 .split--letter > span:nth-child(3), .active-section section h5 .split--letter > span:nth-child(3), .active-section section h6 .split--letter > span:nth-child(3) {
  transition-delay: 0.87s; }

.active-section section h2 .split--letter > span:nth-child(4), .active-section section h3 .split--letter > span:nth-child(4), .active-section section h4 .split--letter > span:nth-child(4), .active-section section h5 .split--letter > span:nth-child(4), .active-section section h6 .split--letter > span:nth-child(4) {
  transition-delay: 0.96s; }

.active-section section h2 .split--letter > span:nth-child(5), .active-section section h3 .split--letter > span:nth-child(5), .active-section section h4 .split--letter > span:nth-child(5), .active-section section h5 .split--letter > span:nth-child(5), .active-section section h6 .split--letter > span:nth-child(5) {
  transition-delay: 1.05s; }

.active-section section h2 .split--letter > span:nth-child(6), .active-section section h3 .split--letter > span:nth-child(6), .active-section section h4 .split--letter > span:nth-child(6), .active-section section h5 .split--letter > span:nth-child(6), .active-section section h6 .split--letter > span:nth-child(6) {
  transition-delay: 1.14s; }

.active-section section h2 .split--letter > span:nth-child(7), .active-section section h3 .split--letter > span:nth-child(7), .active-section section h4 .split--letter > span:nth-child(7), .active-section section h5 .split--letter > span:nth-child(7), .active-section section h6 .split--letter > span:nth-child(7) {
  transition-delay: 1.23s; }

.active-section section h2 .split--letter > span:nth-child(8), .active-section section h3 .split--letter > span:nth-child(8), .active-section section h4 .split--letter > span:nth-child(8), .active-section section h5 .split--letter > span:nth-child(8), .active-section section h6 .split--letter > span:nth-child(8) {
  transition-delay: 1.32s; }

.active-section section h2 .split--letter > span:nth-child(9), .active-section section h3 .split--letter > span:nth-child(9), .active-section section h4 .split--letter > span:nth-child(9), .active-section section h5 .split--letter > span:nth-child(9), .active-section section h6 .split--letter > span:nth-child(9) {
  transition-delay: 1.41s; }

.active-section section h2 .split--letter > span:nth-child(10), .active-section section h3 .split--letter > span:nth-child(10), .active-section section h4 .split--letter > span:nth-child(10), .active-section section h5 .split--letter > span:nth-child(10), .active-section section h6 .split--letter > span:nth-child(10) {
  transition-delay: 1.5s; }

.active-section section h2 .split--letter > span:nth-child(11), .active-section section h3 .split--letter > span:nth-child(11), .active-section section h4 .split--letter > span:nth-child(11), .active-section section h5 .split--letter > span:nth-child(11), .active-section section h6 .split--letter > span:nth-child(11) {
  transition-delay: 1.59s; }

.active-section section h2 .split--letter > span:nth-child(12), .active-section section h3 .split--letter > span:nth-child(12), .active-section section h4 .split--letter > span:nth-child(12), .active-section section h5 .split--letter > span:nth-child(12), .active-section section h6 .split--letter > span:nth-child(12) {
  transition-delay: 1.68s; }

.active-section section h2 .split--letter > span:nth-child(13), .active-section section h3 .split--letter > span:nth-child(13), .active-section section h4 .split--letter > span:nth-child(13), .active-section section h5 .split--letter > span:nth-child(13), .active-section section h6 .split--letter > span:nth-child(13) {
  transition-delay: 1.77s; }

.active-section section h2 .split--letter > span:nth-child(14), .active-section section h3 .split--letter > span:nth-child(14), .active-section section h4 .split--letter > span:nth-child(14), .active-section section h5 .split--letter > span:nth-child(14), .active-section section h6 .split--letter > span:nth-child(14) {
  transition-delay: 1.86s; }

.active-section section h2 .split--letter > span:nth-child(15), .active-section section h3 .split--letter > span:nth-child(15), .active-section section h4 .split--letter > span:nth-child(15), .active-section section h5 .split--letter > span:nth-child(15), .active-section section h6 .split--letter > span:nth-child(15) {
  transition-delay: 1.95s; }

.active-section section h2 .split--letter > span:nth-child(16), .active-section section h3 .split--letter > span:nth-child(16), .active-section section h4 .split--letter > span:nth-child(16), .active-section section h5 .split--letter > span:nth-child(16), .active-section section h6 .split--letter > span:nth-child(16) {
  transition-delay: 2.04s; }

.active-section section h2 .split--letter > span:nth-child(17), .active-section section h3 .split--letter > span:nth-child(17), .active-section section h4 .split--letter > span:nth-child(17), .active-section section h5 .split--letter > span:nth-child(17), .active-section section h6 .split--letter > span:nth-child(17) {
  transition-delay: 2.13s; }

.active-section section h2 .split--letter > span:nth-child(18), .active-section section h3 .split--letter > span:nth-child(18), .active-section section h4 .split--letter > span:nth-child(18), .active-section section h5 .split--letter > span:nth-child(18), .active-section section h6 .split--letter > span:nth-child(18) {
  transition-delay: 2.22s; }

.active-section section h2 .split--letter > span:nth-child(19), .active-section section h3 .split--letter > span:nth-child(19), .active-section section h4 .split--letter > span:nth-child(19), .active-section section h5 .split--letter > span:nth-child(19), .active-section section h6 .split--letter > span:nth-child(19) {
  transition-delay: 2.31s; }

.active-section section h2 .split--letter > span:nth-child(20), .active-section section h3 .split--letter > span:nth-child(20), .active-section section h4 .split--letter > span:nth-child(20), .active-section section h5 .split--letter > span:nth-child(20), .active-section section h6 .split--letter > span:nth-child(20) {
  transition-delay: 2.4s; }

.active-section section h2 .split--letter > span:nth-child(21), .active-section section h3 .split--letter > span:nth-child(21), .active-section section h4 .split--letter > span:nth-child(21), .active-section section h5 .split--letter > span:nth-child(21), .active-section section h6 .split--letter > span:nth-child(21) {
  transition-delay: 2.49s; }

.active-section section h2 .split--letter > span:nth-child(22), .active-section section h3 .split--letter > span:nth-child(22), .active-section section h4 .split--letter > span:nth-child(22), .active-section section h5 .split--letter > span:nth-child(22), .active-section section h6 .split--letter > span:nth-child(22) {
  transition-delay: 2.58s; }

.active-section section h2 .split--letter > span:nth-child(23), .active-section section h3 .split--letter > span:nth-child(23), .active-section section h4 .split--letter > span:nth-child(23), .active-section section h5 .split--letter > span:nth-child(23), .active-section section h6 .split--letter > span:nth-child(23) {
  transition-delay: 2.67s; }

.active-section section h2 .split--letter > span:nth-child(24), .active-section section h3 .split--letter > span:nth-child(24), .active-section section h4 .split--letter > span:nth-child(24), .active-section section h5 .split--letter > span:nth-child(24), .active-section section h6 .split--letter > span:nth-child(24) {
  transition-delay: 2.76s; }

.active-section section h2 .split--letter > span:nth-child(25), .active-section section h3 .split--letter > span:nth-child(25), .active-section section h4 .split--letter > span:nth-child(25), .active-section section h5 .split--letter > span:nth-child(25), .active-section section h6 .split--letter > span:nth-child(25) {
  transition-delay: 2.85s; }

.active-section section h2 .split--letter > span:nth-child(26), .active-section section h3 .split--letter > span:nth-child(26), .active-section section h4 .split--letter > span:nth-child(26), .active-section section h5 .split--letter > span:nth-child(26), .active-section section h6 .split--letter > span:nth-child(26) {
  transition-delay: 2.94s; }

.active-section section h2 .split--letter > span:nth-child(27), .active-section section h3 .split--letter > span:nth-child(27), .active-section section h4 .split--letter > span:nth-child(27), .active-section section h5 .split--letter > span:nth-child(27), .active-section section h6 .split--letter > span:nth-child(27) {
  transition-delay: 3.03s; }

.active-section section h2 .split--letter > span:nth-child(28), .active-section section h3 .split--letter > span:nth-child(28), .active-section section h4 .split--letter > span:nth-child(28), .active-section section h5 .split--letter > span:nth-child(28), .active-section section h6 .split--letter > span:nth-child(28) {
  transition-delay: 3.12s; }

.active-section section h2 .split--letter > span:nth-child(29), .active-section section h3 .split--letter > span:nth-child(29), .active-section section h4 .split--letter > span:nth-child(29), .active-section section h5 .split--letter > span:nth-child(29), .active-section section h6 .split--letter > span:nth-child(29) {
  transition-delay: 3.21s; }

.active-section section h2 .split--letter > span:nth-child(30), .active-section section h3 .split--letter > span:nth-child(30), .active-section section h4 .split--letter > span:nth-child(30), .active-section section h5 .split--letter > span:nth-child(30), .active-section section h6 .split--letter > span:nth-child(30) {
  transition-delay: 3.3s; }

.active-section section h2 .split--letter > span:nth-child(31), .active-section section h3 .split--letter > span:nth-child(31), .active-section section h4 .split--letter > span:nth-child(31), .active-section section h5 .split--letter > span:nth-child(31), .active-section section h6 .split--letter > span:nth-child(31) {
  transition-delay: 3.39s; }

.active-section section h2 .split--letter > span:nth-child(32), .active-section section h3 .split--letter > span:nth-child(32), .active-section section h4 .split--letter > span:nth-child(32), .active-section section h5 .split--letter > span:nth-child(32), .active-section section h6 .split--letter > span:nth-child(32) {
  transition-delay: 3.48s; }

.active-section section h2 .split--letter > span:nth-child(33), .active-section section h3 .split--letter > span:nth-child(33), .active-section section h4 .split--letter > span:nth-child(33), .active-section section h5 .split--letter > span:nth-child(33), .active-section section h6 .split--letter > span:nth-child(33) {
  transition-delay: 3.57s; }

.active-section section h2 .split--letter > span:nth-child(34), .active-section section h3 .split--letter > span:nth-child(34), .active-section section h4 .split--letter > span:nth-child(34), .active-section section h5 .split--letter > span:nth-child(34), .active-section section h6 .split--letter > span:nth-child(34) {
  transition-delay: 3.66s; }

.active-section section h2 .split--letter > span:nth-child(35), .active-section section h3 .split--letter > span:nth-child(35), .active-section section h4 .split--letter > span:nth-child(35), .active-section section h5 .split--letter > span:nth-child(35), .active-section section h6 .split--letter > span:nth-child(35) {
  transition-delay: 3.75s; }

.active-section section h2 .split--letter > span:nth-child(36), .active-section section h3 .split--letter > span:nth-child(36), .active-section section h4 .split--letter > span:nth-child(36), .active-section section h5 .split--letter > span:nth-child(36), .active-section section h6 .split--letter > span:nth-child(36) {
  transition-delay: 3.84s; }

.active-section section h2 .split--letter > span:nth-child(37), .active-section section h3 .split--letter > span:nth-child(37), .active-section section h4 .split--letter > span:nth-child(37), .active-section section h5 .split--letter > span:nth-child(37), .active-section section h6 .split--letter > span:nth-child(37) {
  transition-delay: 3.93s; }

.active-section section h2 .split--letter > span:nth-child(38), .active-section section h3 .split--letter > span:nth-child(38), .active-section section h4 .split--letter > span:nth-child(38), .active-section section h5 .split--letter > span:nth-child(38), .active-section section h6 .split--letter > span:nth-child(38) {
  transition-delay: 4.02s; }

.active-section section h2 .split--letter > span:nth-child(39), .active-section section h3 .split--letter > span:nth-child(39), .active-section section h4 .split--letter > span:nth-child(39), .active-section section h5 .split--letter > span:nth-child(39), .active-section section h6 .split--letter > span:nth-child(39) {
  transition-delay: 4.11s; }

.active-section section h2 .split--letter > span:nth-child(40), .active-section section h3 .split--letter > span:nth-child(40), .active-section section h4 .split--letter > span:nth-child(40), .active-section section h5 .split--letter > span:nth-child(40), .active-section section h6 .split--letter > span:nth-child(40) {
  transition-delay: 4.2s; }

.active-section section h2 .split--letter > span:nth-child(41), .active-section section h3 .split--letter > span:nth-child(41), .active-section section h4 .split--letter > span:nth-child(41), .active-section section h5 .split--letter > span:nth-child(41), .active-section section h6 .split--letter > span:nth-child(41) {
  transition-delay: 4.29s; }

.active-section section h2 .split--letter > span:nth-child(42), .active-section section h3 .split--letter > span:nth-child(42), .active-section section h4 .split--letter > span:nth-child(42), .active-section section h5 .split--letter > span:nth-child(42), .active-section section h6 .split--letter > span:nth-child(42) {
  transition-delay: 4.38s; }

.active-section section h2 .split--letter > span:nth-child(43), .active-section section h3 .split--letter > span:nth-child(43), .active-section section h4 .split--letter > span:nth-child(43), .active-section section h5 .split--letter > span:nth-child(43), .active-section section h6 .split--letter > span:nth-child(43) {
  transition-delay: 4.47s; }

.active-section section h2 .split--letter > span:nth-child(44), .active-section section h3 .split--letter > span:nth-child(44), .active-section section h4 .split--letter > span:nth-child(44), .active-section section h5 .split--letter > span:nth-child(44), .active-section section h6 .split--letter > span:nth-child(44) {
  transition-delay: 4.56s; }

.active-section section h2 .split--letter > span:nth-child(45), .active-section section h3 .split--letter > span:nth-child(45), .active-section section h4 .split--letter > span:nth-child(45), .active-section section h5 .split--letter > span:nth-child(45), .active-section section h6 .split--letter > span:nth-child(45) {
  transition-delay: 4.65s; }

.active-section section h2 .split--letter > span:nth-child(46), .active-section section h3 .split--letter > span:nth-child(46), .active-section section h4 .split--letter > span:nth-child(46), .active-section section h5 .split--letter > span:nth-child(46), .active-section section h6 .split--letter > span:nth-child(46) {
  transition-delay: 4.74s; }

.active-section section h2 .split--letter > span:nth-child(47), .active-section section h3 .split--letter > span:nth-child(47), .active-section section h4 .split--letter > span:nth-child(47), .active-section section h5 .split--letter > span:nth-child(47), .active-section section h6 .split--letter > span:nth-child(47) {
  transition-delay: 4.83s; }

.active-section section h2 .split--letter > span:nth-child(48), .active-section section h3 .split--letter > span:nth-child(48), .active-section section h4 .split--letter > span:nth-child(48), .active-section section h5 .split--letter > span:nth-child(48), .active-section section h6 .split--letter > span:nth-child(48) {
  transition-delay: 4.92s; }

.active-section section h2 .split--letter > span:nth-child(49), .active-section section h3 .split--letter > span:nth-child(49), .active-section section h4 .split--letter > span:nth-child(49), .active-section section h5 .split--letter > span:nth-child(49), .active-section section h6 .split--letter > span:nth-child(49) {
  transition-delay: 5.01s; }

.active-section section h2 .split--letter > span:nth-child(50), .active-section section h3 .split--letter > span:nth-child(50), .active-section section h4 .split--letter > span:nth-child(50), .active-section section h5 .split--letter > span:nth-child(50), .active-section section h6 .split--letter > span:nth-child(50) {
  transition-delay: 5.1s; }

.active-section section h2 .split--letter > span:nth-child(51), .active-section section h3 .split--letter > span:nth-child(51), .active-section section h4 .split--letter > span:nth-child(51), .active-section section h5 .split--letter > span:nth-child(51), .active-section section h6 .split--letter > span:nth-child(51) {
  transition-delay: 5.19s; }

.active-section section h2 .split--letter > span:nth-child(52), .active-section section h3 .split--letter > span:nth-child(52), .active-section section h4 .split--letter > span:nth-child(52), .active-section section h5 .split--letter > span:nth-child(52), .active-section section h6 .split--letter > span:nth-child(52) {
  transition-delay: 5.28s; }

.active-section section h2 .split--letter > span:nth-child(53), .active-section section h3 .split--letter > span:nth-child(53), .active-section section h4 .split--letter > span:nth-child(53), .active-section section h5 .split--letter > span:nth-child(53), .active-section section h6 .split--letter > span:nth-child(53) {
  transition-delay: 5.37s; }

.active-section section h2 .split--letter > span:nth-child(54), .active-section section h3 .split--letter > span:nth-child(54), .active-section section h4 .split--letter > span:nth-child(54), .active-section section h5 .split--letter > span:nth-child(54), .active-section section h6 .split--letter > span:nth-child(54) {
  transition-delay: 5.46s; }

.active-section section h2 .split--letter > span:nth-child(55), .active-section section h3 .split--letter > span:nth-child(55), .active-section section h4 .split--letter > span:nth-child(55), .active-section section h5 .split--letter > span:nth-child(55), .active-section section h6 .split--letter > span:nth-child(55) {
  transition-delay: 5.55s; }

.active-section section h2 .split--letter > span:nth-child(56), .active-section section h3 .split--letter > span:nth-child(56), .active-section section h4 .split--letter > span:nth-child(56), .active-section section h5 .split--letter > span:nth-child(56), .active-section section h6 .split--letter > span:nth-child(56) {
  transition-delay: 5.64s; }

.active-section section h2 .split--letter > span:nth-child(57), .active-section section h3 .split--letter > span:nth-child(57), .active-section section h4 .split--letter > span:nth-child(57), .active-section section h5 .split--letter > span:nth-child(57), .active-section section h6 .split--letter > span:nth-child(57) {
  transition-delay: 5.73s; }

.active-section section h2 .split--letter > span:nth-child(58), .active-section section h3 .split--letter > span:nth-child(58), .active-section section h4 .split--letter > span:nth-child(58), .active-section section h5 .split--letter > span:nth-child(58), .active-section section h6 .split--letter > span:nth-child(58) {
  transition-delay: 5.82s; }

.active-section section h2 .split--letter > span:nth-child(59), .active-section section h3 .split--letter > span:nth-child(59), .active-section section h4 .split--letter > span:nth-child(59), .active-section section h5 .split--letter > span:nth-child(59), .active-section section h6 .split--letter > span:nth-child(59) {
  transition-delay: 5.91s; }

.active-section section h2 .split--letter > span:nth-child(60), .active-section section h3 .split--letter > span:nth-child(60), .active-section section h4 .split--letter > span:nth-child(60), .active-section section h5 .split--letter > span:nth-child(60), .active-section section h6 .split--letter > span:nth-child(60) {
  transition-delay: 6s; }

.active-section section p, .active-section section blockquote {
  opacity: 1;
  transition: transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.9s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .active-section section p .split--word:nth-child(1) span, .active-section section blockquote .split--word:nth-child(1) span {
    transition-delay: 1.23s; }
  .active-section section p .split--word:nth-child(2) span, .active-section section blockquote .split--word:nth-child(2) span {
    transition-delay: 1.26s; }
  .active-section section p .split--word:nth-child(3) span, .active-section section blockquote .split--word:nth-child(3) span {
    transition-delay: 1.29s; }
  .active-section section p .split--word:nth-child(4) span, .active-section section blockquote .split--word:nth-child(4) span {
    transition-delay: 1.32s; }
  .active-section section p .split--word:nth-child(5) span, .active-section section blockquote .split--word:nth-child(5) span {
    transition-delay: 1.35s; }
  .active-section section p .split--word:nth-child(6) span, .active-section section blockquote .split--word:nth-child(6) span {
    transition-delay: 1.38s; }
  .active-section section p .split--word:nth-child(7) span, .active-section section blockquote .split--word:nth-child(7) span {
    transition-delay: 1.41s; }
  .active-section section p .split--word:nth-child(8) span, .active-section section blockquote .split--word:nth-child(8) span {
    transition-delay: 1.44s; }
  .active-section section p .split--word:nth-child(9) span, .active-section section blockquote .split--word:nth-child(9) span {
    transition-delay: 1.47s; }
  .active-section section p .split--word:nth-child(10) span, .active-section section blockquote .split--word:nth-child(10) span {
    transition-delay: 1.5s; }
  .active-section section p .split--word:nth-child(11) span, .active-section section blockquote .split--word:nth-child(11) span {
    transition-delay: 1.53s; }
  .active-section section p .split--word:nth-child(12) span, .active-section section blockquote .split--word:nth-child(12) span {
    transition-delay: 1.56s; }
  .active-section section p .split--word:nth-child(13) span, .active-section section blockquote .split--word:nth-child(13) span {
    transition-delay: 1.59s; }
  .active-section section p .split--word:nth-child(14) span, .active-section section blockquote .split--word:nth-child(14) span {
    transition-delay: 1.62s; }
  .active-section section p .split--word:nth-child(15) span, .active-section section blockquote .split--word:nth-child(15) span {
    transition-delay: 1.65s; }
  .active-section section p .split--word:nth-child(16) span, .active-section section blockquote .split--word:nth-child(16) span {
    transition-delay: 1.68s; }
  .active-section section p .split--word:nth-child(17) span, .active-section section blockquote .split--word:nth-child(17) span {
    transition-delay: 1.71s; }
  .active-section section p .split--word:nth-child(18) span, .active-section section blockquote .split--word:nth-child(18) span {
    transition-delay: 1.74s; }
  .active-section section p .split--word:nth-child(19) span, .active-section section blockquote .split--word:nth-child(19) span {
    transition-delay: 1.77s; }
  .active-section section p .split--word:nth-child(20) span, .active-section section blockquote .split--word:nth-child(20) span {
    transition-delay: 1.8s; }
  .active-section section p .split--word:nth-child(21) span, .active-section section blockquote .split--word:nth-child(21) span {
    transition-delay: 1.83s; }
  .active-section section p .split--word:nth-child(22) span, .active-section section blockquote .split--word:nth-child(22) span {
    transition-delay: 1.86s; }
  .active-section section p .split--word:nth-child(23) span, .active-section section blockquote .split--word:nth-child(23) span {
    transition-delay: 1.89s; }
  .active-section section p .split--word:nth-child(24) span, .active-section section blockquote .split--word:nth-child(24) span {
    transition-delay: 1.92s; }
  .active-section section p .split--word:nth-child(25) span, .active-section section blockquote .split--word:nth-child(25) span {
    transition-delay: 1.95s; }
  .active-section section p .split--word:nth-child(26) span, .active-section section blockquote .split--word:nth-child(26) span {
    transition-delay: 1.98s; }
  .active-section section p .split--word:nth-child(27) span, .active-section section blockquote .split--word:nth-child(27) span {
    transition-delay: 2.01s; }
  .active-section section p .split--word:nth-child(28) span, .active-section section blockquote .split--word:nth-child(28) span {
    transition-delay: 2.04s; }
  .active-section section p .split--word:nth-child(29) span, .active-section section blockquote .split--word:nth-child(29) span {
    transition-delay: 2.07s; }
  .active-section section p .split--word:nth-child(30) span, .active-section section blockquote .split--word:nth-child(30) span {
    transition-delay: 2.1s; }
  .active-section section p .split--word:nth-child(31) span, .active-section section blockquote .split--word:nth-child(31) span {
    transition-delay: 2.13s; }
  .active-section section p .split--word:nth-child(32) span, .active-section section blockquote .split--word:nth-child(32) span {
    transition-delay: 2.16s; }
  .active-section section p .split--word:nth-child(33) span, .active-section section blockquote .split--word:nth-child(33) span {
    transition-delay: 2.19s; }
  .active-section section p .split--word:nth-child(34) span, .active-section section blockquote .split--word:nth-child(34) span {
    transition-delay: 2.22s; }
  .active-section section p .split--word:nth-child(35) span, .active-section section blockquote .split--word:nth-child(35) span {
    transition-delay: 2.25s; }
  .active-section section p .split--word:nth-child(36) span, .active-section section blockquote .split--word:nth-child(36) span {
    transition-delay: 2.28s; }
  .active-section section p .split--word:nth-child(37) span, .active-section section blockquote .split--word:nth-child(37) span {
    transition-delay: 2.31s; }
  .active-section section p .split--word:nth-child(38) span, .active-section section blockquote .split--word:nth-child(38) span {
    transition-delay: 2.34s; }
  .active-section section p .split--word:nth-child(39) span, .active-section section blockquote .split--word:nth-child(39) span {
    transition-delay: 2.37s; }
  .active-section section p .split--word:nth-child(40) span, .active-section section blockquote .split--word:nth-child(40) span {
    transition-delay: 2.4s; }
  .active-section section p .split--word:nth-child(41) span, .active-section section blockquote .split--word:nth-child(41) span {
    transition-delay: 2.43s; }
  .active-section section p .split--word:nth-child(42) span, .active-section section blockquote .split--word:nth-child(42) span {
    transition-delay: 2.46s; }
  .active-section section p .split--word:nth-child(43) span, .active-section section blockquote .split--word:nth-child(43) span {
    transition-delay: 2.49s; }
  .active-section section p .split--word:nth-child(44) span, .active-section section blockquote .split--word:nth-child(44) span {
    transition-delay: 2.52s; }
  .active-section section p .split--word:nth-child(45) span, .active-section section blockquote .split--word:nth-child(45) span {
    transition-delay: 2.55s; }
  .active-section section p .split--word:nth-child(46) span, .active-section section blockquote .split--word:nth-child(46) span {
    transition-delay: 2.58s; }
  .active-section section p .split--word:nth-child(47) span, .active-section section blockquote .split--word:nth-child(47) span {
    transition-delay: 2.61s; }
  .active-section section p .split--word:nth-child(48) span, .active-section section blockquote .split--word:nth-child(48) span {
    transition-delay: 2.64s; }
  .active-section section p .split--word:nth-child(49) span, .active-section section blockquote .split--word:nth-child(49) span {
    transition-delay: 2.67s; }
  .active-section section p .split--word:nth-child(50) span, .active-section section blockquote .split--word:nth-child(50) span {
    transition-delay: 2.7s; }
  .active-section section p .split--word:nth-child(51) span, .active-section section blockquote .split--word:nth-child(51) span {
    transition-delay: 2.73s; }
  .active-section section p .split--word:nth-child(52) span, .active-section section blockquote .split--word:nth-child(52) span {
    transition-delay: 2.76s; }
  .active-section section p .split--word:nth-child(53) span, .active-section section blockquote .split--word:nth-child(53) span {
    transition-delay: 2.79s; }
  .active-section section p .split--word:nth-child(54) span, .active-section section blockquote .split--word:nth-child(54) span {
    transition-delay: 2.82s; }
  .active-section section p .split--word:nth-child(55) span, .active-section section blockquote .split--word:nth-child(55) span {
    transition-delay: 2.85s; }
  .active-section section p .split--word:nth-child(56) span, .active-section section blockquote .split--word:nth-child(56) span {
    transition-delay: 2.88s; }
  .active-section section p .split--word:nth-child(57) span, .active-section section blockquote .split--word:nth-child(57) span {
    transition-delay: 2.91s; }
  .active-section section p .split--word:nth-child(58) span, .active-section section blockquote .split--word:nth-child(58) span {
    transition-delay: 2.94s; }
  .active-section section p .split--word:nth-child(59) span, .active-section section blockquote .split--word:nth-child(59) span {
    transition-delay: 2.97s; }
  .active-section section p .split--word:nth-child(60) span, .active-section section blockquote .split--word:nth-child(60) span {
    transition-delay: 3s; }
  .active-section section p .split--word:nth-child(61) span, .active-section section blockquote .split--word:nth-child(61) span {
    transition-delay: 3.03s; }
  .active-section section p .split--word:nth-child(62) span, .active-section section blockquote .split--word:nth-child(62) span {
    transition-delay: 3.06s; }
  .active-section section p .split--word:nth-child(63) span, .active-section section blockquote .split--word:nth-child(63) span {
    transition-delay: 3.09s; }
  .active-section section p .split--word:nth-child(64) span, .active-section section blockquote .split--word:nth-child(64) span {
    transition-delay: 3.12s; }
  .active-section section p .split--word:nth-child(65) span, .active-section section blockquote .split--word:nth-child(65) span {
    transition-delay: 3.15s; }
  .active-section section p .split--word:nth-child(66) span, .active-section section blockquote .split--word:nth-child(66) span {
    transition-delay: 3.18s; }
  .active-section section p .split--word:nth-child(67) span, .active-section section blockquote .split--word:nth-child(67) span {
    transition-delay: 3.21s; }
  .active-section section p .split--word:nth-child(68) span, .active-section section blockquote .split--word:nth-child(68) span {
    transition-delay: 3.24s; }
  .active-section section p .split--word:nth-child(69) span, .active-section section blockquote .split--word:nth-child(69) span {
    transition-delay: 3.27s; }
  .active-section section p .split--word:nth-child(70) span, .active-section section blockquote .split--word:nth-child(70) span {
    transition-delay: 3.3s; }
  .active-section section p .split--word:nth-child(71) span, .active-section section blockquote .split--word:nth-child(71) span {
    transition-delay: 3.33s; }
  .active-section section p .split--word:nth-child(72) span, .active-section section blockquote .split--word:nth-child(72) span {
    transition-delay: 3.36s; }
  .active-section section p .split--word:nth-child(73) span, .active-section section blockquote .split--word:nth-child(73) span {
    transition-delay: 3.39s; }
  .active-section section p .split--word:nth-child(74) span, .active-section section blockquote .split--word:nth-child(74) span {
    transition-delay: 3.42s; }
  .active-section section p .split--word:nth-child(75) span, .active-section section blockquote .split--word:nth-child(75) span {
    transition-delay: 3.45s; }
  .active-section section p .split--word:nth-child(76) span, .active-section section blockquote .split--word:nth-child(76) span {
    transition-delay: 3.48s; }
  .active-section section p .split--word:nth-child(77) span, .active-section section blockquote .split--word:nth-child(77) span {
    transition-delay: 3.51s; }
  .active-section section p .split--word:nth-child(78) span, .active-section section blockquote .split--word:nth-child(78) span {
    transition-delay: 3.54s; }
  .active-section section p .split--word:nth-child(79) span, .active-section section blockquote .split--word:nth-child(79) span {
    transition-delay: 3.57s; }
  .active-section section p .split--word:nth-child(80) span, .active-section section blockquote .split--word:nth-child(80) span {
    transition-delay: 3.6s; }
  .active-section section p .split--word:nth-child(81) span, .active-section section blockquote .split--word:nth-child(81) span {
    transition-delay: 3.63s; }
  .active-section section p .split--word:nth-child(82) span, .active-section section blockquote .split--word:nth-child(82) span {
    transition-delay: 3.66s; }
  .active-section section p .split--word:nth-child(83) span, .active-section section blockquote .split--word:nth-child(83) span {
    transition-delay: 3.69s; }
  .active-section section p .split--word:nth-child(84) span, .active-section section blockquote .split--word:nth-child(84) span {
    transition-delay: 3.72s; }
  .active-section section p .split--word:nth-child(85) span, .active-section section blockquote .split--word:nth-child(85) span {
    transition-delay: 3.75s; }
  .active-section section p .split--word:nth-child(86) span, .active-section section blockquote .split--word:nth-child(86) span {
    transition-delay: 3.78s; }
  .active-section section p .split--word:nth-child(87) span, .active-section section blockquote .split--word:nth-child(87) span {
    transition-delay: 3.81s; }
  .active-section section p .split--word:nth-child(88) span, .active-section section blockquote .split--word:nth-child(88) span {
    transition-delay: 3.84s; }
  .active-section section p .split--word:nth-child(89) span, .active-section section blockquote .split--word:nth-child(89) span {
    transition-delay: 3.87s; }
  .active-section section p .split--word:nth-child(90) span, .active-section section blockquote .split--word:nth-child(90) span {
    transition-delay: 3.9s; }
  .active-section section p .split--word:nth-child(91) span, .active-section section blockquote .split--word:nth-child(91) span {
    transition-delay: 3.93s; }
  .active-section section p .split--word:nth-child(92) span, .active-section section blockquote .split--word:nth-child(92) span {
    transition-delay: 3.96s; }
  .active-section section p .split--word:nth-child(93) span, .active-section section blockquote .split--word:nth-child(93) span {
    transition-delay: 3.99s; }
  .active-section section p .split--word:nth-child(94) span, .active-section section blockquote .split--word:nth-child(94) span {
    transition-delay: 4.02s; }
  .active-section section p .split--word:nth-child(95) span, .active-section section blockquote .split--word:nth-child(95) span {
    transition-delay: 4.05s; }
  .active-section section p .split--word:nth-child(96) span, .active-section section blockquote .split--word:nth-child(96) span {
    transition-delay: 4.08s; }
  .active-section section p .split--word:nth-child(97) span, .active-section section blockquote .split--word:nth-child(97) span {
    transition-delay: 4.11s; }
  .active-section section p .split--word:nth-child(98) span, .active-section section blockquote .split--word:nth-child(98) span {
    transition-delay: 4.14s; }
  .active-section section p .split--word:nth-child(99) span, .active-section section blockquote .split--word:nth-child(99) span {
    transition-delay: 4.17s; }
  .active-section section p .split--word:nth-child(100) span, .active-section section blockquote .split--word:nth-child(100) span {
    transition-delay: 4.2s; }
  .active-section section p .split--word:nth-child(101) span, .active-section section blockquote .split--word:nth-child(101) span {
    transition-delay: 4.23s; }
  .active-section section p .split--word:nth-child(102) span, .active-section section blockquote .split--word:nth-child(102) span {
    transition-delay: 4.26s; }
  .active-section section p .split--word:nth-child(103) span, .active-section section blockquote .split--word:nth-child(103) span {
    transition-delay: 4.29s; }
  .active-section section p .split--word:nth-child(104) span, .active-section section blockquote .split--word:nth-child(104) span {
    transition-delay: 4.32s; }
  .active-section section p .split--word:nth-child(105) span, .active-section section blockquote .split--word:nth-child(105) span {
    transition-delay: 4.35s; }
  .active-section section p .split--word:nth-child(106) span, .active-section section blockquote .split--word:nth-child(106) span {
    transition-delay: 4.38s; }
  .active-section section p .split--word:nth-child(107) span, .active-section section blockquote .split--word:nth-child(107) span {
    transition-delay: 4.41s; }
  .active-section section p .split--word:nth-child(108) span, .active-section section blockquote .split--word:nth-child(108) span {
    transition-delay: 4.44s; }
  .active-section section p .split--word:nth-child(109) span, .active-section section blockquote .split--word:nth-child(109) span {
    transition-delay: 4.47s; }
  .active-section section p .split--word:nth-child(110) span, .active-section section blockquote .split--word:nth-child(110) span {
    transition-delay: 4.5s; }
  .active-section section p .split--word:nth-child(111) span, .active-section section blockquote .split--word:nth-child(111) span {
    transition-delay: 4.53s; }
  .active-section section p .split--word:nth-child(112) span, .active-section section blockquote .split--word:nth-child(112) span {
    transition-delay: 4.56s; }
  .active-section section p .split--word:nth-child(113) span, .active-section section blockquote .split--word:nth-child(113) span {
    transition-delay: 4.59s; }
  .active-section section p .split--word:nth-child(114) span, .active-section section blockquote .split--word:nth-child(114) span {
    transition-delay: 4.62s; }
  .active-section section p .split--word:nth-child(115) span, .active-section section blockquote .split--word:nth-child(115) span {
    transition-delay: 4.65s; }
  .active-section section p .split--word:nth-child(116) span, .active-section section blockquote .split--word:nth-child(116) span {
    transition-delay: 4.68s; }
  .active-section section p .split--word:nth-child(117) span, .active-section section blockquote .split--word:nth-child(117) span {
    transition-delay: 4.71s; }
  .active-section section p .split--word:nth-child(118) span, .active-section section blockquote .split--word:nth-child(118) span {
    transition-delay: 4.74s; }
  .active-section section p .split--word:nth-child(119) span, .active-section section blockquote .split--word:nth-child(119) span {
    transition-delay: 4.77s; }
  .active-section section p .split--word:nth-child(120) span, .active-section section blockquote .split--word:nth-child(120) span {
    transition-delay: 4.8s; }
  .active-section section p .split--letter > span:nth-child(1), .active-section section blockquote .split--letter > span:nth-child(1) {
    transition-delay: 1.23s; }
  .active-section section p .split--letter > span:nth-child(2), .active-section section blockquote .split--letter > span:nth-child(2) {
    transition-delay: 1.26s; }
  .active-section section p .split--letter > span:nth-child(3), .active-section section blockquote .split--letter > span:nth-child(3) {
    transition-delay: 1.29s; }
  .active-section section p .split--letter > span:nth-child(4), .active-section section blockquote .split--letter > span:nth-child(4) {
    transition-delay: 1.32s; }
  .active-section section p .split--letter > span:nth-child(5), .active-section section blockquote .split--letter > span:nth-child(5) {
    transition-delay: 1.35s; }
  .active-section section p .split--letter > span:nth-child(6), .active-section section blockquote .split--letter > span:nth-child(6) {
    transition-delay: 1.38s; }
  .active-section section p .split--letter > span:nth-child(7), .active-section section blockquote .split--letter > span:nth-child(7) {
    transition-delay: 1.41s; }
  .active-section section p .split--letter > span:nth-child(8), .active-section section blockquote .split--letter > span:nth-child(8) {
    transition-delay: 1.44s; }
  .active-section section p .split--letter > span:nth-child(9), .active-section section blockquote .split--letter > span:nth-child(9) {
    transition-delay: 1.47s; }
  .active-section section p .split--letter > span:nth-child(10), .active-section section blockquote .split--letter > span:nth-child(10) {
    transition-delay: 1.5s; }
  .active-section section p .split--letter > span:nth-child(11), .active-section section blockquote .split--letter > span:nth-child(11) {
    transition-delay: 1.53s; }
  .active-section section p .split--letter > span:nth-child(12), .active-section section blockquote .split--letter > span:nth-child(12) {
    transition-delay: 1.56s; }
  .active-section section p .split--letter > span:nth-child(13), .active-section section blockquote .split--letter > span:nth-child(13) {
    transition-delay: 1.59s; }
  .active-section section p .split--letter > span:nth-child(14), .active-section section blockquote .split--letter > span:nth-child(14) {
    transition-delay: 1.62s; }
  .active-section section p .split--letter > span:nth-child(15), .active-section section blockquote .split--letter > span:nth-child(15) {
    transition-delay: 1.65s; }
  .active-section section p .split--letter > span:nth-child(16), .active-section section blockquote .split--letter > span:nth-child(16) {
    transition-delay: 1.68s; }
  .active-section section p .split--letter > span:nth-child(17), .active-section section blockquote .split--letter > span:nth-child(17) {
    transition-delay: 1.71s; }
  .active-section section p .split--letter > span:nth-child(18), .active-section section blockquote .split--letter > span:nth-child(18) {
    transition-delay: 1.74s; }
  .active-section section p .split--letter > span:nth-child(19), .active-section section blockquote .split--letter > span:nth-child(19) {
    transition-delay: 1.77s; }
  .active-section section p .split--letter > span:nth-child(20), .active-section section blockquote .split--letter > span:nth-child(20) {
    transition-delay: 1.8s; }
  .active-section section p .split--letter > span:nth-child(21), .active-section section blockquote .split--letter > span:nth-child(21) {
    transition-delay: 1.83s; }
  .active-section section p .split--letter > span:nth-child(22), .active-section section blockquote .split--letter > span:nth-child(22) {
    transition-delay: 1.86s; }
  .active-section section p .split--letter > span:nth-child(23), .active-section section blockquote .split--letter > span:nth-child(23) {
    transition-delay: 1.89s; }
  .active-section section p .split--letter > span:nth-child(24), .active-section section blockquote .split--letter > span:nth-child(24) {
    transition-delay: 1.92s; }
  .active-section section p .split--letter > span:nth-child(25), .active-section section blockquote .split--letter > span:nth-child(25) {
    transition-delay: 1.95s; }
  .active-section section p .split--letter > span:nth-child(26), .active-section section blockquote .split--letter > span:nth-child(26) {
    transition-delay: 1.98s; }
  .active-section section p .split--letter > span:nth-child(27), .active-section section blockquote .split--letter > span:nth-child(27) {
    transition-delay: 2.01s; }
  .active-section section p .split--letter > span:nth-child(28), .active-section section blockquote .split--letter > span:nth-child(28) {
    transition-delay: 2.04s; }
  .active-section section p .split--letter > span:nth-child(29), .active-section section blockquote .split--letter > span:nth-child(29) {
    transition-delay: 2.07s; }
  .active-section section p .split--letter > span:nth-child(30), .active-section section blockquote .split--letter > span:nth-child(30) {
    transition-delay: 2.1s; }
  .active-section section p .split--letter > span:nth-child(31), .active-section section blockquote .split--letter > span:nth-child(31) {
    transition-delay: 2.13s; }
  .active-section section p .split--letter > span:nth-child(32), .active-section section blockquote .split--letter > span:nth-child(32) {
    transition-delay: 2.16s; }
  .active-section section p .split--letter > span:nth-child(33), .active-section section blockquote .split--letter > span:nth-child(33) {
    transition-delay: 2.19s; }
  .active-section section p .split--letter > span:nth-child(34), .active-section section blockquote .split--letter > span:nth-child(34) {
    transition-delay: 2.22s; }
  .active-section section p .split--letter > span:nth-child(35), .active-section section blockquote .split--letter > span:nth-child(35) {
    transition-delay: 2.25s; }
  .active-section section p .split--letter > span:nth-child(36), .active-section section blockquote .split--letter > span:nth-child(36) {
    transition-delay: 2.28s; }
  .active-section section p .split--letter > span:nth-child(37), .active-section section blockquote .split--letter > span:nth-child(37) {
    transition-delay: 2.31s; }
  .active-section section p .split--letter > span:nth-child(38), .active-section section blockquote .split--letter > span:nth-child(38) {
    transition-delay: 2.34s; }
  .active-section section p .split--letter > span:nth-child(39), .active-section section blockquote .split--letter > span:nth-child(39) {
    transition-delay: 2.37s; }
  .active-section section p .split--letter > span:nth-child(40), .active-section section blockquote .split--letter > span:nth-child(40) {
    transition-delay: 2.4s; }
  .active-section section p .split--letter > span:nth-child(41), .active-section section blockquote .split--letter > span:nth-child(41) {
    transition-delay: 2.43s; }
  .active-section section p .split--letter > span:nth-child(42), .active-section section blockquote .split--letter > span:nth-child(42) {
    transition-delay: 2.46s; }
  .active-section section p .split--letter > span:nth-child(43), .active-section section blockquote .split--letter > span:nth-child(43) {
    transition-delay: 2.49s; }
  .active-section section p .split--letter > span:nth-child(44), .active-section section blockquote .split--letter > span:nth-child(44) {
    transition-delay: 2.52s; }
  .active-section section p .split--letter > span:nth-child(45), .active-section section blockquote .split--letter > span:nth-child(45) {
    transition-delay: 2.55s; }
  .active-section section p .split--letter > span:nth-child(46), .active-section section blockquote .split--letter > span:nth-child(46) {
    transition-delay: 2.58s; }
  .active-section section p .split--letter > span:nth-child(47), .active-section section blockquote .split--letter > span:nth-child(47) {
    transition-delay: 2.61s; }
  .active-section section p .split--letter > span:nth-child(48), .active-section section blockquote .split--letter > span:nth-child(48) {
    transition-delay: 2.64s; }
  .active-section section p .split--letter > span:nth-child(49), .active-section section blockquote .split--letter > span:nth-child(49) {
    transition-delay: 2.67s; }
  .active-section section p .split--letter > span:nth-child(50), .active-section section blockquote .split--letter > span:nth-child(50) {
    transition-delay: 2.7s; }
  .active-section section p .split--letter > span:nth-child(51), .active-section section blockquote .split--letter > span:nth-child(51) {
    transition-delay: 2.73s; }
  .active-section section p .split--letter > span:nth-child(52), .active-section section blockquote .split--letter > span:nth-child(52) {
    transition-delay: 2.76s; }
  .active-section section p .split--letter > span:nth-child(53), .active-section section blockquote .split--letter > span:nth-child(53) {
    transition-delay: 2.79s; }
  .active-section section p .split--letter > span:nth-child(54), .active-section section blockquote .split--letter > span:nth-child(54) {
    transition-delay: 2.82s; }
  .active-section section p .split--letter > span:nth-child(55), .active-section section blockquote .split--letter > span:nth-child(55) {
    transition-delay: 2.85s; }
  .active-section section p .split--letter > span:nth-child(56), .active-section section blockquote .split--letter > span:nth-child(56) {
    transition-delay: 2.88s; }
  .active-section section p .split--letter > span:nth-child(57), .active-section section blockquote .split--letter > span:nth-child(57) {
    transition-delay: 2.91s; }
  .active-section section p .split--letter > span:nth-child(58), .active-section section blockquote .split--letter > span:nth-child(58) {
    transition-delay: 2.94s; }
  .active-section section p .split--letter > span:nth-child(59), .active-section section blockquote .split--letter > span:nth-child(59) {
    transition-delay: 2.97s; }
  .active-section section p .split--letter > span:nth-child(60), .active-section section blockquote .split--letter > span:nth-child(60) {
    transition-delay: 3s; }
  .active-section section p .split--letter > span:nth-child(61), .active-section section blockquote .split--letter > span:nth-child(61) {
    transition-delay: 3.03s; }
  .active-section section p .split--letter > span:nth-child(62), .active-section section blockquote .split--letter > span:nth-child(62) {
    transition-delay: 3.06s; }
  .active-section section p .split--letter > span:nth-child(63), .active-section section blockquote .split--letter > span:nth-child(63) {
    transition-delay: 3.09s; }
  .active-section section p .split--letter > span:nth-child(64), .active-section section blockquote .split--letter > span:nth-child(64) {
    transition-delay: 3.12s; }
  .active-section section p .split--letter > span:nth-child(65), .active-section section blockquote .split--letter > span:nth-child(65) {
    transition-delay: 3.15s; }
  .active-section section p .split--letter > span:nth-child(66), .active-section section blockquote .split--letter > span:nth-child(66) {
    transition-delay: 3.18s; }
  .active-section section p .split--letter > span:nth-child(67), .active-section section blockquote .split--letter > span:nth-child(67) {
    transition-delay: 3.21s; }
  .active-section section p .split--letter > span:nth-child(68), .active-section section blockquote .split--letter > span:nth-child(68) {
    transition-delay: 3.24s; }
  .active-section section p .split--letter > span:nth-child(69), .active-section section blockquote .split--letter > span:nth-child(69) {
    transition-delay: 3.27s; }
  .active-section section p .split--letter > span:nth-child(70), .active-section section blockquote .split--letter > span:nth-child(70) {
    transition-delay: 3.3s; }
  .active-section section p .split--letter > span:nth-child(71), .active-section section blockquote .split--letter > span:nth-child(71) {
    transition-delay: 3.33s; }
  .active-section section p .split--letter > span:nth-child(72), .active-section section blockquote .split--letter > span:nth-child(72) {
    transition-delay: 3.36s; }
  .active-section section p .split--letter > span:nth-child(73), .active-section section blockquote .split--letter > span:nth-child(73) {
    transition-delay: 3.39s; }
  .active-section section p .split--letter > span:nth-child(74), .active-section section blockquote .split--letter > span:nth-child(74) {
    transition-delay: 3.42s; }
  .active-section section p .split--letter > span:nth-child(75), .active-section section blockquote .split--letter > span:nth-child(75) {
    transition-delay: 3.45s; }
  .active-section section p .split--letter > span:nth-child(76), .active-section section blockquote .split--letter > span:nth-child(76) {
    transition-delay: 3.48s; }
  .active-section section p .split--letter > span:nth-child(77), .active-section section blockquote .split--letter > span:nth-child(77) {
    transition-delay: 3.51s; }
  .active-section section p .split--letter > span:nth-child(78), .active-section section blockquote .split--letter > span:nth-child(78) {
    transition-delay: 3.54s; }
  .active-section section p .split--letter > span:nth-child(79), .active-section section blockquote .split--letter > span:nth-child(79) {
    transition-delay: 3.57s; }
  .active-section section p .split--letter > span:nth-child(80), .active-section section blockquote .split--letter > span:nth-child(80) {
    transition-delay: 3.6s; }
  .active-section section p .split--letter > span:nth-child(81), .active-section section blockquote .split--letter > span:nth-child(81) {
    transition-delay: 3.63s; }
  .active-section section p .split--letter > span:nth-child(82), .active-section section blockquote .split--letter > span:nth-child(82) {
    transition-delay: 3.66s; }
  .active-section section p .split--letter > span:nth-child(83), .active-section section blockquote .split--letter > span:nth-child(83) {
    transition-delay: 3.69s; }
  .active-section section p .split--letter > span:nth-child(84), .active-section section blockquote .split--letter > span:nth-child(84) {
    transition-delay: 3.72s; }
  .active-section section p .split--letter > span:nth-child(85), .active-section section blockquote .split--letter > span:nth-child(85) {
    transition-delay: 3.75s; }
  .active-section section p .split--letter > span:nth-child(86), .active-section section blockquote .split--letter > span:nth-child(86) {
    transition-delay: 3.78s; }
  .active-section section p .split--letter > span:nth-child(87), .active-section section blockquote .split--letter > span:nth-child(87) {
    transition-delay: 3.81s; }
  .active-section section p .split--letter > span:nth-child(88), .active-section section blockquote .split--letter > span:nth-child(88) {
    transition-delay: 3.84s; }
  .active-section section p .split--letter > span:nth-child(89), .active-section section blockquote .split--letter > span:nth-child(89) {
    transition-delay: 3.87s; }
  .active-section section p .split--letter > span:nth-child(90), .active-section section blockquote .split--letter > span:nth-child(90) {
    transition-delay: 3.9s; }
  .active-section section p .split--letter > span:nth-child(91), .active-section section blockquote .split--letter > span:nth-child(91) {
    transition-delay: 3.93s; }
  .active-section section p .split--letter > span:nth-child(92), .active-section section blockquote .split--letter > span:nth-child(92) {
    transition-delay: 3.96s; }
  .active-section section p .split--letter > span:nth-child(93), .active-section section blockquote .split--letter > span:nth-child(93) {
    transition-delay: 3.99s; }
  .active-section section p .split--letter > span:nth-child(94), .active-section section blockquote .split--letter > span:nth-child(94) {
    transition-delay: 4.02s; }
  .active-section section p .split--letter > span:nth-child(95), .active-section section blockquote .split--letter > span:nth-child(95) {
    transition-delay: 4.05s; }
  .active-section section p .split--letter > span:nth-child(96), .active-section section blockquote .split--letter > span:nth-child(96) {
    transition-delay: 4.08s; }
  .active-section section p .split--letter > span:nth-child(97), .active-section section blockquote .split--letter > span:nth-child(97) {
    transition-delay: 4.11s; }
  .active-section section p .split--letter > span:nth-child(98), .active-section section blockquote .split--letter > span:nth-child(98) {
    transition-delay: 4.14s; }
  .active-section section p .split--letter > span:nth-child(99), .active-section section blockquote .split--letter > span:nth-child(99) {
    transition-delay: 4.17s; }
  .active-section section p .split--letter > span:nth-child(100), .active-section section blockquote .split--letter > span:nth-child(100) {
    transition-delay: 4.2s; }
  .active-section section p .split--letter > span:nth-child(101), .active-section section blockquote .split--letter > span:nth-child(101) {
    transition-delay: 4.23s; }
  .active-section section p .split--letter > span:nth-child(102), .active-section section blockquote .split--letter > span:nth-child(102) {
    transition-delay: 4.26s; }
  .active-section section p .split--letter > span:nth-child(103), .active-section section blockquote .split--letter > span:nth-child(103) {
    transition-delay: 4.29s; }
  .active-section section p .split--letter > span:nth-child(104), .active-section section blockquote .split--letter > span:nth-child(104) {
    transition-delay: 4.32s; }
  .active-section section p .split--letter > span:nth-child(105), .active-section section blockquote .split--letter > span:nth-child(105) {
    transition-delay: 4.35s; }
  .active-section section p .split--letter > span:nth-child(106), .active-section section blockquote .split--letter > span:nth-child(106) {
    transition-delay: 4.38s; }
  .active-section section p .split--letter > span:nth-child(107), .active-section section blockquote .split--letter > span:nth-child(107) {
    transition-delay: 4.41s; }
  .active-section section p .split--letter > span:nth-child(108), .active-section section blockquote .split--letter > span:nth-child(108) {
    transition-delay: 4.44s; }
  .active-section section p .split--letter > span:nth-child(109), .active-section section blockquote .split--letter > span:nth-child(109) {
    transition-delay: 4.47s; }
  .active-section section p .split--letter > span:nth-child(110), .active-section section blockquote .split--letter > span:nth-child(110) {
    transition-delay: 4.5s; }
  .active-section section p .split--letter > span:nth-child(111), .active-section section blockquote .split--letter > span:nth-child(111) {
    transition-delay: 4.53s; }
  .active-section section p .split--letter > span:nth-child(112), .active-section section blockquote .split--letter > span:nth-child(112) {
    transition-delay: 4.56s; }
  .active-section section p .split--letter > span:nth-child(113), .active-section section blockquote .split--letter > span:nth-child(113) {
    transition-delay: 4.59s; }
  .active-section section p .split--letter > span:nth-child(114), .active-section section blockquote .split--letter > span:nth-child(114) {
    transition-delay: 4.62s; }
  .active-section section p .split--letter > span:nth-child(115), .active-section section blockquote .split--letter > span:nth-child(115) {
    transition-delay: 4.65s; }
  .active-section section p .split--letter > span:nth-child(116), .active-section section blockquote .split--letter > span:nth-child(116) {
    transition-delay: 4.68s; }
  .active-section section p .split--letter > span:nth-child(117), .active-section section blockquote .split--letter > span:nth-child(117) {
    transition-delay: 4.71s; }
  .active-section section p .split--letter > span:nth-child(118), .active-section section blockquote .split--letter > span:nth-child(118) {
    transition-delay: 4.74s; }
  .active-section section p .split--letter > span:nth-child(119), .active-section section blockquote .split--letter > span:nth-child(119) {
    transition-delay: 4.77s; }
  .active-section section p .split--letter > span:nth-child(120), .active-section section blockquote .split--letter > span:nth-child(120) {
    transition-delay: 4.8s; }
  .active-section section p .split--word > span, .active-section section p .split--letter > span, .active-section section blockquote .split--word > span, .active-section section blockquote .split--letter > span {
    opacity: 1;
    transform: translate3d(0, 0, 0) skewX(0deg);
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-property: transform, opacity; }

.active-section section .image-wrapper {
  transition-duration: 0.9s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-property: transform, opacity;
  transform: translate3d(0, 0, 0) skewX(0deg);
  opacity: 1; }

/* Components */
.arrow-group {
  position: relative;
  display: flex;
  justify-content: flex-start; }
  .arrow-group .arrow-group__first {
    transform: scaleX(-1); }
  .arrow-group__links {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%; }
    .arrow-group__links > * {
      flex: 1; }
    .arrow-group__links div {
      height: 100%; }
  .arrow-group--vertical {
    flex-direction: column; }
    .arrow-group--vertical .arrow-group__links {
      flex-direction: column; }
      .arrow-group--vertical .arrow-group__links > * {
        max-height: 50%; }
    .arrow-group--vertical .arrow-group__first {
      transform: scaleX(-1) rotate(-90deg); }
    .arrow-group--vertical .arrow-group__second {
      transform: rotate(90deg); }

.scrolling-section:first-child .arrow-group__links {
  justify-content: flex-end; }

.scrolling-section:first-child .arrow-group__first {
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.24;
  pointer-events: none; }

.scrolling-section:last-child .arrow-group__links {
  justify-content: flex-start; }

.scrolling-section:last-child .arrow-group__second {
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.24;
  pointer-events: none; }

.carousel {
  position: relative; }
  .carousel .arrow-group {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(0, -100%);
    padding-bottom: 16px; }
    .carousel .arrow-group .arrow-group__first,
    .carousel .arrow-group .arrow-group__second {
      opacity: 0;
      transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1); }
    .carousel .arrow-group .arrow-group__first:nth-child(1), .carousel .arrow-group .arrow-group__second:nth-child(1) {
      transition-delay: 0.78s; }
    .carousel .arrow-group .arrow-group__first:nth-child(2), .carousel .arrow-group .arrow-group__second:nth-child(2) {
      transition-delay: 0.96s; }
  .carousel--nav-bottom .arrow-group {
    left: 0;
    bottom: 0;
    right: auto;
    top: auto;
    transform: translate(0, 100%);
    padding-top: 16px; }
  .carousel__item-wrapper {
    overflow: hidden; }
  .carousel__items, .carousel__item {
    transition: transform cubic-bezier(0.7, 0, 0.3, 1) 0.9s; }
  .carousel__items {
    display: flex;
    flex-wrap: nowrap; }
  .carousel__item {
    min-width: 100%; }
  .active-section .carousel .arrow-group__first,
  .active-section .carousel .arrow-group__second {
    opacity: 1; }

.clip-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  clip: rect(0, auto, auto, 0); }
  .clip-wrapper__left {
    position: fixed;
    top: 0px;
    width: 72px;
    left: 0px;
    border: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex; }
    .clip-wrapper__left > * {
      margin: 60px 0; }
    .clip-wrapper__left > *:first-child:not(*:last-child) {
      margin-bottom: 0; }
    .clip-wrapper__left > *:last-child:not(*:first-child) {
      margin-top: 0; }
    .clip-wrapper__left a {
      pointer-events: none;
      z-index: 999; }
    .clip-wrapper__left .arrow-group {
      transform: translate3d(-33px, 0, 0); }
      .clip-wrapper__left .arrow-group, .clip-wrapper__left .arrow-group__first, .clip-wrapper__left .arrow-group__first:first-child, .clip-wrapper__left .arrow-group__second, .clip-wrapper__left .arrow-group__second:nth-child(2) {
        transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
        pointer-events: auto; }
      .clip-wrapper__left .arrow-group {
        transition-delay: 0.3s; }
      .clip-wrapper__left .arrow-group__first:nth-child(1), .clip-wrapper__left .arrow-group__second:nth-child(1) {
        transition-delay: 0.18s; }
      .clip-wrapper__left .arrow-group__first:nth-child(2), .clip-wrapper__left .arrow-group__second:nth-child(2) {
        transition-delay: 0.36s; }
      .clip-wrapper__left .arrow-group__first .icon, .clip-wrapper__left .arrow-group__second .icon {
        width: 48px;
        height: 48px; }
      .clip-wrapper__left .arrow-group__first {
        transform: scaleX(-1) rotate(0deg) translate3d(0, 4px, 0); }
      .clip-wrapper__left .arrow-group__second {
        transform: rotate(180deg) translate3d(0, 4px, 0); }
      @media screen and (max-width: 800px) {
        .clip-wrapper__left .arrow-group {
          display: none; } }
    .clip-wrapper__left .arrow-nav--hovering .arrow-group {
      transform: translate3d(-12px, 0, 0);
      transition-delay: 0s; }
    .clip-wrapper__left .arrow-nav--hovering .arrow-group__first {
      transform: scaleX(-1) rotate(-90deg); }
    .clip-wrapper__left .arrow-nav--hovering .arrow-group__second {
      transform: rotate(90deg); }
    .clip-wrapper__left hr {
      margin: 6px 18px;
      width: 100%;
      transform: scaleX(0.5);
      transform-origin: left; }
    .active-section .clip-wrapper__left a {
      pointer-events: auto;
      z-index: 999; }
    .clip-wrapper__left__bottom {
      transform: rotate(-90deg) translateX(40%); }
      .clip-wrapper__left__bottom h1, .clip-wrapper__left__bottom h2, .clip-wrapper__left__bottom h3, .clip-wrapper__left__bottom h4, .clip-wrapper__left__bottom h5, .clip-wrapper__left__bottom h6 {
        white-space: nowrap; }
    .clip-wrapper__left i {
      margin: 0 12px; }

.codepen-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: -1px;
  background: #fefefe; }
  .codepen-wrapper iframe {
    width: 100%; }
  .scrolling-section--black .codepen-wrapper {
    background: #131112; }
  .scrolling-section--grey .codepen-wrapper {
    background: #f0f0f0; }

.cursor {
  position: relative;
  pointer-events: none;
  opacity: 0;
  left: 0;
  top: 0;
  position: fixed;
  mix-blend-mode: difference;
  z-index: 9999; }
  .cursor__wrapper {
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .cursor__outer {
    position: fixed;
    width: 100%;
    height: 100%; }
    .cursor__outer circle {
      transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
      transform-origin: center;
      fill: none;
      stroke: #fefefe;
      stroke-width: 1;
      vector-effect: non-scaling-stroke; }
  .cursor__inner, .cursor__text {
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s; }
  .cursor__text {
    color: #fefefe;
    opacity: 0; }
  .cursor__inner {
    position: absolute;
    height: 6px;
    width: 6px;
    background: #fefefe;
    border-radius: 50%; }
  .cursor__line {
    background: #fefefe;
    height: 1px;
    width: 12px; }
  .cursor--visible {
    opacity: 1; }
  .cursor--hovering .cursor__outer circle {
    transform: scale(1.5); }
  .cursor--hovering .cursor__text {
    opacity: 1; }
  .cursor--hovering .cursor__inner {
    opacity: 0; }

.grid-line__container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }
  .grid-line__container .grid, .grid-line__container section {
    height: 100%; }
  .grid-line__container .grid {
    margin: 0 auto; }
    .grid-line__container .grid > div {
      height: 100%; }
  .grid-line__container section {
    background: none;
    justify-content: space-between; }

.grid-line__item {
  display: flex;
  justify-content: space-between; }

.grid-line__vertical, .grid-line__horizontal {
  background-color: rgba(19, 17, 18, 0.06); }

.grid-line__vertical {
  width: 1px;
  min-width: 1px;
  height: 100%;
  min-height: 100vh; }

.grid-line__horizontal {
  height: 1px;
  width: 100%; }

.scrolling-section--black .grid-line__vertical, .scrolling-section--black .grid-line__horizontal {
  background-color: rgba(254, 254, 254, 0.06); }

@media screen and (max-width: 800px) {
  .grid-line section {
    padding: 120px 0; } }

.iframe-wrapper {
  display: flex;
  flex-direction: column;
  border: solid 1px;
  border-radius: 3px;
  overflow: hidden;
  margin-right: -1px;
  border-color: rgba(19, 17, 18, 0.12);
  background: #fefefe;
  position: relative; }
  .iframe-wrapper .iframe-wrapper__controls {
    display: flex;
    justify-content: space-between;
    padding: 12px 18px; }
  .iframe-wrapper .iframe-wrapper__main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: solid 1px rgba(19, 17, 18, 0.12); }
  .iframe-wrapper .icon-arrow {
    transform: rotate(-45deg); }
  .scrolling-section--black .iframe-wrapper {
    background: #131112;
    border-color: rgba(254, 254, 254, 0.12); }
    .scrolling-section--black .iframe-wrapper .iframe-wrapper__main {
      border-color: rgba(254, 254, 254, 0.12); }
  .scrolling-section--grey .iframe-wrapper {
    background: #f0f0f0; }
  @media screen and (max-width: 800px) {
    .iframe-wrapper figcaption {
      display: none; } }

.image-wrapper {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border: solid 2px; }
  .image-wrapper img {
    display: block;
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 0.9s;
    opacity: 0; }
  .scrolling-section .image-wrapper {
    background-color: #f9faf9;
    border-color: #f0f0f0; }
  .scrolling-section--black .image-wrapper {
    background-color: #181617;
    border-color: #262425; }
  .scrolling-section .image-wrapper--loaded {
    border-color: transparent !important;
    background-color: transparent; }
  .image-wrapper--loaded img {
    opacity: 1; }

.nav-takeover {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999; }
  .nav-takeover .style-line {
    position: fixed;
    width: 100%;
    height: 100%; }
  .nav-takeover__main {
    position: fixed;
    width: 100%;
    pointer-events: none;
    height: 100vh;
    top: 0px;
    display: flex;
    overflow: hidden; }
  .nav-takeover__overlay, .nav-takeover__panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s;
    transform-origin: right;
    transform: scale3d(0, 1, 1); }
  .nav-takeover__overlay {
    transition-delay: 0.06s;
    background: #f0f0f0; }
  .nav-takeover__panel {
    background: #fefefe;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    overflow-x: hidden; }
    .nav-takeover__panel > * {
      opacity: 0;
      transition: none; }
    .nav-takeover__panel h1, .nav-takeover__panel h2, .nav-takeover__panel h3, .nav-takeover__panel h4 {
      display: inline;
      pointer-events: none;
      transition: none;
      margin: 0; }
      .nav-takeover__panel h1.hovered, .nav-takeover__panel h2.hovered, .nav-takeover__panel h3.hovered, .nav-takeover__panel h4.hovered {
        color: transparent;
        -webkit-text-stroke: 1px #f63f43; }
    .nav-takeover__panel a {
      white-space: pre;
      height: 100%;
      border: none;
      display: flex;
      align-items: flex-start; }
    .nav-takeover__panel hr {
      height: 3px; }
    .nav-takeover__panel li {
      display: flex;
      align-items: flex-start;
      transform: translate3d(-125%, 0, 0);
      transition: none;
      width: auto;
      min-width: 90px;
      margin-bottom: 40px;
      height: 72px; }
  .nav-takeover__breadcrumbs, .nav-takeover__items {
    padding: 0 40px; }
  .nav-takeover__breadcrumbs {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    align-items: flex-end;
    transform: translateY(-84px); }
    .nav-takeover__breadcrumbs .icon-caret {
      margin: 0 12px;
      transform: translateY(-1px); }
  .nav-takeover__breadcrumbs-item {
    display: flex;
    flex-direction: row-reverse;
    align-items: center; }
  .nav-takeover__line-container {
    width: 1px;
    transform: translateX(-1px);
    padding-top: 160px; }
  .nav-takeover__line {
    background: #f63f43;
    opacity: 0;
    height: 80px;
    width: 100%;
    transform: translate3d(0, -300%, 0);
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.9s; }
  .nav-takeover__controls {
    position: fixed;
    left: 12px;
    top: 52px;
    display: flex; }
  .nav-takeover__item-container {
    flex: 1;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    padding: 160px 0 72px; }
  .nav-takeover__items {
    pointer-events: none;
    position: absolute;
    left: 0;
    min-height: 100%;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .nav-takeover__items li {
      transform: translate3d(-125%, 0, 0);
      transition: transform cubic-bezier(0.7, 0, 0.3, 1) 0.9s; }
      .nav-takeover__items li:nth-child(1) {
        transition-delay: 0.09s; }
      .nav-takeover__items li:nth-child(2) {
        transition-delay: 0.18s; }
      .nav-takeover__items li:nth-child(3) {
        transition-delay: 0.27s; }
      .nav-takeover__items li:nth-child(4) {
        transition-delay: 0.36s; }
      .nav-takeover__items li:nth-child(5) {
        transition-delay: 0.45s; }
      .nav-takeover__items li:nth-child(6) {
        transition-delay: 0.54s; }
      .nav-takeover__items li:nth-child(7) {
        transition-delay: 0.63s; }
      .nav-takeover__items li:nth-child(8) {
        transition-delay: 0.72s; }
      .nav-takeover__items li:nth-child(9) {
        transition-delay: 0.81s; }
      .nav-takeover__items li:nth-child(10) {
        transition-delay: 0.9s; }
      .nav-takeover__items li:nth-child(11) {
        transition-delay: 0.99s; }
      .nav-takeover__items li:nth-child(12) {
        transition-delay: 1.08s; }
      .nav-takeover__items li:nth-child(13) {
        transition-delay: 1.17s; }
      .nav-takeover__items li:nth-child(14) {
        transition-delay: 1.26s; }
      .nav-takeover__items li:nth-child(15) {
        transition-delay: 1.35s; }
      .nav-takeover__items li:nth-child(16) {
        transition-delay: 1.44s; }
      .nav-takeover__items li:nth-child(17) {
        transition-delay: 1.53s; }
      .nav-takeover__items li:nth-child(18) {
        transition-delay: 1.62s; }
      .nav-takeover__items li:nth-child(19) {
        transition-delay: 1.71s; }
      .nav-takeover__items li:nth-child(20) {
        transition-delay: 1.8s; }
      .nav-takeover__items li:nth-child(21) {
        transition-delay: 1.89s; }
      .nav-takeover__items li:nth-child(22) {
        transition-delay: 1.98s; }
      .nav-takeover__items li:nth-child(23) {
        transition-delay: 2.07s; }
      .nav-takeover__items li:nth-child(24) {
        transition-delay: 2.16s; }
  .nav-takeover__items--secondary a.active h1.hovered, .nav-takeover__items--secondary a.active h2.hovered, .nav-takeover__items--secondary a.active h3.hovered, .nav-takeover__items--secondary a.active h4.hovered, .nav-takeover__items--secondary a.active h5.hovered, .nav-takeover__items--secondary a.active h6.hovered {
    color: transparent;
    -webkit-text-stroke: 1px #f63f43; }
  .nav-takeover__items--secondary h1.hovered, .nav-takeover__items--secondary h2.hovered, .nav-takeover__items--secondary h3.hovered, .nav-takeover__items--secondary h4.hovered, .nav-takeover__items--secondary h5.hovered, .nav-takeover__items--secondary h6.hovered {
    color: transparent;
    -webkit-text-stroke: 1px #f63f43; }
  .nav-takeover--menuOpen .nav-takeover__main {
    pointer-events: auto; }
  .nav-takeover--menuOpen .nav-takeover__overlay, .nav-takeover--menuOpen .nav-takeover__panel {
    transform-origin: left;
    transform: none;
    transition-delay: 0s; }
  .nav-takeover--menuOpen .nav-takeover__panel {
    transition-delay: 0.09s; }
  .nav-takeover--menuOpen .nav-takeover__panel > * {
    opacity: 1;
    transition: opacity cubic-bezier(0.77, 0, 0.175, 1) 0.6s 0.6s; }
  .nav-takeover--menuOpen .nav-takeover__items--active {
    pointer-events: auto; }
    .nav-takeover--menuOpen .nav-takeover__items--active > li {
      transform: none;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(1) {
        transition-delay: 0.69s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(2) {
        transition-delay: 0.78s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(3) {
        transition-delay: 0.87s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(4) {
        transition-delay: 0.96s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(5) {
        transition-delay: 1.05s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(6) {
        transition-delay: 1.14s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(7) {
        transition-delay: 1.23s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(8) {
        transition-delay: 1.32s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(9) {
        transition-delay: 1.41s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(10) {
        transition-delay: 1.5s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(11) {
        transition-delay: 1.59s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(12) {
        transition-delay: 1.68s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(13) {
        transition-delay: 1.77s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(14) {
        transition-delay: 1.86s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(15) {
        transition-delay: 1.95s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(16) {
        transition-delay: 2.04s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(17) {
        transition-delay: 2.13s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(18) {
        transition-delay: 2.22s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(19) {
        transition-delay: 2.31s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(20) {
        transition-delay: 2.4s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(21) {
        transition-delay: 2.49s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(22) {
        transition-delay: 2.58s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(23) {
        transition-delay: 2.67s; }
      .nav-takeover--menuOpen .nav-takeover__items--active > li:nth-child(24) {
        transition-delay: 2.76s; }
    .nav-takeover--menuOpen .nav-takeover__items--active .nav-takeover__items {
      pointer-events: none; }
  @media screen and (max-width: 800px) {
    .nav-takeover .nav-takeover__controls {
      left: auto;
      top: auto;
      right: 12px;
      bottom: 12px; }
    .nav-takeover .nav-takeover__line-container, .nav-takeover .nav-takeover__item-container {
      padding-top: 108px; }
    .nav-takeover .nav-takeover__spacer {
      width: 48px; }
    .nav-takeover .nav-takeover__line {
      height: 48px; }
    .nav-takeover .nav-takeover__breadcrumbs, .nav-takeover .nav-takeover__items {
      padding: 0 12px; }
    .nav-takeover .nav-takeover__breadcrumbs {
      transform: translateY(-60px); }
      .nav-takeover .nav-takeover__breadcrumbs .icon-caret {
        margin: 0 6px; }
    .nav-takeover .nav-takeover__panel {
      width: 100%; }
      .nav-takeover .nav-takeover__panel hr {
        display: block; }
      .nav-takeover .nav-takeover__panel ul {
        width: 100%; }
      .nav-takeover .nav-takeover__panel li {
        width: 100%;
        height: 48px;
        margin-bottom: 24px; }
      .nav-takeover .nav-takeover__panel h1, .nav-takeover .nav-takeover__panel h2, .nav-takeover .nav-takeover__panel h3, .nav-takeover .nav-takeover__panel h5, .nav-takeover .nav-takeover__panel h4 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .nav-takeover .nav-takeover__panel a {
        width: 100%;
        display: flex;
        align-items: center;
        white-space: normal; }
      .nav-takeover .nav-takeover__panel .nav-takeover__items .nav-takeover__item:last-child {
        border-right: none; }
    .nav-takeover .nav-takeover--menuOpen {
      opacity: 0; }
      .nav-takeover .nav-takeover--menuOpen .nav-takeover__panel--secondary {
        transform: translate3d(100%, 0, 0); }
    .nav-takeover .tabbed-list__header ul {
      padding-top: 0;
      padding-bottom: 0; }
    .nav-takeover.nav-takeover--secondaryPanelOpen .nav-takeover__panel--secondary {
      transform: none; }
    .nav-takeover.nav-takeover--secondaryPanelOpen .nav-takeover__panel--primary {
      transform: translate3d(100%, 0, 0); }
      .nav-takeover.nav-takeover--secondaryPanelOpen .nav-takeover__panel--primary li {
        transform: translate3d(12%, 0, 0);
        opacity: 0; } }

@keyframes increasingNum {
  0% {
    opacity: 0;
    transform: translate3d(0, 60px, 0); }
  100% {
    opacity: 1;
    transform: none; } }

@keyframes decreasingNum {
  0% {
    opacity: 0;
    transform: translate3d(0, -60px, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.nav-toggle {
  position: fixed;
  right: 0;
  top: 24px;
  width: 80px;
  height: 120px;
  pointer-events: auto;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999; }
  .active-section .nav-toggle {
    display: flex; }
  .nav-toggle h4, .nav-toggle h5, .nav-toggle h6, .nav-toggle p {
    line-height: 20px; }
  .nav-toggle .nav-toggle__abbreviation, .nav-toggle .nav-toggle__count {
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-duration: 0.3s;
    transition-property: transform, opacity; }
  .nav-toggle .nav-toggle__count {
    overflow: hidden;
    transition-delay: 0.09s; }
    .nav-toggle .nav-toggle__count span {
      transition: transform cubic-bezier(0.19, 1, 0.22, 1) 0.9s;
      display: inline-block;
      animation: none; }
      .nav-toggle .nav-toggle__count span:last-child {
        transition-delay: 0.06s; }
  .nav-toggle .nav-toggle__count {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 20px; }
  .nav-toggle__hamburger {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    padding-top: 18px;
    padding-bottom: 24px; }
  .nav-toggle__line {
    transition: transform cubic-bezier(0.19, 1, 0.22, 1) 0.6s, background-color cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
    background-color: #131112;
    height: 1px;
    border-radius: 1px;
    width: 100%; }
    .nav-toggle__line:first-child, .nav-toggle__line:last-child {
      opacity: 0; }
  .scrolling-section--black .nav-toggle .nav-toggle__line {
    background-color: #fefefe; }
  .nav-toggle--hovering .nav-toggle__hamburger {
    min-height: 18px; }
  .nav-toggle--hovering .nav-toggle__line {
    opacity: 1; }
    .nav-toggle--hovering .nav-toggle__line:first-child {
      transform: translate3d(0, -10px, 0); }
    .nav-toggle--hovering .nav-toggle__line:last-child {
      transform: translate3d(0, 10px, 0); }
  .nav-toggle--hovering .nav-toggle__abbreviation, .nav-toggle--hovering .nav-toggle__count {
    opacity: 0; }
  .nav-toggle--hovering .nav-toggle__abbreviation {
    transform: translate3d(0, -12px, 0); }
  .nav-toggle--hovering .nav-toggle__count {
    transform: translate3d(0, 12px, 0); }
  .nav-toggle--menuOpen .nav-toggle__hamburger .nav-toggle__line {
    background-color: #131112;
    opacity: 1; }
    .nav-toggle--menuOpen .nav-toggle__hamburger .nav-toggle__line:first-child {
      transform: translate3d(2px, 0px, 0) rotate(135deg) scaleX(0.72); }
    .nav-toggle--menuOpen .nav-toggle__hamburger .nav-toggle__line:nth-child(2) {
      opacity: 0; }
    .nav-toggle--menuOpen .nav-toggle__hamburger .nav-toggle__line:last-child {
      transform: translate3d(2px, -2px, 0) rotate(45deg) scaleX(0.72); }
  .nav-toggle--menuOpen .nav-toggle__abbreviation, .nav-toggle--menuOpen .nav-toggle__count {
    opacity: 0; }
  .nav-toggle--menuOpen .nav-toggle__abbreviation {
    transform: translate3d(0, -12px, 0); }
  .nav-toggle--menuOpen .nav-toggle__count {
    transform: translate3d(0, 12px, 0); }
  @media screen and (max-width: 800px) {
    .nav-toggle {
      top: 12px;
      width: 49px;
      height: 90px; }
      .nav-toggle .nav-toggle__hamburger {
        padding-top: 12px;
        padding-bottom: 16px; }
      .nav-toggle .nav-toggle--menuOpen {
        opacity: 0; } }

.next-project {
  cursor: none;
  opacity: 0; }
  .active-section .next-project {
    opacity: 1; }
  .next-project i {
    position: absolute;
    right: 0;
    top: 12px; }
  .next-project h1 {
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s; }
  .next-project section {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0; }

.page-transition__wipe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #f0f0f0;
  transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
  transition-delay: 0.09s;
  transform-origin: right;
  transform: scale3d(0, 1, 1); }
  .page-transition__wipe:last-child {
    transition-delay: 0s;
    background: #fefefe; }

.page-transition--animating .page-transition__wipe {
  transform-origin: left;
  transform: none; }

.parallax-background {
  position: absolute;
  will-change: transform;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  justify-content: center;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .scrolling-section--black .parallax-background {
    background-color: transparent; }
  .parallax-background--hidden {
    position: relative;
    display: none; }
  @media screen and (max-width: 800px) {
    .parallax-background {
      justify-content: flex-start; } }

.process {
  position: relative;
  width: 100%;
  height: auto; }
  .process ul {
    list-style: none; }
  .process .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    text-align: center;
    opacity: 1;
    width: 66.6667%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .process .title > * {
      justify-content: center; }
  .process .step__arc {
    stroke-width: 2px;
    stroke: #131112;
    stroke-dashoffset: 350;
    stroke-dasharray: 350;
    fill: transparent;
    vector-effect: non-scaling-stroke;
    transition: stroke-dasharray 0.3s cubic-bezier(0.7, 0, 0.3, 1); }
    .process .step__arc--active {
      stroke-dasharray: 700; }
  .process .circle__arc {
    stroke-dashoffset: 350;
    stroke-dasharray: 350;
    stroke: rgba(19, 17, 18, 0.24);
    stroke-width: 2;
    fill: transparent;
    vector-effect: non-scaling-stroke; }
  .process .step__number {
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: #131112;
    line-height: 1.4;
    height: 14px;
    text-align: center;
    opacity: 1; }
  .process .step__icon {
    position: relative;
    width: 60px;
    background-position: bottom left;
    background-repeat: no-repeat;
    opacity: 0;
    margin-bottom: 8px; }
  .process .step__spot-svg {
    width: 30px;
    height: 30px;
    opacity: 0; }
  .process .step__spot-circle {
    fill: #fefefe;
    stroke: #131112;
    vector-effect: non-scaling-stroke;
    stroke-width: 2; }
  .process .step__icon {
    position: relative;
    width: 60px;
    background-position: bottom left;
    background-repeat: no-repeat;
    opacity: 0;
    margin-bottom: 8px; }
  .process .step__svg {
    position: relative;
    bottom: 0;
    width: 48px;
    height: 48px;
    font-size: 40px; }
  .scrolling-section--black .process .step__spot-fill {
    fill: #fefefe; }
  .scrolling-section--black .process .step__spot-circle {
    fill: #131112;
    stroke: #fefefe; }
  .scrolling-section--black .process .step__arc {
    stroke: #fefefe; }
  .scrolling-section--black .process .circle__arc {
    stroke: rgba(254, 254, 254, 0.24); }
  .active-section .process .step__text {
    opacity: 1; }
  .active-section .process .step__spot-svg {
    transition: opacity 0.9s 0.9s cubic-bezier(0.7, 0, 0.3, 1);
    opacity: 1; }
  .active-section .process .circle__arc {
    stroke-dasharray: 700;
    transition: stroke-dasharray 0.9s cubic-bezier(0.215, 0.61, 0.355, 1); }
    .active-section .process .circle__arc:nth-child(1) {
      transition-delay: 0.36s; }
    .active-section .process .circle__arc:nth-child(2) {
      transition-delay: 0.42s; }
    .active-section .process .circle__arc:nth-child(3) {
      transition-delay: 0.48s; }
    .active-section .process .circle__arc:nth-child(4) {
      transition-delay: 0.54s; }
    .active-section .process .circle__arc:nth-child(5) {
      transition-delay: 0.6s; }
    .active-section .process .circle__arc:nth-child(6) {
      transition-delay: 0.66s; }
    .active-section .process .circle__arc:nth-child(7) {
      transition-delay: 0.72s; }
    .active-section .process .circle__arc:nth-child(8) {
      transition-delay: 0.78s; }
  .process .step {
    position: absolute;
    transform: translate(-50%, -50%); }
    .process .step__spot-fill {
      fill: #131112;
      transform: scale(0);
      transform-origin: center;
      transition: transform 0.3s cubic-bezier(0.7, 0, 0.3, 1); }
    .process .step__info {
      position: absolute;
      width: 200px;
      pointer-events: all;
      overflow: hidden; }
    .process .step__text {
      opacity: 0;
      height: auto;
      transition: transform 0.9s cubic-bezier(0.19, 1, 0.22, 1) 0s, opacity 0.9s 1.8s cubic-bezier(0.19, 1, 0.22, 1);
      transform: translateX(-100%); }
      .process .step__text:nth-child(1) {
        transition-delay: 0s; }
      .process .step__text:nth-child(2) {
        transition-delay: 0.06s; }
      .process .step__text:nth-child(3) {
        transition-delay: 0.12s; }
      .process .step__text:nth-child(4) {
        transition-delay: 0.18s; }
      .process .step__text:nth-child(5) {
        transition-delay: 0.24s; }
      .process .step__text:nth-child(6) {
        transition-delay: 0.3s; }
      .process .step__text:nth-child(7) {
        transition-delay: 0.36s; }
      .process .step__text:nth-child(8) {
        transition-delay: 0.42s; }
    .process .step:nth-child(-n + 2) .step__info {
      bottom: 0;
      left: 0; }
    .process .step:nth-child(1) {
      top: 1%;
      left: 50%; }
      .process .step:nth-child(1) .step__info {
        bottom: 0;
        left: 0;
        transform: translate(25%, -50%); }
    .process .step:nth-child(2) {
      top: 15.64466094%;
      left: 85.35533906%; }
      .process .step:nth-child(2) .step__info {
        transform: translate(25%, -25%); }
    .process .step:nth-child(n + 3) .step__info {
      top: 0;
      left: 0; }
    .process .step:nth-child(3) {
      top: 50%;
      left: 99%; }
      .process .step:nth-child(3) .step__info {
        transform: translate(25%, 0); }
    .process .step:nth-child(4) {
      top: 84.35533906%;
      left: 85.35533906%; }
      .process .step:nth-child(4) .step__info {
        transform: translate(25%, 25%); }
    .process .step:nth-child(5) {
      top: 99%;
      left: 50%; }
      .process .step:nth-child(5) .step__info {
        transform: translate(25%, 50%); }
    .process .step:nth-child(6) {
      top: 84.35533906%;
      left: 14.64466094%; }
      .process .step:nth-child(6) .step__info {
        transform: translate(-25%, 25%); }
    .process .step:nth-child(7) {
      top: 50%;
      left: 1%; }
      .process .step:nth-child(7) .step__info {
        transform: translate(-25%, -25%); }
    .process .step:nth-child(8) {
      top: 15.64466094%;
      left: 14.64466094%; }
      .process .step:nth-child(8) .step__info {
        transform: translate(-25%, -50%); }
    .process .step:nth-child(n + 6) .step__text {
      transform: translateX(100%); }
    .process .step:nth-child(n + 6) .step__info {
      top: 0;
      right: 0;
      left: auto;
      text-align: right; }
    .process .step--hovering .step__info > .step__text {
      transform: none; }
    .process .step--hovering .step__spot-fill {
      transform: scale(1); }
    .process .step--active .step__info > .step__text {
      transform: none; }
    .process .step--active .step__spot-fill {
      transform: scale(1); }
  @media screen and (max-width: 800px) {
    .process {
      width: 125%;
      transform: translateX(-10%); }
      .process .title {
        width: 90%; } }

.project-card {
  margin-bottom: 12px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; }
  .project-card a {
    border: none;
    color: #131112;
    display: flex;
    align-items: center; }
  .project-card h1, .project-card h2, .project-card h3, .project-card h4, .project-card h5, .project-card h6 {
    color: transparent; }
  .project-card__tags {
    opacity: 0;
    display: none; }
  .project-card, .project-card__tags, .project-card__asset {
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.6s; }
  .project-card__bottom {
    position: relative;
    opacity: 0.6;
    display: block;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1; }
  .project-card__arrow {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%) rotate(-45deg); }
  .project-card__asset {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    z-index: 0; }
    .project-card__asset img, .project-card__asset video {
      transition-delay: 0.06s; }
  .active-section .project-card__asset img, .active-section .project-card__asset video {
    transform: none; }
  .project-card--hovered .project-card__asset {
    opacity: 0.9; }
  .project-card--hovered .project-card__bottom {
    opacity: 1; }
    .project-card--hovered .project-card__bottom h2, .project-card--hovered .project-card__bottom h3, .project-card--hovered .project-card__bottom h4, .project-card--hovered .project-card__bottom h5, .project-card--hovered .project-card__bottom h6 {
      color: #fefefe; }
  @media screen and (max-width: 800px) {
    .project-card i {
      right: 0; } }

[class*="react-sandbox-editor"].sandbox__header {
  background: #232021; }

[class*="react-sandbox-editor"].sandbox__tab {
  font-family: "Lausanne", sans-serif; }
  [class*="react-sandbox-editor"].sandbox__tab:hover {
    color: #f63f43; }

[class*="react-sandbox-editor"].sandbox__tab--selected {
  color: #f63f43; }

[class*="react-sandbox-editor"].sandbox__indicator {
  background-color: #f63f43;
  height: 3px;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-duration: 0.6s; }

[class*="react-sandbox-editor"] .ace_print-margin {
  display: none; }

@keyframes drawInOut {
  0%, 30% {
    transform-origin: bottom;
    transform: scaleY(1); }
  45% {
    transform-origin: bottom;
    transform: scaleY(0); }
  65% {
    transform: scaleY(0);
    transform-origin: top; }
  80%, 100% {
    transform: scaleY(1);
    transform-origin: top; } }

.scroll-arrow {
  pointer-events: auto;
  position: fixed;
  bottom: 0;
  left: 50%;
  height: 90px;
  width: 80px;
  transform: translateX(-40px);
  display: none;
  align-items: center;
  flex-direction: column-reverse;
  white-space: pre;
  z-index: 1; }
  .active-section .scroll-arrow {
    display: flex; }
  .scroll-arrow h1 span, .scroll-arrow h2 span, .scroll-arrow h3 span, .scroll-arrow h4 span, .scroll-arrow h5 span, .scroll-arrow h6 span {
    display: inline-block; }
  .scroll-arrow h1 > span, .scroll-arrow h2 > span, .scroll-arrow h3 > span, .scroll-arrow h4 > span, .scroll-arrow h5 > span, .scroll-arrow h6 > span {
    overflow: hidden; }
    .scroll-arrow h1 > span:first-child, .scroll-arrow h2 > span:first-child, .scroll-arrow h3 > span:first-child, .scroll-arrow h4 > span:first-child, .scroll-arrow h5 > span:first-child, .scroll-arrow h6 > span:first-child {
      margin-right: 6px; }
    .scroll-arrow h1 > span > span, .scroll-arrow h2 > span > span, .scroll-arrow h3 > span > span, .scroll-arrow h4 > span > span, .scroll-arrow h5 > span > span, .scroll-arrow h6 > span > span {
      transform: none;
      transition: transform 0.9s cubic-bezier(0.19, 1, 0.22, 1); }
      .scroll-arrow h1 > span > span:nth-child(1), .scroll-arrow h2 > span > span:nth-child(1), .scroll-arrow h3 > span > span:nth-child(1), .scroll-arrow h4 > span > span:nth-child(1), .scroll-arrow h5 > span > span:nth-child(1), .scroll-arrow h6 > span > span:nth-child(1) {
        transition-delay: 0.69s; }
      .scroll-arrow h1 > span > span:nth-child(2), .scroll-arrow h2 > span > span:nth-child(2), .scroll-arrow h3 > span > span:nth-child(2), .scroll-arrow h4 > span > span:nth-child(2), .scroll-arrow h5 > span > span:nth-child(2), .scroll-arrow h6 > span > span:nth-child(2) {
        transition-delay: 0.78s; }
      .scroll-arrow h1 > span > span:nth-child(3), .scroll-arrow h2 > span > span:nth-child(3), .scroll-arrow h3 > span > span:nth-child(3), .scroll-arrow h4 > span > span:nth-child(3), .scroll-arrow h5 > span > span:nth-child(3), .scroll-arrow h6 > span > span:nth-child(3) {
        transition-delay: 0.87s; }
      .scroll-arrow h1 > span > span:nth-child(4), .scroll-arrow h2 > span > span:nth-child(4), .scroll-arrow h3 > span > span:nth-child(4), .scroll-arrow h4 > span > span:nth-child(4), .scroll-arrow h5 > span > span:nth-child(4), .scroll-arrow h6 > span > span:nth-child(4) {
        transition-delay: 0.96s; }
      .scroll-arrow h1 > span > span:nth-child(5), .scroll-arrow h2 > span > span:nth-child(5), .scroll-arrow h3 > span > span:nth-child(5), .scroll-arrow h4 > span > span:nth-child(5), .scroll-arrow h5 > span > span:nth-child(5), .scroll-arrow h6 > span > span:nth-child(5) {
        transition-delay: 1.05s; }
      .scroll-arrow h1 > span > span:nth-child(6), .scroll-arrow h2 > span > span:nth-child(6), .scroll-arrow h3 > span > span:nth-child(6), .scroll-arrow h4 > span > span:nth-child(6), .scroll-arrow h5 > span > span:nth-child(6), .scroll-arrow h6 > span > span:nth-child(6) {
        transition-delay: 1.14s; }
      .scroll-arrow h1 > span > span:nth-child(7), .scroll-arrow h2 > span > span:nth-child(7), .scroll-arrow h3 > span > span:nth-child(7), .scroll-arrow h4 > span > span:nth-child(7), .scroll-arrow h5 > span > span:nth-child(7), .scroll-arrow h6 > span > span:nth-child(7) {
        transition-delay: 1.23s; }
      .scroll-arrow h1 > span > span:nth-child(8), .scroll-arrow h2 > span > span:nth-child(8), .scroll-arrow h3 > span > span:nth-child(8), .scroll-arrow h4 > span > span:nth-child(8), .scroll-arrow h5 > span > span:nth-child(8), .scroll-arrow h6 > span > span:nth-child(8) {
        transition-delay: 1.32s; }
      .scroll-arrow h1 > span > span:nth-child(9), .scroll-arrow h2 > span > span:nth-child(9), .scroll-arrow h3 > span > span:nth-child(9), .scroll-arrow h4 > span > span:nth-child(9), .scroll-arrow h5 > span > span:nth-child(9), .scroll-arrow h6 > span > span:nth-child(9) {
        transition-delay: 1.41s; }
      .scroll-arrow h1 > span > span:nth-child(10), .scroll-arrow h2 > span > span:nth-child(10), .scroll-arrow h3 > span > span:nth-child(10), .scroll-arrow h4 > span > span:nth-child(10), .scroll-arrow h5 > span > span:nth-child(10), .scroll-arrow h6 > span > span:nth-child(10) {
        transition-delay: 1.5s; }
  .scroll-arrow__seperator-container {
    background: rgba(19, 17, 18, 0);
    height: 60px;
    width: 1px;
    transform-origin: bottom;
    transform: scaleY(1);
    transition: transform cubic-bezier(0.19, 1, 0.22, 1) 0.9s 0.3s; }
  .scroll-arrow__seperator {
    background: #131112;
    height: 100%;
    width: 100%;
    animation: drawInOut cubic-bezier(0.7, 0, 0.3, 1) 3s infinite;
    animation-delay: 1.2s; }
  .scrolling-section--black .scroll-arrow .scroll-arrow__seperator-container {
    background: rgba(254, 254, 254, 0.24); }
  .scrolling-section--black .scroll-arrow .scroll-arrow__seperator {
    background: #fefefe; }
  .scroll-arrow--hidden {
    pointer-events: none; }
    .scroll-arrow--hidden h1 span > span, .scroll-arrow--hidden h2 span > span, .scroll-arrow--hidden h3 span > span, .scroll-arrow--hidden h4 span > span, .scroll-arrow--hidden h5 span > span, .scroll-arrow--hidden h6 span > span {
      transition: transform 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translateY(24px); }
      .scroll-arrow--hidden h1 span > span:nth-child(1), .scroll-arrow--hidden h2 span > span:nth-child(1), .scroll-arrow--hidden h3 span > span:nth-child(1), .scroll-arrow--hidden h4 span > span:nth-child(1), .scroll-arrow--hidden h5 span > span:nth-child(1), .scroll-arrow--hidden h6 span > span:nth-child(1) {
        transition-delay: 0.09s; }
      .scroll-arrow--hidden h1 span > span:nth-child(2), .scroll-arrow--hidden h2 span > span:nth-child(2), .scroll-arrow--hidden h3 span > span:nth-child(2), .scroll-arrow--hidden h4 span > span:nth-child(2), .scroll-arrow--hidden h5 span > span:nth-child(2), .scroll-arrow--hidden h6 span > span:nth-child(2) {
        transition-delay: 0.18s; }
      .scroll-arrow--hidden h1 span > span:nth-child(3), .scroll-arrow--hidden h2 span > span:nth-child(3), .scroll-arrow--hidden h3 span > span:nth-child(3), .scroll-arrow--hidden h4 span > span:nth-child(3), .scroll-arrow--hidden h5 span > span:nth-child(3), .scroll-arrow--hidden h6 span > span:nth-child(3) {
        transition-delay: 0.27s; }
      .scroll-arrow--hidden h1 span > span:nth-child(4), .scroll-arrow--hidden h2 span > span:nth-child(4), .scroll-arrow--hidden h3 span > span:nth-child(4), .scroll-arrow--hidden h4 span > span:nth-child(4), .scroll-arrow--hidden h5 span > span:nth-child(4), .scroll-arrow--hidden h6 span > span:nth-child(4) {
        transition-delay: 0.36s; }
      .scroll-arrow--hidden h1 span > span:nth-child(5), .scroll-arrow--hidden h2 span > span:nth-child(5), .scroll-arrow--hidden h3 span > span:nth-child(5), .scroll-arrow--hidden h4 span > span:nth-child(5), .scroll-arrow--hidden h5 span > span:nth-child(5), .scroll-arrow--hidden h6 span > span:nth-child(5) {
        transition-delay: 0.45s; }
      .scroll-arrow--hidden h1 span > span:nth-child(6), .scroll-arrow--hidden h2 span > span:nth-child(6), .scroll-arrow--hidden h3 span > span:nth-child(6), .scroll-arrow--hidden h4 span > span:nth-child(6), .scroll-arrow--hidden h5 span > span:nth-child(6), .scroll-arrow--hidden h6 span > span:nth-child(6) {
        transition-delay: 0.54s; }
      .scroll-arrow--hidden h1 span > span:nth-child(7), .scroll-arrow--hidden h2 span > span:nth-child(7), .scroll-arrow--hidden h3 span > span:nth-child(7), .scroll-arrow--hidden h4 span > span:nth-child(7), .scroll-arrow--hidden h5 span > span:nth-child(7), .scroll-arrow--hidden h6 span > span:nth-child(7) {
        transition-delay: 0.63s; }
      .scroll-arrow--hidden h1 span > span:nth-child(8), .scroll-arrow--hidden h2 span > span:nth-child(8), .scroll-arrow--hidden h3 span > span:nth-child(8), .scroll-arrow--hidden h4 span > span:nth-child(8), .scroll-arrow--hidden h5 span > span:nth-child(8), .scroll-arrow--hidden h6 span > span:nth-child(8) {
        transition-delay: 0.72s; }
      .scroll-arrow--hidden h1 span > span:nth-child(9), .scroll-arrow--hidden h2 span > span:nth-child(9), .scroll-arrow--hidden h3 span > span:nth-child(9), .scroll-arrow--hidden h4 span > span:nth-child(9), .scroll-arrow--hidden h5 span > span:nth-child(9), .scroll-arrow--hidden h6 span > span:nth-child(9) {
        transition-delay: 0.81s; }
      .scroll-arrow--hidden h1 span > span:nth-child(10), .scroll-arrow--hidden h2 span > span:nth-child(10), .scroll-arrow--hidden h3 span > span:nth-child(10), .scroll-arrow--hidden h4 span > span:nth-child(10), .scroll-arrow--hidden h5 span > span:nth-child(10), .scroll-arrow--hidden h6 span > span:nth-child(10) {
        transition-delay: 0.9s; }
    .scroll-arrow--hidden .scroll-arrow__seperator-container {
      transform: scaleY(0);
      transition: transform cubic-bezier(0.86, 0, 0.07, 1) 0.9s; }
    .scroll-arrow--hidden .scroll-arrow__seperator {
      animation: none; }
  @media screen and (max-width: 800px) {
    .scroll-arrow {
      position: fixed; }
      .scroll-arrow h1, .scroll-arrow h2, .scroll-arrow h3, .scroll-arrow h4, .scroll-arrow h5, .scroll-arrow h6 {
        margin-bottom: 3px; }
      .scroll-arrow .scroll-arrow__seperator-container {
        height: 48px; } }

.side-scroller {
  width: 100%; }
  .side-scroller__items {
    overflow-x: scroll;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0 auto;
    justify-content: flex-start;
    -webkit-mask-image: linear-gradient(to right, transparent, black 5%, black 95%, transparent); }
  .side-scroller__controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px; }

.sidebar {
  width: auto;
  position: fixed;
  pointer-events: none;
  display: none;
  align-items: center;
  justify-content: flex-end;
  top: 0px;
  background-blend-mode: multiply;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 999; }
  .sidebar--length50 {
    transform: translateX(92px); }
  .sidebar--length51 {
    transform: translateX(93px); }
  .sidebar--length52 {
    transform: translateX(94px); }
  .sidebar--length53 {
    transform: translateX(95px); }
  .sidebar--length54 {
    transform: translateX(96px); }
  .sidebar--length55 {
    transform: translateX(97px); }
  .sidebar--length56 {
    transform: translateX(98px); }
  .sidebar--length57 {
    transform: translateX(99px); }
  .sidebar--length58 {
    transform: translateX(100px); }
  .sidebar--length59 {
    transform: translateX(101px); }
  .sidebar--length60 {
    transform: translateX(102px); }
  .sidebar--length61 {
    transform: translateX(103px); }
  .sidebar--length62 {
    transform: translateX(104px); }
  .sidebar--length63 {
    transform: translateX(105px); }
  .sidebar--length64 {
    transform: translateX(106px); }
  .sidebar--length65 {
    transform: translateX(107px); }
  .sidebar--length66 {
    transform: translateX(108px); }
  .sidebar--length67 {
    transform: translateX(109px); }
  .sidebar--length68 {
    transform: translateX(110px); }
  .sidebar--length69 {
    transform: translateX(111px); }
  .sidebar--length70 {
    transform: translateX(112px); }
  .sidebar--length71 {
    transform: translateX(113px); }
  .sidebar--length72 {
    transform: translateX(114px); }
  .sidebar--length73 {
    transform: translateX(115px); }
  .sidebar--length74 {
    transform: translateX(116px); }
  .sidebar--length75 {
    transform: translateX(117px); }
  .sidebar--length76 {
    transform: translateX(118px); }
  .sidebar--length77 {
    transform: translateX(119px); }
  .sidebar--length78 {
    transform: translateX(120px); }
  .sidebar--length79 {
    transform: translateX(121px); }
  .sidebar--length80 {
    transform: translateX(122px); }
  .sidebar--length81 {
    transform: translateX(123px); }
  .sidebar--length82 {
    transform: translateX(124px); }
  .sidebar--length83 {
    transform: translateX(125px); }
  .sidebar--length84 {
    transform: translateX(126px); }
  .sidebar--length85 {
    transform: translateX(127px); }
  .sidebar--length86 {
    transform: translateX(128px); }
  .sidebar--length87 {
    transform: translateX(129px); }
  .sidebar--length88 {
    transform: translateX(130px); }
  .sidebar--length89 {
    transform: translateX(131px); }
  .sidebar--length90 {
    transform: translateX(132px); }
  .sidebar--length91 {
    transform: translateX(133px); }
  .sidebar--length92 {
    transform: translateX(134px); }
  .sidebar--length93 {
    transform: translateX(135px); }
  .sidebar--length94 {
    transform: translateX(136px); }
  .sidebar--length95 {
    transform: translateX(137px); }
  .sidebar--length96 {
    transform: translateX(138px); }
  .sidebar--length97 {
    transform: translateX(139px); }
  .sidebar--length98 {
    transform: translateX(140px); }
  .sidebar--length99 {
    transform: translateX(141px); }
  .sidebar--length100 {
    transform: translateX(142px); }
  .sidebar--length101 {
    transform: translateX(143px); }
  .sidebar--length102 {
    transform: translateX(144px); }
  .sidebar--length103 {
    transform: translateX(145px); }
  .sidebar--length104 {
    transform: translateX(146px); }
  .sidebar--length105 {
    transform: translateX(147px); }
  .sidebar--length106 {
    transform: translateX(148px); }
  .sidebar--length107 {
    transform: translateX(149px); }
  .sidebar--length108 {
    transform: translateX(150px); }
  .sidebar--length109 {
    transform: translateX(151px); }
  .sidebar--length110 {
    transform: translateX(152px); }
  .sidebar--length111 {
    transform: translateX(153px); }
  .sidebar--length112 {
    transform: translateX(154px); }
  .sidebar--length113 {
    transform: translateX(155px); }
  .sidebar--length114 {
    transform: translateX(156px); }
  .sidebar--length115 {
    transform: translateX(157px); }
  .sidebar--length116 {
    transform: translateX(158px); }
  .sidebar--length117 {
    transform: translateX(159px); }
  .sidebar--length118 {
    transform: translateX(160px); }
  .sidebar--length119 {
    transform: translateX(161px); }
  .sidebar--length120 {
    transform: translateX(162px); }
  .sidebar--length121 {
    transform: translateX(163px); }
  .sidebar--length122 {
    transform: translateX(164px); }
  .sidebar--length123 {
    transform: translateX(165px); }
  .sidebar--length124 {
    transform: translateX(166px); }
  .sidebar--length125 {
    transform: translateX(167px); }
  .sidebar--length126 {
    transform: translateX(168px); }
  .sidebar--length127 {
    transform: translateX(169px); }
  .sidebar--length128 {
    transform: translateX(170px); }
  .sidebar--length129 {
    transform: translateX(171px); }
  .sidebar--length130 {
    transform: translateX(172px); }
  .sidebar--length131 {
    transform: translateX(173px); }
  .sidebar--length132 {
    transform: translateX(174px); }
  .sidebar--length133 {
    transform: translateX(175px); }
  .sidebar--length134 {
    transform: translateX(176px); }
  .sidebar--length135 {
    transform: translateX(177px); }
  .sidebar--length136 {
    transform: translateX(178px); }
  .sidebar--length137 {
    transform: translateX(179px); }
  .sidebar--length138 {
    transform: translateX(180px); }
  .sidebar--length139 {
    transform: translateX(181px); }
  .sidebar--length140 {
    transform: translateX(182px); }
  .sidebar--length141 {
    transform: translateX(183px); }
  .sidebar--length142 {
    transform: translateX(184px); }
  .sidebar--length143 {
    transform: translateX(185px); }
  .sidebar--length144 {
    transform: translateX(186px); }
  .sidebar--length145 {
    transform: translateX(187px); }
  .sidebar--length146 {
    transform: translateX(188px); }
  .sidebar--length147 {
    transform: translateX(189px); }
  .sidebar--length148 {
    transform: translateX(190px); }
  .sidebar--length149 {
    transform: translateX(191px); }
  .sidebar--length150 {
    transform: translateX(192px); }
  .sidebar--length151 {
    transform: translateX(193px); }
  .sidebar--length152 {
    transform: translateX(194px); }
  .sidebar--length153 {
    transform: translateX(195px); }
  .sidebar--length154 {
    transform: translateX(196px); }
  .sidebar--length155 {
    transform: translateX(197px); }
  .sidebar--length156 {
    transform: translateX(198px); }
  .sidebar--length157 {
    transform: translateX(199px); }
  .sidebar--length158 {
    transform: translateX(200px); }
  .sidebar--length159 {
    transform: translateX(201px); }
  .sidebar--length160 {
    transform: translateX(202px); }
  .sidebar--length161 {
    transform: translateX(203px); }
  .sidebar--length162 {
    transform: translateX(204px); }
  .sidebar--length163 {
    transform: translateX(205px); }
  .sidebar--length164 {
    transform: translateX(206px); }
  .sidebar--length165 {
    transform: translateX(207px); }
  .sidebar--length166 {
    transform: translateX(208px); }
  .sidebar--length167 {
    transform: translateX(209px); }
  .sidebar--length168 {
    transform: translateX(210px); }
  .sidebar--length169 {
    transform: translateX(211px); }
  .sidebar--length170 {
    transform: translateX(212px); }
  .sidebar--length171 {
    transform: translateX(213px); }
  .sidebar--length172 {
    transform: translateX(214px); }
  .sidebar--length173 {
    transform: translateX(215px); }
  .sidebar--length174 {
    transform: translateX(216px); }
  .sidebar--length175 {
    transform: translateX(217px); }
  .sidebar--length176 {
    transform: translateX(218px); }
  .sidebar--length177 {
    transform: translateX(219px); }
  .sidebar--length178 {
    transform: translateX(220px); }
  .sidebar--length179 {
    transform: translateX(221px); }
  .sidebar--length180 {
    transform: translateX(222px); }
  .sidebar--length181 {
    transform: translateX(223px); }
  .sidebar--length182 {
    transform: translateX(224px); }
  .sidebar--length183 {
    transform: translateX(225px); }
  .sidebar--length184 {
    transform: translateX(226px); }
  .sidebar--length185 {
    transform: translateX(227px); }
  .sidebar--length186 {
    transform: translateX(228px); }
  .sidebar--length187 {
    transform: translateX(229px); }
  .sidebar--length188 {
    transform: translateX(230px); }
  .sidebar--length189 {
    transform: translateX(231px); }
  .sidebar--length190 {
    transform: translateX(232px); }
  .sidebar--length191 {
    transform: translateX(233px); }
  .sidebar--length192 {
    transform: translateX(234px); }
  .sidebar--length193 {
    transform: translateX(235px); }
  .sidebar--length194 {
    transform: translateX(236px); }
  .sidebar--length195 {
    transform: translateX(237px); }
  .sidebar--length196 {
    transform: translateX(238px); }
  .sidebar--length197 {
    transform: translateX(239px); }
  .sidebar--length198 {
    transform: translateX(240px); }
  .sidebar--length199 {
    transform: translateX(241px); }
  .sidebar--length200 {
    transform: translateX(242px); }
  .sidebar--left {
    left: 0;
    transform: translateX(-48px); }
    .sidebar--left .sidebar__dash {
      transform-origin: left; }
    .sidebar--left .sidebar__border {
      -webkit-mask-image: linear-gradient(to right, black 60%, transparent); }
  .sidebar--right {
    right: 0; }
    .sidebar--right .sidebar__dash {
      transform-origin: right; }
    .sidebar--right .sidebar__border {
      -webkit-mask-image: linear-gradient(to left, black 60%, transparent); }
  .active-section .sidebar {
    display: flex;
    pointer-events: auto; }
    .active-section .sidebar__inner {
      pointer-events: auto; }
  .sidebar__inner {
    pointer-events: none;
    list-style: none;
    margin-top: 60px;
    margin-left: 0;
    width: 100%;
    padding: 0;
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s; }
  .sidebar__item {
    width: 100%;
    height: 42px;
    border-bottom: none;
    overflow: hidden;
    display: block;
    position: relative;
    transition: height cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s 0.3s; }
    .sidebar__item a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      border: none;
      pointer-events: none; }
      .sidebar__item a.active .sidebar__dash {
        transform: scaleX(1); }
  .sidebar__label, .sidebar__number {
    color: #131112;
    opacity: 0;
    display: block; }
  .sidebar__number, .sidebar__indicator, .sidebar__dash {
    margin-right: 12px; }
  .sidebar__number {
    color: #131112;
    width: 24px; }
  .sidebar__label {
    color: #131112;
    white-space: nowrap; }
  .sidebar__indicator {
    opacity: 0;
    -webkit-text-stroke-width: 3px;
    font-family: "Lausanne", sans-serif; }
  .sidebar__dash {
    width: 48px;
    height: 1px;
    background: #131112;
    display: inline-block;
    transform: scaleX(0.5);
    transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s; }
  .sidebar__border {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: rgba(19, 17, 18, 0.06);
    opacity: 0; }
  .sidebar__border--top {
    bottom: auto;
    top: 0;
    display: none; }
  .sidebar--open {
    transform: none;
    transition: transform 0.3s 0.3s cubic-bezier(0.86, 0, 0.07, 1); }
    .sidebar--open .sidebar__inner .sidebar__item {
      height: 60px;
      transition: height cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s; }
      .sidebar--open .sidebar__inner .sidebar__item a {
        pointer-events: auto; }
        .sidebar--open .sidebar__inner .sidebar__item a.active .sidebar__indicator {
          opacity: 1;
          color: #f63f43; }
      .sidebar--open .sidebar__inner .sidebar__item .sidebar__number,
      .sidebar--open .sidebar__inner .sidebar__item .sidebar__label,
      .sidebar--open .sidebar__inner .sidebar__item .sidebar__border {
        opacity: 1;
        transition: opacity 0.3s 0.3s cubic-bezier(0.86, 0, 0.07, 1); }
      .sidebar--open .sidebar__inner .sidebar__item .sidebar__number {
        transform: translate3d(0, 0, 0); }
      .sidebar--open .sidebar__inner .sidebar__item .sidebar__dash {
        transform: scaleX(1); }
  .scrolling-section--black .sidebar .sidebar__dash {
    background: #fefefe; }
  .scrolling-section--black .sidebar--open .sidebar__inner .sidebar__border {
    background: rgba(254, 254, 254, 0.06); }
  @media screen and (max-width: 800px) {
    .sidebar {
      right: 0;
      left: auto;
      transform: translateX(176px); }
      .active-section .sidebar {
        pointer-events: none;
        display: none; }
      .sidebar .sidebar__inner {
        margin-top: 84px;
        pointer-events: none; }
      .sidebar .sidebar__item {
        height: 30px; }
      .sidebar .sidebar__dash {
        width: 32px;
        transform-origin: right; }
      .sidebar .sidebar__label {
        margin-right: 0px; }
      .sidebar.sidebar--open {
        transform: none; }
        .sidebar.sidebar--open .sidebar__inner {
          background: #fefefe; }
        .sidebar.sidebar--open .sidebar__item {
          height: 48px;
          padding-right: 24px; }
      .scrolling-section--black .sidebar--open .sidebar__inner {
        background: #131112; } }

.text-link {
  position: relative;
  display: inline-flex;
  flex-direction: column; }
  .text-link__line-container {
    height: 1px;
    width: 100%;
    display: block;
    overflow: hidden;
    background: rgba(19, 17, 18, 0.1);
    position: absolute;
    bottom: 0;
    left: 0; }
  .text-link__line {
    background: #131112;
    height: 100%;
    width: 100%;
    display: block;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform cubic-bezier(0.19, 1, 0.22, 1) 0.9s; }
  .scrolling-section--black .text-link .text-link__line-container {
    background: rgba(254, 254, 254, 0.24); }
  .scrolling-section--black .text-link .text-link__line {
    background: #fefefe; }
  .text-link.text-link--hide-underline .text-link__line-container {
    background: transparent; }
  .text-link.text-link--hide-line .text-link__line-container {
    height: 0; }
  .text-link--hovered .text-link__line {
    transform: scaleX(1);
    transform-origin: left; }
  .text-link--disabled {
    pointer-events: none; }

.video-wrapper {
  position: relative; }
  .video-wrapper__controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
    opacity: 0;
    pointer-events: none; }
  .video-wrapper__controls-item {
    color: #fefefe;
    display: flex;
    align-items: center;
    background: rgba(19, 17, 18, 0.8);
    opacity: 0.9;
    border-radius: 16px;
    padding: 0 16px;
    margin-right: 4px; }
  .video-wrapper .icon {
    stroke: #fefefe;
    margin-right: 4px; }
  .video-wrapper--hovering .video-wrapper__controls {
    opacity: 1; }

/* Responsive */
@media screen and (max-width: 1336px) {
  .h1 {
    font-size: 16rem; }
  .h2 {
    font-size: 14rem; }
  h3 {
    font-size: 12rem; }
  section {
    padding: 104px 0; }
  .grid {
    padding: 0 72px; }
  .grid__item--hide-bp-xxlarge {
    display: none; }
  .grid__item--col-1-xxlarge {
    min-width: 8.33%;
    width: 8.33%; }
  .grid__item--col-2-xxlarge {
    min-width: 16.66%;
    width: 16.66%; }
  .grid__item--col-3-xxlarge {
    min-width: 25%;
    width: 25%; }
  .grid__item--col-4-xxlarge {
    min-width: 33.33%;
    width: 33.33%; }
  .grid__item--col-5-xxlarge {
    min-width: 41.66%;
    width: 41.66%; }
  .grid__item--col-6-xxlarge {
    min-width: 50%;
    width: 50%; }
  .grid__item--col-7-xxlarge {
    min-width: 58.33%;
    width: 58.33%; }
  .grid__item--col-8-xxlarge {
    min-width: 66.66%;
    width: 66.66%; }
  .grid__item--col-9-xxlarge {
    min-width: 75%;
    width: 75%; }
  .grid__item--col-10-xxlarge {
    min-width: 83.33%;
    width: 83.33%; }
  .grid__item--col-11-xxlarge {
    min-width: 91.66%;
    width: 91.66%; }
  .grid__item--col-12-xxlarge {
    min-width: 100%;
    width: 100%; } }

@media screen and (max-width: 1280px) {
  .h1 {
    font-size: 15rem; }
  .h2 {
    font-size: 13rem; }
  h3 {
    font-size: 11rem; }
  section {
    padding: 108px 0; }
  .grid {
    padding: 0 72px; }
  .grid__item--hide-bp-xlarge {
    display: none; }
  .grid__item--col-1-xlarge {
    min-width: 8.33%;
    width: 8.33%; }
  .grid__item--col-2-xlarge {
    min-width: 16.66%;
    width: 16.66%; }
  .grid__item--col-3-xlarge {
    min-width: 25%;
    width: 25%; }
  .grid__item--col-4-xlarge {
    min-width: 33.33%;
    width: 33.33%; }
  .grid__item--col-5-xlarge {
    min-width: 41.66%;
    width: 41.66%; }
  .grid__item--col-6-xlarge {
    min-width: 50%;
    width: 50%; }
  .grid__item--col-7-xlarge {
    min-width: 58.33%;
    width: 58.33%; }
  .grid__item--col-8-xlarge {
    min-width: 66.66%;
    width: 66.66%; }
  .grid__item--col-9-xlarge {
    min-width: 75%;
    width: 75%; }
  .grid__item--col-10-xlarge {
    min-width: 83.33%;
    width: 83.33%; }
  .grid__item--col-11-xlarge {
    min-width: 91.66%;
    width: 91.66%; }
  .grid__item--col-12-xlarge {
    min-width: 100%;
    width: 100%; } }

@media screen and (max-width: 1024px) {
  .h1 {
    font-size: 14rem; }
  .h2 {
    font-size: 12rem; }
  blockquote {
    font-size: 2.625rem; }
  section {
    padding: 96px 0; }
  .grid {
    padding: 0 60px; }
  .grid__item--hide-bp-large {
    display: none; }
  .grid__item--col-1-large {
    min-width: 8.33%;
    width: 8.33%; }
  .grid__item--col-2-large {
    min-width: 16.66%;
    width: 16.66%; }
  .grid__item--col-3-large {
    min-width: 25%;
    width: 25%; }
  .grid__item--col-4-large {
    min-width: 33.33%;
    width: 33.33%; }
  .grid__item--col-5-large {
    min-width: 41.66%;
    width: 41.66%; }
  .grid__item--col-6-large {
    min-width: 50%;
    width: 50%; }
  .grid__item--col-7-large {
    min-width: 58.33%;
    width: 58.33%; }
  .grid__item--col-8-large {
    min-width: 66.66%;
    width: 66.66%; }
  .grid__item--col-9-large {
    min-width: 75%;
    width: 75%; }
  .grid__item--col-10-large {
    min-width: 83.33%;
    width: 83.33%; }
  .grid__item--col-11-large {
    min-width: 91.66%;
    width: 91.66%; }
  .grid__item--col-12-large {
    min-width: 100%;
    width: 100%; } }

@media screen and (max-width: 800px) {
  body {
    cursor: auto !important; }
  .cursor {
    display: none; }
  .active-section .logo {
    display: none; }
  .grid {
    padding: 0 48px; }
  .clip-wrapper__left {
    width: 48px; }
  .grid__line:first-child {
    border-left: solid 1px; }
  .grid__line:last-child {
    border-right: solid 1px; }
  .grid__row {
    margin-bottom: 24px; }
  .scrolling-section__number {
    transform: none;
    height: auto; }
  section {
    padding: 60px 0; }
    section .spacer__sm {
      min-height: 12px; }
    section .spacer__md {
      min-height: 24px; }
    section .spacer__lg {
      min-height: 48px; }
    section .shift--left {
      transform: none; }
    section .mr {
      margin-right: 0; }
  .h1 {
    font-size: 9rem;
    margin-left: 0;
    margin-bottom: 24px;
    letter-spacing: -0.15rem; }
  .h2 {
    font-size: 7.5rem;
    margin-left: 0;
    margin-bottom: 18px; }
  h3 {
    font-size: 5rem;
    margin-bottom: 12px; }
  h4 {
    font-size: 4rem;
    margin-bottom: 9px; }
  .grid__item--hide-bp-medium {
    display: none; }
  .grid__item--col-1-medium {
    min-width: 8.33%;
    width: 8.33%; }
  .grid__item--col-2-medium {
    min-width: 16.66%;
    width: 16.66%; }
  .grid__item--col-3-medium {
    min-width: 25%;
    width: 25%; }
  .grid__item--col-4-medium {
    min-width: 33.33%;
    width: 33.33%; }
  .grid__item--col-5-medium {
    min-width: 41.66%;
    width: 41.66%; }
  .grid__item--col-6-medium {
    min-width: 50%;
    width: 50%; }
  .grid__item--col-7-medium {
    min-width: 58.33%;
    width: 58.33%; }
  .grid__item--col-8-medium {
    min-width: 66.66%;
    width: 66.66%; }
  .grid__item--col-9-medium {
    min-width: 75%;
    width: 75%; }
  .grid__item--col-10-medium {
    min-width: 83.33%;
    width: 83.33%; }
  .grid__item--col-11-medium {
    min-width: 91.66%;
    width: 91.66%; }
  .grid__item--col-12-medium {
    min-width: 100%;
    width: 100%; } }

@media screen and (max-width: 480px) {
  .h1 {
    font-size: 7rem; }
  .h2 {
    font-size: 6rem; }
  h3 {
    font-size: 5rem; }
  h4 {
    font-size: 3.75rem; }
  h5 {
    font-size: 2.125rem; }
  blockquote {
    font-size: 2.375rem;
    margin-bottom: 26px; }
  p {
    font-size: 1.875rem; } }

@media screen and (max-width: 320px) {
  .h1 {
    font-size: 6rem; }
  .h2 {
    font-size: 5.5rem; }
  h3 {
    font-size: 5rem; }
  h4 {
    font-size: 3.5rem; } }

/* Override Classes */
*.mb0 {
  margin-bottom: 0; }

*.m0 {
  margin: 0; }

*.p0 {
  padding: 0; }

*.lh1 {
  line-height: 1; }

/*
create color utility classes in the format

.inherit--brand-[COLOR]
.inherit-bg--brand-[COLOR]
.inherit-outline--brand-[COLOR]
*/
.inherit--brand-red {
  color: #f63f43; }
  .inherit--brand-red .outline {
    -webkit-text-stroke-color: #f63f43; }

.inherit-bg--brand-red {
  background-color: #f63f43; }

.inherit--brand-orange {
  color: #f67e0d; }
  .inherit--brand-orange .outline {
    -webkit-text-stroke-color: #f67e0d; }

.inherit-bg--brand-orange {
  background-color: #f67e0d; }

.inherit--brand-yellow {
  color: #efbb29; }
  .inherit--brand-yellow .outline {
    -webkit-text-stroke-color: #efbb29; }

.inherit-bg--brand-yellow {
  background-color: #efbb29; }

.inherit--brand-green {
  color: #12ab2e; }
  .inherit--brand-green .outline {
    -webkit-text-stroke-color: #12ab2e; }

.inherit-bg--brand-green {
  background-color: #12ab2e; }

.inherit--brand-purple {
  color: #7366FB; }
  .inherit--brand-purple .outline {
    -webkit-text-stroke-color: #7366FB; }

.inherit-bg--brand-purple {
  background-color: #7366FB; }

.inherit--brand-pink {
  color: #ef9b97; }
  .inherit--brand-pink .outline {
    -webkit-text-stroke-color: #ef9b97; }

.inherit-bg--brand-pink {
  background-color: #ef9b97; }

.inherit--brand-blue {
  color: #0b67de; }
  .inherit--brand-blue .outline {
    -webkit-text-stroke-color: #0b67de; }

.inherit-bg--brand-blue {
  background-color: #0b67de; }

.inherit--brand-teal {
  color: #00D9B6; }
  .inherit--brand-teal .outline {
    -webkit-text-stroke-color: #00D9B6; }

.inherit-bg--brand-teal {
  background-color: #00D9B6; }

/* Create CSS Variables */
:root {
  --brand-red: #f63f43;
  --brand-orange: #f67e0d;
  --brand-yellow: #efbb29;
  --brand-green: #12ab2e;
  --brand-purple: #7366FB;
  --brand-pink: #ef9b97;
  --brand-blue: #0b67de;
  --brand-teal: #00D9B6; }
