* {
	&.mb0 {
		margin-bottom: 0;
	}

	&.m0 {
		margin: 0;
	}

	&.p0 {
		padding: 0;
	}

	&.lh1 {
		line-height: 1;
	}
}
