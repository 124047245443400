/* SASS partial for grunt-webfont, autogenerated, do not edit! */
@font-face {
  font-family: "icons";
  src: url('../fonts/icons.eot');
  src: url('../fonts/icons.eot?#iefix') format('eot'),
    url('../fonts/icons.woff') format('woff'),
    url('../fonts/icons.ttf') format('truetype'),
    url('../fonts/icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin iconcss {
	font-family:"icons";
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:optimizeLegibility;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}

.iconcss {
	@include iconcss();
}

.icon-arrow-right:before { content: "\EA01" }
.icon-caret-right:before { content: "\EA02" }
.icon-download:before { content: "\EA03" }
.icon-arrow-right-thin:before { content: "\EA04" }
.icon-discover:before { content: "\EA05" }
.icon-ideate:before { content: "\EA06" }
.icon-wireframe:before { content: "\EA07" }
.icon-prototype:before { content: "\EA08" }
.icon-user-testing:before { content: "\EA09" }
.icon-experience-mapping:before { content: "\EA10" }
.icon-refining:before { content: "\EA11" }
.icon-backlog:before { content: "\EA12" }


@mixin icon($icon, $font-size:false, $extend-base:true) {
	@extend .icon-#{$icon}:before;
	@if($font-size) {
		font-size: $font-size;
  		font-family: "icons";
	}
	@if($extend-base) {
		@extend .iconcss; 
	}
}