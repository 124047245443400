// @font-face {
// 	font-family: 'Circular Std';
// 	font-weight: 800;
// 	font-style: normal;
// 	src: url('../fonts/CircularStd/CircularStd-Black.eot');
// 	src: url('../fonts/CircularStd/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/CircularStd/CircularStd-Black.woff2') format('woff2'), 
// 	url('../fonts/CircularStd/CircularStd-Black.woff') format('woff'), 
// 	url('../fonts/CircularStd/CircularStd-Black.ttf') format('truetype'),
// 	url('../fonts/CircularStd/CircularStd-Black.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Circular Std';
// 	font-weight: 400;
// 	font-style: normal;
// 	src: url('../fonts/CircularStd/CircularStd-Book.eot');
// 	src: url('../fonts/CircularStd/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/CircularStd/CircularStd-Book.woff2') format('woff2'), 
// 	url('../fonts/CircularStd/CircularStd-Book.woff') format('woff'), 
// 	url('../fonts/CircularStd/CircularStd-Book.ttf') format('truetype'),
// 	url('../fonts/CircularStd/CircularStd-Book.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Circular Std';
// 	font-weight: 500;
// 	font-style: normal;
// 	src: url('../fonts/CircularStd/CircularStd-Medium.eot');
// 	src: url('../fonts/CircularStd/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/CircularStd/CircularStd-Medium.woff2') format('woff2'), 
// 	url('../fonts/CircularStd/CircularStd-Medium.woff') format('woff'), 
// 	url('../fonts/CircularStd/CircularStd-Medium.ttf') format('truetype'),
// 	url('../fonts/CircularStd/CircularStd-Medium.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Averta';
// 	font-weight: 900;
// 	font-style: normal;
// 	src: url('../fonts/Averta/Averta-Black.eot');
// 	src: url('../fonts/Averta/Averta-Black.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Averta/Averta-Black.woff2') format('woff2'), 
// 	url('../fonts/Averta/Averta-Black.woff') format('woff'), 
// 	url('../fonts/Averta/Averta-Black.ttf') format('truetype'),
// 	url('../fonts/Averta/Averta-Black.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Averta';
// 	font-weight: 800;
// 	font-style: normal;
// 	src: url('../fonts/Averta/Averta-ExtraBold.eot');
// 	src: url('../fonts/Averta/Averta-ExtraBold.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Averta/Averta-ExtraBold.woff2') format('woff2'), 
// 	url('../fonts/Averta/Averta-ExtraBold.woff') format('woff'), 
// 	url('../fonts/Averta/Averta-ExtraBold.ttf') format('truetype'),
// 	url('../fonts/Averta/Averta-ExtraBold.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Averta';
// 	font-weight: 700;
// 	font-style: normal;
// 	src: url('../fonts/Averta/Averta-Bold.eot');
// 	src: url('../fonts/Averta/Averta-Bold.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Averta/Averta-Bold.woff2') format('woff2'), 
// 	url('../fonts/Averta/Averta-Bold.woff') format('woff'), 
// 	url('../fonts/Averta/Averta-Bold.ttf') format('truetype'),
// 	url('../fonts/Averta/Averta-Bold.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Averta';
// 	font-weight: 600;
// 	font-style: normal;
// 	src: url('../fonts/Averta/Averta-Semibold.eot');
// 	src: url('../fonts/Averta/Averta-Semibold.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Averta/Averta-Semibold.woff2') format('woff2'), 
// 	url('../fonts/Averta/Averta-Semibold.woff') format('woff'), 
// 	url('../fonts/Averta/Averta-Semibold.ttf') format('truetype'),
// 	url('../fonts/Averta/Averta-Semibold.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Averta';
// 	font-weight: 300;
// 	font-style: normal;
// 	src: url('../fonts/Averta/Averta-Light.eot');
// 	src: url('../fonts/Averta/Averta-Light.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Averta/Averta-Light.woff2') format('woff2'), 
// 	url('../fonts/Averta/Averta-Light.woff') format('woff'), 
// 	url('../fonts/Averta/Averta-Light.ttf') format('truetype'),
// 	url('../fonts/Averta/Averta-Light.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'CentraNo2';
// 	font-weight: 700;
// 	font-style: normal;
// 	src: url('../fonts/CentraNo2/CentraNo2-Bold.eot');
// 	src: url('../fonts/CentraNo2/CentraNo2-Bold.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/CentraNo2/CentraNo2-Bold.woff2') format('woff2'), 
// 	url('../fonts/CentraNo2/CentraNo2-Bold.woff') format('woff'), 
// 	url('../fonts/CentraNo2/CentraNo2-Bold.ttf') format('truetype'),
// 	url('../fonts/CentraNo2/CentraNo2-Bold.svg#codropsicons') format('svg');
// }


// @font-face {
// 	font-family: 'Brown';
// 	font-weight: 600;
// 	font-style: normal;
// 	src: url('../fonts/Brown/Brown-Bold.eot');
// 	src: url('../fonts/Brown/Brown-Bold.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Brown/Brown-Bold.woff2') format('woff2'), 
// 	url('../fonts/Brown/Brown-Bold.woff') format('woff'), 
// 	url('../fonts/Brown/Brown-Bold.ttf') format('truetype'),
// 	url('../fonts/Brown/Brown-Bold.svg#codropsicons') format('svg');
// }


// @font-face {
// 	font-family: 'Averta';
// 	font-weight: 400;
// 	font-style: normal;
// 	src: url('../fonts/Averta/Averta-Regular.eot');
// 	src: url('../fonts/Averta/Averta-Regular.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Averta/Averta-Regular.woff2') format('woff2'), 
// 	url('../fonts/Averta/Averta-Regular.woff') format('woff'), 
// 	url('../fonts/Averta/Averta-Regular.ttf') format('truetype'),
// 	url('../fonts/Averta/Averta-Regular.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Gilroy';
// 	font-weight: 800;
// 	font-style: normal;
// 	src: url('../fonts/Gilroy/Gilroy-ExtraBold.eot');
// 	src: url('../fonts/Gilroy/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Gilroy/Gilroy-ExtraBold.woff2') format('woff2'), 
// 	url('../fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff'), 
// 	url('../fonts/Gilroy/Gilroy-ExtraBold.ttf') format('truetype'),
// 	url('../fonts/Gilroy/Gilroy-ExtraBold.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Gilroy';
// 	font-weight: 300;
// 	font-style: normal;
// 	src: url('../fonts/Gilroy/Gilroy-Light.eot');
// 	src: url('../fonts/Gilroy/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Gilroy/Gilroy-Light.woff2') format('woff2'), 
// 	url('../fonts/Gilroy/Gilroy-Light.woff') format('woff'), 
// 	url('../fonts/Gilroy/Gilroy-Light.ttf') format('truetype'),
// 	url('../fonts/Gilroy/Gilroy-Light.svg#codropsicons') format('svg');
// }


// @font-face {
// 	font-family: 'Campton';
// 	font-weight: 800;
// 	font-style: normal;
// 	src: url('../fonts/Campton/Campton-Bold.eot');
// 	src: url('../fonts/Campton/Campton-Bold.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Campton/Campton-Bold.woff2') format('woff2'), 
// 	url('../fonts/Campton/Campton-Bold.woff') format('woff'), 
// 	url('../fonts/Campton/Campton-Bold.ttf') format('truetype'),
// 	url('../fonts/Campton/Campton-Bold.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Campton';
// 	font-weight: 400;
// 	font-style: normal;
// 	src: url('../fonts/Campton/Campton-Book.eot');
// 	src: url('../fonts/Campton/Campton-Book.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Campton/Campton-Book.woff2') format('woff2'), 
// 	url('../fonts/Campton/Campton-Book.woff') format('woff'), 
// 	url('../fonts/Campton/Campton-Book.ttf') format('truetype'),
// 	url('../fonts/Campton/Campton-Book.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'GTWalsheim';
// 	font-weight: 400;
// 	font-style: normal;
// 	src: url('../fonts/GTWalsheim/GT-Walsheim-Pro-Regular.eot');
// 	src: url('../fonts/GTWalsheim/GT-Walsheim-Pro-Regular.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/GTWalsheim/GT-Walsheim-Pro-Regular.woff2') format('woff2'), 
// 	url('../fonts/GTWalsheim/GT-Walsheim-Pro-Regular.woff') format('woff'), 
// 	url('../fonts/GTWalsheim/GT-Walsheim-Pro-Regular.ttf') format('truetype'),
// 	url('../fonts/GTWalsheim/GT-Walsheim-Pro-Regular.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'GTSectraDisplay';
// 	font-weight: 500;
// 	font-style: normal;
// 	src: url('../fonts/GTSectraDisplay/GTSectraDisplay-Regular.eot');
// 	src: url('../fonts/GTSectraDisplay/GTSectraDisplay-Regular.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/GTSectraDisplay/GTSectraDisplay-Regular.woff2') format('woff2'), 
// 	url('../fonts/GTSectraDisplay/GTSectraDisplay-Regular.woff') format('woff'), 
// 	url('../fonts/GTSectraDisplay/GTSectraDisplay-Regular.ttf') format('truetype'),
// 	url('../fonts/GTSectraDisplay/GTSectraDisplay-Regular.svg#codropsicons') format('svg');
// }

@font-face {
	font-family: 'Lausanne';
	font-weight: normal;
	font-style: normal;
	src: url('../fonts/Lausanne/Lausanne-Light.eot');
	src: url('../fonts/Lausanne/Lausanne-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Lausanne/Lausanne-Light.woff2') format('woff2'), 
	url('../fonts/Lausanne/Lausanne-Light.woff') format('woff'), 
	url('../fonts/Lausanne/Lausanne-Light.ttf') format('truetype'),
	url('../fonts/Lausanne/Lausanne-Light.svg#codropsicons') format('svg');
}

@font-face {
	font-family: 'Lausanne Extended';
	font-weight: normal;
	font-style: normal;
	src: url('../fonts/Lausanne/Lausanne-Light-Extended.eot');
	src: url('../fonts/Lausanne/Lausanne-Light-Extended.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Lausanne/Lausanne-Light-Extended.woff2') format('woff2'), 
	url('../fonts/Lausanne/Lausanne-Light-Extended.woff') format('woff'), 
	url('../fonts/Lausanne/Lausanne-Light-Extended.ttf') format('truetype'),
	url('../fonts/Lausanne/Lausanne-Light-Extended.svg#codropsicons') format('svg');
}

@font-face {
	font-family: 'Reckless Neue';
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/RecklessNeue/RecklessNeue-Light.eot');
	src: url('../fonts/RecklessNeue/RecklessNeue-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/RecklessNeue/RecklessNeue-Light.woff2') format('woff2'), 
	url('../fonts/RecklessNeue/RecklessNeue-Light.woff') format('woff'), 
	url('../fonts/RecklessNeue/RecklessNeue-Light.ttf') format('truetype'),
	url('../fonts/RecklessNeue/RecklessNeue-Light.svg#codropsicons') format('svg');
}

// @font-face {
// 	font-family: 'Akzidenz Grotesk';
// 	font-weight: normal;
// 	font-style: normal;
// 	src: url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Regular.eot');
// 	src: url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Regular.woff2') format('woff2'), 
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Regular.woff') format('woff'), 
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Regular.ttf') format('truetype'),
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Regular.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Akzidenz Grotesk';
// 	font-weight: 500;
// 	font-style: normal;
// 	src: url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Medium.eot');
// 	src: url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Medium.woff2') format('woff2'), 
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Medium.woff') format('woff'), 
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Medium.ttf') format('truetype'),
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGrotesk-Medium.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Akzidenz Grotesk Extended';
// 	font-weight: normal;
// 	font-style: normal;
// 	src: url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Regular.eot');
// 	src: url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Regular.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Regular.woff2') format('woff2'), 
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Regular.woff') format('woff'), 
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Regular.ttf') format('truetype'),
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Regular.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Akzidenz Grotesk Extended';
// 	font-weight: 500;
// 	font-style: normal;
// 	src: url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Medium.eot');
// 	src: url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Medium.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Medium.woff2') format('woff2'), 
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Medium.woff') format('woff'), 
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Medium.ttf') format('truetype'),
// 	url('../fonts/AkzidenzGrotesk/AkzidenzGroteskExtended-Medium.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Hurme';
// 	font-weight: 400;
// 	font-style: normal;
// 	src: url('../fonts/Hurme/Hurme-Regular.eot');
// 	src: url('../fonts/Hurme/Hurme-Regular.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Hurme/Hurme-Regular.woff2') format('woff2'), 
// 	url('../fonts/Hurme/Hurme-Regular.woff') format('woff'), 
// 	url('../fonts/Hurme/Hurme-Regular.otf') format('truetype'),
// 	url('../fonts/Hurme/Hurme-Regular.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Hurme';
// 	font-weight: 200;
// 	font-style: normal;
// 	src: url('../fonts/Hurme/Hurme-Thin.eot');
// 	src: url('../fonts/Hurme/Hurme-Thin.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Hurme/Hurme-Thin.woff2') format('woff2'), 
// 	url('../fonts/Hurme/Hurme-Thin.woff') format('woff'), 
// 	url('../fonts/Hurme/Hurme-Thin.otf') format('truetype'),
// 	url('../fonts/Hurme/Hurme-Thin.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Hurme';
// 	font-weight: 600;
// 	font-style: normal;
// 	src: url('../fonts/Hurme/Hurme-SemiBold-alt.eot');
// 	src: url('../fonts/Hurme/Hurme-SemiBold-alt.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Hurme/Hurme-SemiBold-alt.woff2') format('woff2'), 
// 	url('../fonts/Hurme/Hurme-SemiBold-alt.woff') format('woff'), 
// 	url('../fonts/Hurme/Hurme-SemiBold-alt.otf') format('truetype'),
// 	url('../fonts/Hurme/Hurme-SemiBold-alt.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Hurme';
// 	font-weight: 700;
// 	font-style: normal;
// 	src: url('../fonts/Hurme/Hurme-Bold.eot');
// 	src: url('../fonts/Hurme/Hurme-Bold.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Hurme/Hurme-Bold.woff2') format('woff2'), 
// 	url('../fonts/Hurme/Hurme-Bold.woff') format('woff'), 
// 	url('../fonts/Hurme/Hurme-Bold.otf') format('truetype'),
// 	url('../fonts/Hurme/Hurme-Bold.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Oh My Sans';
// 	font-weight: 400;
// 	font-style: normal;
// 	src: url('../fonts/OhMySans/OhMySans-Text-Regular.eot');
// 	src: url('../fonts/OhMySans/OhMySans-Text-Regular.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/OhMySans/OhMySans-Text-Regular.woff2') format('woff2'), 
// 	url('../fonts/OhMySans/OhMySans-Text-Regular.woff') format('woff'), 
// 	url('../fonts/OhMySans/OhMySans-Text-Regular.otf') format('truetype'),
// 	url('../fonts/OhMySans/OhMySans-Text-Regular.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'TT Commons';
// 	font-weight: 600;
// 	font-style: normal;
// 	src: url('../fonts/TTCommons/TTCommons-Medium-web.eot');
// 	src: url('../fonts/TTCommons/TTCommons-Medium-web.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/TTCommons/TTCommons-Medium-web.woff2') format('woff2'), 
// 	url('../fonts/TTCommons/TTCommons-Medium-web.woff') format('woff'), 
// 	url('../fonts/TTCommons/TTCommons-Medium-web.otf') format('truetype'),
// 	url('../fonts/TTCommons/TTCommons-Medium-web.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Smitten';
// 	font-weight: 400;
// 	font-style: normal;
// 	src: url('../fonts/Smitten/Smitten-Regular.eot');
// 	src: url('../fonts/Smitten/Smitten-Regular.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Smitten/Smitten-Regular.woff2') format('woff2'), 
// 	url('../fonts/Smitten/Smitten-Regular.woff') format('woff'), 
// 	url('../fonts/Smitten/Smitten-Regular.otf') format('truetype'),
// 	url('../fonts/Smitten/Smitten-Regular.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Smitten';
// 	font-weight: 500;
// 	font-style: normal;
// 	src: url('../fonts/Smitten/Smitten-Medium.eot');
// 	src: url('../fonts/Smitten/Smitten-Medium.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Smitten/Smitten-Medium.woff2') format('woff2'), 
// 	url('../fonts/Smitten/Smitten-Medium.woff') format('woff'), 
// 	url('../fonts/Smitten/Smitten-Medium.otf') format('truetype'),
// 	url('../fonts/Smitten/Smitten-Medium.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Smitten';
// 	font-weight: 700;
// 	font-style: normal;
// 	src: url('../fonts/Smitten/Smitten-Bold.eot');
// 	src: url('../fonts/Smitten/Smitten-Bold.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Smitten/Smitten-Bold.woff2') format('woff2'), 
// 	url('../fonts/Smitten/Smitten-Bold.woff') format('woff'), 
// 	url('../fonts/Smitten/Smitten-Bold.otf') format('truetype'),
// 	url('../fonts/Smitten/Smitten-Bold.svg#codropsicons') format('svg');
// }

@font-face {
	font-family: 'Futura Maxi';
	font-weight: 600;
	font-style: normal;
	src: url('../fonts/FuturaMaxi/FuturaMaxi-Demi.eot');
	src: url('../fonts/FuturaMaxi/FuturaMaxi-Demi.eot?#iefix') format('embedded-opentype'),
	url('../fonts/FuturaMaxi/FuturaMaxi-Demi.woff2') format('woff2'), 
	url('../fonts/FuturaMaxi/FuturaMaxi-Demi.woff') format('woff'), 
	url('../fonts/FuturaMaxi/FuturaMaxi-Demi.otf') format('opentype'),
	url('../fonts/FuturaMaxi/FuturaMaxi-Demi.svg#codropsicons') format('svg');
}

@font-face {
	font-family: 'Futura Maxi';
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/FuturaMaxi/FuturaMaxi-Book.eot');
	src: url('../fonts/FuturaMaxi/FuturaMaxi-Book.eot?#iefix') format('embedded-opentype'),
	url('../fonts/FuturaMaxi/FuturaMaxi-Book.woff2') format('woff2'), 
	url('../fonts/FuturaMaxi/FuturaMaxi-Book.woff') format('woff'), 
	url('../fonts/FuturaMaxi/FuturaMaxi-Book.otf') format('opentype'),
	url('../fonts/FuturaMaxi/FuturaMaxi-Book.svg#codropsicons') format('svg');
}

@font-face {
	font-family: 'Moderat';
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/Moderat/Moderat-Regular.eot');
	src: url('../fonts/Moderat/Moderat-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Moderat/Moderat-Regular.woff2') format('woff2'), 
	url('../fonts/Moderat/Moderat-Regular.woff') format('woff'), 
	url('../fonts/Moderat/Moderat-Regular.ttf') format('truetype'),
	url('../fonts/Moderat/Moderat-Regular.svg#codropsicons') format('svg');
}

// @font-face {
// 	font-family: 'Moderat';
// 	font-weight: 500;
// 	font-style: normal;
// 	src: url('../fonts/Moderat/Moderat-Medium.eot');
// 	src: url('../fonts/Moderat/Moderat-Medium.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Moderat/Moderat-Medium.woff2') format('woff2'), 
// 	url('../fonts/Moderat/Moderat-Medium.woff') format('woff'), 
// 	url('../fonts/Moderat/Moderat-Medium.ttf') format('truetype'),
// 	url('../fonts/Moderat/Moderat-Medium.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Moderat';
// 	font-weight: 700;
// 	font-style: normal;
// 	src: url('../fonts/Moderat/Moderat-Bold.eot');
// 	src: url('../fonts/Moderat/Moderat-Bold.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Moderat/Moderat-Bold.woff2') format('woff2'), 
// 	url('../fonts/Moderat/Moderat-Bold.woff') format('woff'), 
// 	url('../fonts/Moderat/Moderat-Bold.ttf') format('truetype'),
// 	url('../fonts/Moderat/Moderat-Bold.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Moderat Mono';
// 	font-weight: 600;
// 	font-style: normal;
// 	src: url('../fonts/Moderat/Moderat-Mono-Medium.eot');
// 	src: url('../fonts/Moderat/Moderat-Mono-Medium.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Moderat/Moderat-Mono-Medium.woff2') format('woff2'), 
// 	url('../fonts/Moderat/Moderat-Mono-Medium.woff') format('woff'), 
// 	url('../fonts/Moderat/Moderat-Mono-Medium.ttf') format('truetype'),
// 	url('../fonts/Moderat/Moderat-Mono-Medium.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Apercu';
// 	font-weight: 800;
// 	font-style: normal;
// 	src: url('../fonts/Apercu/Apercu-Black.eot');
// 	src: url('../fonts/Apercu/Apercu-Black.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Apercu/Apercu-Black.woff2') format('woff2'), 
// 	url('../fonts/Apercu/Apercu-Black.woff') format('woff'), 
// 	url('../fonts/Apercu/Apercu-Black.ttf') format('truetype'),
// 	url('../fonts/Apercu/Apercu-Black.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Apercu';
// 	font-weight: 300;
// 	font-style: normal;
// 	src: url('../fonts/Apercu/Apercu-Light.eot');
// 	src: url('../fonts/Apercu/Apercu-Light.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Apercu/Apercu-Light.woff2') format('woff2'), 
// 	url('../fonts/Apercu/Apercu-Light.woff') format('woff'), 
// 	url('../fonts/Apercu/Apercu-Light.ttf') format('truetype'),
// 	url('../fonts/Apercu/Apercu-Light.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Apercu';
// 	font-weight: 400;
// 	font-style: normal;
// 	src: url('../fonts/Apercu/Apercu-Regular.eot');
// 	src: url('../fonts/Apercu/Apercu-Regular.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Apercu/Apercu-Regular.woff2') format('woff2'), 
// 	url('../fonts/Apercu/Apercu-Regular.woff') format('woff'), 
// 	url('../fonts/Apercu/Apercu-Regular.ttf') format('truetype'),
// 	url('../fonts/Apercu/Apercu-Regular.svg#codropsicons') format('svg');
// }

// @font-face {
// 	font-family: 'Apercu';
// 	font-weight: 500;
// 	font-style: normal;
// 	src: url('../fonts/Apercu/Apercu-Bold.eot');
// 	src: url('../fonts/Apercu/Apercu-Bold.eot?#iefix') format('embedded-opentype'),
// 	url('../fonts/Apercu/Apercu-Bold.woff2') format('woff2'), 
// 	url('../fonts/Apercu/Apercu-Bold.woff') format('woff'), 
// 	url('../fonts/Apercu/Apercu-Bold.ttf') format('truetype'),
// 	url('../fonts/Apercu/Apercu-Bold.svg#codropsicons') format('svg');
// }
