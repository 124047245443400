$baseclass: clip-wrapper;

.#{$baseclass} {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	clip: rect(0,auto,auto,0);

	&__left {
		position: fixed;
		top: 0px;
		width: 72px;
		left: 0px;
		border: none;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		display: flex;

		> * {
			margin: 60px 0;
		}

		> *:first-child:not(*:last-child) {
			margin-bottom: 0;
		}

		> *:last-child:not(*:first-child) {
			margin-top: 0;
		}

		a {
			pointer-events: none;
			@include zIndexCloser();
		}

		.#{$arrow-group-baseclass} {
			transform: translate3d(-33px,0,0);

			&,
			&__first,
			&__first:first-child,
			&__second,
			&__second:nth-child(2) {
				transition: transform $easing1 $duration-short;
				pointer-events: auto;
			}

			& {
				transition-delay: $duration-short;
			}

			&__first, &__second {
				@for $i from 1 through 2 {
					&:nth-child(#{$i}) {
						transition-delay: (#{0.0 + ($i * 0.18)}s);							
					}
				}

				.icon {
					width: 48px;
					height: 48px;
				}
			}

			&__first {
				transform: scaleX(-1) rotate(0deg) translate3d(0,4px,0);
			}

			&__second {
				transform: rotate(180deg) translate3d(0,4px,0);
			}

			@media screen and (max-width: $bp-medium) {
				display: none;
			}
		}

		.arrow-nav--hovering {
			.#{$arrow-group-baseclass} {
				transform: translate3d(-12px,0,0);
				transition-delay: 0s;
			}

			.#{$arrow-group-baseclass}__first {
				transform: scaleX(-1) rotate(-90deg);
			}
			.#{$arrow-group-baseclass}__second {
				transform: rotate(90deg);
			}
		}

		hr {
			margin: 6px 18px;
			width: 100%;
			transform: scaleX(0.5);
			transform-origin: left;
		}

		.active-section & {
			a {
				pointer-events: auto;
				@include zIndexCloser();
			}
		}


		&__bottom {
			transform: rotate(-90deg) translateX(40%);

			h1, h2, h3, h4, h5, h6 {
				white-space: nowrap;
			}
		}

		i {
			margin: 0 12px;
		}
	}
}
