$arrow-group-baseclass: arrow-group;

.#{$arrow-group-baseclass} {
	position: relative;
	display: flex;
	justify-content: flex-start;

	.#{$arrow-group-baseclass}__first {
		transform: scaleX(-1);
	}

	&__links {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 100%;

		> * {
			flex: 1;
		}

		div {
			height: 100%;
		}
	}

	&--vertical {
		flex-direction: column;

		.#{$arrow-group-baseclass}__links {
			flex-direction: column;

			> * {
				max-height: 50%;
			}
		}

		.#{$arrow-group-baseclass}__first {
			transform: scaleX(-1) rotate(-90deg);
		}
		.#{$arrow-group-baseclass}__second {
			transform: rotate(90deg);
		}
	}
}

.#{$scrolling-section-classname} {
	&:first-child {
		.#{$arrow-group-baseclass}__links {
			justify-content: flex-end;
		}
		.#{$arrow-group-baseclass}__first {
			@include disabled();
		}
	}
	&:last-child {
		.#{$arrow-group-baseclass}__links {
			justify-content: flex-start;
		}
		.#{$arrow-group-baseclass}__second {
			@include disabled();
		}
	}
}
