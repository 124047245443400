$baseclass: nav-takeover;

.#{$baseclass} {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include zIndexCloser();

	.style-line {
		position: fixed;
		width: 100%;
		height: 100%;
	}

	&__main {
		position: fixed;
		width: 100%;
		pointer-events: none;
		height: 100vh;
		top: 0px;
		display: flex;
		overflow: hidden;
	}

	&__overlay, &__panel {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		transition: transform $easing6 $duration-medium;
		transform-origin: right;
		transform: scale3d(0, 1, 1);
	}

	&__overlay {
		transition-delay: $delay-short;
		background: rgba($brand-grey-lightest, 1);
	}

	&__panel {
		background: rgba($brand-white, 1);
		display: flex;
		justify-content: center;
		flex-direction: row-reverse;
		overflow-x: hidden;

		> * {
			opacity: 0;
			transition: none;
		}

		h1, h2, h3, h4 {
			display: inline;
			pointer-events: none;
			transition: none;
			margin: 0;

			&.hovered {
				color: transparent;
				-webkit-text-stroke: 1px $brand-red;
			}
		}

		a {
			white-space: pre;
			height: 100%;
			border: none;
			display: flex;
			align-items: flex-start;
		}

		hr {
			height: 3px;
		}

		li {
			display: flex;
			align-items: flex-start;
			transform: translate3d(-125%, 0,0);
			// cursor: pointer;
			transition: none;
			width: auto;
			min-width: 90px;
			margin-bottom: 40px;
			height: 72px;
		}
	}

	&__breadcrumbs, &__items {
		padding: 0 40px;
	}

	&__breadcrumbs {
		position: absolute;
		display: flex;
		flex-direction: row-reverse;
		flex-wrap: wrap-reverse;
		justify-content: flex-end;
		align-items: flex-end;
		// line-height: 1;
		transform: translateY(-84px);

		.icon-caret {
			margin: 0 12px;
			transform: translateY(-1px);
			// margin-bottom: 2px;
		}
	}

	&__breadcrumbs-item {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
	}

	&__line-container {
		width: 1px;
		transform: translateX(-1px);
		// background: rgba($brand-black, 0.06);
		padding-top: 160px;
	}

	&__line {
		background: lighten($brand-red, 0%);
		opacity: 0;
		height: 80px;
		width: 100%;
		transform: translate3d(0,-300%,0);
		transition: all $easing2 $duration-long;
	}

	&__controls {
		position: fixed;
		left: 12px;
		top: 52px;
		display: flex;
	}

	&__item-container {
		flex: 1;
		position: relative;
		overflow: hidden;
		min-height: 100vh;
		padding: 160px 0 72px;
	}

	&__items {
		pointer-events: none;
		position: absolute;
		left: 0;
		min-height: 100%;
		height: auto;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		li {
			transform: translate3d(-125%, 0,0);
			transition: transform $easing5 $duration-long;

			@for $i from 1 through 24 {
				&:nth-child(#{$i}) {
					transition-delay: (#{0.0 + ($i * 0.09)}s);
				}
			}
		}
	}

	@mixin activeDot() {
		content: '';
		border: solid 1px $brand-red;
		width: 6px;
		height: 6px;
		display: block;
		border-radius: 50%;
		position: absolute;
		left: -18px;
		top: calc(50% - 3px);
	}


	&__items--active {

		h1, h2, h3, h4, h5, h6 {
			&.active {
				// color: transparent;
				// -webkit-text-stroke: 1px $brand-black;

				// &:after {
				// 	@include activeDot();
				// }
			}
		}
	}

	&__items--secondary {
		a.active {
			h1, h2, h3, h4, h5, h6 {
				// &:after {
				// 	@include activeDot();
				// }

				&.hovered {
					color: transparent;
					-webkit-text-stroke: 1px $brand-red;
				}
			}
		}

		h1, h2, h3, h4, h5, h6 {
			&.hovered {
				color: transparent;
				-webkit-text-stroke: 1px $brand-red;
			}
		}
	}

	&--menuOpen {
		.#{$baseclass}__main {
			pointer-events: auto;
		}

		.#{$baseclass}__overlay, .#{$baseclass}__panel {
			transform-origin: left;
			transform: none;
			transition-delay: 0s;
		}

		// .#{$baseclass}__overlay {
		// 	transform: scale3d(2,1,1);
		// }

		.#{$baseclass}__panel {
			transition-delay: $delay-medium;
		}

		.#{$baseclass}__panel {
			> * {
				opacity: 1;
				transition: opacity $easing6 $duration-medium $duration-medium;
			}
		}

		.#{$baseclass}__items--active {
			pointer-events: auto;

			> li {
				transform: none;
				transition-timing-function: $easing2;

				@for $i from 1 through 24 {
					&:nth-child(#{$i}) {
						transition-delay: (#{0.6 + ($i * 0.09)}s);
					}
				}
			}

			.#{$baseclass}__items {
				pointer-events: none;
			}
		}
	}

	@media screen and (max-width: $bp-medium) {
		.#{$baseclass}__controls {
			left: auto;
			top: auto;
			right: 12px;
			bottom: 12px;
		}

		.#{$baseclass}__line-container, .#{$baseclass}__item-container {
			padding-top: 108px;
		}

		.#{$baseclass}__spacer {
			width: 48px;
		}

		.#{$baseclass}__line {
			height: 48px;
		}

		.#{$baseclass}__breadcrumbs, .#{$baseclass}__items {
			padding: 0 12px;
		}

		.#{$baseclass}__breadcrumbs {
			transform: translateY(-60px);

			.icon-caret {
				margin: 0 6px;
			}
		}

		.#{$baseclass}__panel {
			width: 100%;

			hr {
				display: block;
			}
			ul {
				width: 100%;
			}
			li {
				width: 100%;
				height: 48px;
				margin-bottom: 24px;
			}

			h1, h2, h3, h5, h4 {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
			
			a {
				width: 100%;
				display: flex;
				align-items: center;
				white-space: normal;
			}
			.#{$baseclass}__items {
				// margin-bottom: 18px;
				// padding: 0;

				.#{$baseclass}__item {
					&:last-child {
						border-right: none;
					}
				}
			}
		}
		.#{$baseclass}--menuOpen {
			opacity: 0;

			.#{$baseclass}__panel--secondary {
				transform: translate3d(100%,0,0);
			}
		}
		.tabbed-list__header {
			ul {
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		&.#{$baseclass}--secondaryPanelOpen {

			.#{$baseclass}__panel--secondary {
				transform: none;
			}
			.#{$baseclass}__panel--primary {
				transform: translate3d(100%,0,0);

				li {
					transform: translate3d(12%,0,0);
					opacity: 0;
				}
			}
		}
	}
}