$baseclass: parallax-background;

.#{$baseclass} {
	position: absolute;
	will-change: transform;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	justify-content: center;
	transition: opacity $duration-short $easing1;

	.#{$scrolling-section-classname}--black & {
		background-color: transparent;
	}

	&--hidden {
		position: relative;
		display: none;
	}

	@media screen and (max-width: $bp-medium) {
		justify-content: flex-start;
	}
}
