$baseclass: grid-line;

.#{$baseclass} {
	&__container {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;

		.grid, section {
			height: 100%;
		}

		.grid {
			margin: 0 auto; 

			> div {
				height: 100%;
			}
		}

		section {
			background: none;
			justify-content: space-between;
		}
	}

	&__item {
		display: flex;
		justify-content: space-between;
	}

	&__vertical, &__horizontal {
		background-color: rgba($brand-black, 0.06);
	}

	&__vertical {
		width: 1px;
		min-width: 1px;
		height: 100%;
		min-height: 100vh;
	}

	&__horizontal {
		height: 1px;
		width: 100%;
	}

	.#{$scrolling-section-classname}--black & {
		&__vertical,
		&__horizontal {
			background-color: rgba($brand-white, 0.06);
		}
	}

	@media screen and (max-width: $bp-medium) {
		section {
			padding: 120px 0;
		}
	}
}

