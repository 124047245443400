$baseclass: iframe-wrapper;

.#{$baseclass} {
	display: flex;
	flex-direction: column;
	border: solid 1px;
	border-radius: 3px;
	overflow: hidden;
	margin-right: -1px;
	border-color: rgba($brand-black, .12);
	background: $brand-white;
	position: relative;

	> * {

	}

	.#{$baseclass}__controls {
		display: flex;
		justify-content: space-between;
		padding: 12px 18px;
		// border-bottom: solid 1px;

		// > * {
		// 	margin-right: 12px;
		// }
	}

	.#{$baseclass}__main {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-top: solid 1px rgba($brand-black, .12);
	}

	.icon-arrow {
		transform: rotate(-45deg);
	}

	.#{$scrolling-section-classname}--black & {
		background: $brand-black;
		border-color: rgba($brand-white, .12);

		.#{$baseclass}__main {
			border-color: rgba($brand-white, .12);
		}
	}

	.#{$scrolling-section-classname}--grey & {
		background: $brand-grey-lightest;
	}

	@media screen and (max-width: $bp-medium) {
		figcaption {
			display: none;
		}
	}
}