@keyframes ghostIn {
	0% {
		transform: translate3d(0,128px,0) skewY(0deg);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes ghostOut {
	0% {
		transform: none;
	}
	100% {
		transform: translate3d(0, 128px, 0) skewY(0deg);
	}
}

// .image--visible {
// 	img, video {
// 		transition-duration: $duration-long;
// 		transition-timing-function: $easing5;
// 		transition-property: transform, opacity;
// 		opacity: 1;
// 		transform: translate3d(0,0,0) skewX(0deg);
// 	}
// }

// @keyframes fadeIn {
// 	0%, 100% {
// 		opacity: 1;
// 	}
// 	50%, {
// 		opacity: 0;
// 	}
// }

// article {
// 	opacity: 0;
// 	animation: fadeIn $duration-short $easing1;
// 	animation-fill-mode: forwards;
// }

// article {
// 	animation: fadeIn 0.9s $easing1;
// }

.scale-up {
	transition: $easing2 $duration-medium transform;

	&--sm {
		&:hover {
			transform: scale(3);
		}		
	}
	
	&--lg {
		&:hover {
			transform: scale(6);
		}		
	}
}

section {
	.image-wrapper {
		opacity: 0;
		transition: none;
		transform: translate3d(0,50%,0) skewY(6deg);
	}

	blockquote {
		transition: none;
		opacity: 0;
	}

	.grid__item  .grid__item,
	.grid__item {
		@for $i from 1 through 60 {
			&:nth-child(#{$i}) {
				.image-wrapper {
					transition-delay: (#{0.9 + ($i * 0.3)}s);
				}
				blockquote {
					transition-delay: (#{0.3 + ($i * 0.3)}s);
				}
			}
		}
	}

	.carousel--stacking {
		.carousel__item {
			@for $i from 1 through 60 {
				&:nth-child(#{$i}) {
					.image-wrapper {
						transition-delay: (#{0.3 + ($i * 0.24)}s);
					}
				}
			}
		}
	}

	.split--word, .split--letter {
		overflow: hidden;

		> span {
			transition: none;
			opacity: 0;
		}
	}

	h2, h3, h4, h5, h6 {
		transition: none;
		opacity: 0;

		.split--word, .split--letter {
			> span {
				transform: translate3d(0,90px,0) skewX(9deg);
			}
		}
	}

	p, blockquote {
		.split--word, .split--letter {
			> span {
				opacity: 0;
				transform: translate3d(0,100%,0) skewX(6deg);
			}
		}
	}
}

.active-section {
	section {
		h1 {
			> span {
				overflow: hidden;

				@for $i from 1 through 60 {
					&:nth-child(#{$i}) span {
						animation-delay: (#{0.6 + ($i * 0.12)}s);
					}
				}
			}

			> span > span {
				animation: ghostIn $duration-long $easing1;
				animation-fill-mode: both;
			}
		}

		h2, h3, h4, h5, h6 {
			opacity: 1;
			transition: transform $duration-long $delay-medium $easing1, opacity $duration-long $delay-medium $easing1;

			.split--word, .split--letter {
				> span {
					transition: transform $duration-long $easing1, opacity $duration-long $easing1;
					opacity: 1;
					transform: translate3d(0,-8%,0);
					transform: none;
				}
			} 
		}

		h2, h3 {
			transition-delay: $delay-short;
		}

		h4 {
			transition-delay: $delay-longer;
		}

		h5, h6 {
			transition-delay: $delay-longest;
		}


		h2, h3, h4, h5, h6 {
			.split--word {
				@for $i from 1 through 60 {
					&:nth-child(#{$i}) {
						span {
							transition-delay: (#{0.6 + ($i * 0.18)}s);							
						}
					}
				}
			}
			.split--letter > span {
				@for $i from 1 through 60 {
					&:nth-child(#{$i}) {
						transition-delay: (#{0.6 + ($i * 0.09)}s);							
					}
				}
			}
		}

		p, blockquote {
			opacity: 1;
			transition: transform $duration-long $easing1, opacity $duration-long $easing1;

			.split--word {
				@for $i from 1 through 120 {
					&:nth-child(#{$i}) span {
						transition-delay: (#{1.2 + ($i * 0.03)}s);
					}
				}
			}

			.split--letter > span {
				@for $i from 1 through 120 {
					&:nth-child(#{$i}) {
						transition-delay: (#{1.2 + ($i * 0.03)}s);
					}
				}
			}

			.split--word, .split--letter {
				> span {
					opacity: 1;
					transform: translate3d(0,0,0) skewX(0deg);
					transition-duration: $duration-medium;
					transition-timing-function: $easing1;
					transition-property: transform, opacity;
				}	
			} 
		}

		.image-wrapper {
			transition-duration: $duration-long;
			transition-timing-function: $easing5;
			transition-property: transform, opacity;
			transform: translate3d(0,0,0) skewX(0deg);
			opacity: 1;
		}
	}
}


