$baseclass: cursor;

.#{$baseclass} {
	position: relative;
	pointer-events: none;
	opacity: 0;
	left: 0;
	top: 0;
	position: fixed;
	mix-blend-mode: difference;
	@include zIndexClosest();
	// transition: opacity $easing4 $duration-long;

	&__wrapper {
		transform: translate(-50% , -50%);
		width: 80px;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__outer {
		position: fixed;
		width: 100%;
		height: 100%;

		circle {
			transition: all $easing4 $duration-short;
			transform-origin: center;
			fill: none;
			stroke: $brand-white;
			stroke-width: 1;
			vector-effect: non-scaling-stroke;
		}
	}

	&__inner, &__text {
		transition: all $easing4 $duration-short;
	}

	&__text {
		color: $brand-white;
		opacity: 0;
	}

	&__inner {
		position: absolute;
		height: 6px;
		width: 6px;
		background: $brand-white;
		border-radius: 50%;
	}

	&__line {
		background: $brand-white;
		height: 1px;
		width: 12px;
	}

	&--visible {
		opacity: 1;
	}

	&--hovering {
		.#{$baseclass}__outer {
			circle {
				// opacity: 0.8;
				transform: scale(1.5);
			}
			// opacity: 0;
		}

		.#{$baseclass}__text {
			opacity: 1;
		}

		.#{$baseclass}__inner {
			opacity: 0;
			// transform: scale(1.5);
			// background: transparent;
			// border: solid .1px $brand-white;
		}
	}

		// &:first-child {
		// 	transform-origin: 50% 50%;
		// 	// transform: rotate(90deg);
		// }
		// &:last-child {
		// 	transform-origin: top center;
		// 	transform: rotate(90deg) translate(-1px, -1px);
		// }
	}