* {
	box-sizing: border-box;
}

html {
	background: $brand-grey-dark;
}


body {
	background: $brand-white;
	-webkit-overflow-scrolling: touch;

	-webkit-touch-callout: none;
	-webkit-user-select: none; 
	-moz-user-select: none;    
	-ms-user-select: none;     

	-o-user-select: none;
	user-select: none;  


	cursor: none !important;
	color: $brand-grey-dark;
}

ul {
	margin-left: 30px;

	&.ls {
		list-style: disc;
	}
}

a {
	cursor: none;
	color: $brand-grey-dark;  
	// border-bottom: solid 1px;
	text-decoration: none;
	outline: none;
	// white-space: normal;
}

hr {
	opacity: 0.05;
	background: $brand-black;
	height: 1px;
	width: 100%;
	min-width: 60px;
	border: 0;
	display: inline-block;
}

video {
	position: relative;
	width: 100%;
}

iframe {
	position: relative;
	width: 0;
	min-width: 100%;
	max-width: 100%;
}

.active-section {
	// position: relative;
}

*::-webkit-scrollbar {
	width: 0px;
	background: transparent; 
}

.disabled {
	@include disabled();
}

section {
	padding: 120px 0;
	position: relative;
	overflow: hidden;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: $brand-white;
	background-size: cover;
	background-position: center;
	transition: background-color $easing2 $duration-long;
	// @include zIndexClose();		

	img {
		width: 100%;
	}

	.spacer {
		&__sm {
			min-height: 60px;
		}
		&__md {
			min-height: 120px;
		}
		&__lg {
			min-height: 240px;
		}
	}

	.shift {
		&-left--lg {
			transform: translateX(-100%);
		}
		&-left--md {
			transform: translateX(-50%);
		}
		&-left--sm {
			transform: translateX(-33.3333%);
		}
		&-left--xs {
			transform: translateX(-25%);
		}
		&-right--lg {
			transform: translateX(100%);
		}
		&-right--md {
			transform: translateX(50%);
		}
		&-right--sm {
			transform: translateX(33.3333%);
		}
		&-right--xs {
			transform: translateX(25%);
		}
	}
}

.#{$scrolling-section-classname} {
	> div {
		position: relative;
	}

	.icon {
		stroke: $brand-black;
	}			

	&__number {
		transform: translate(-24px, 0);
	}

	.outline {
		-webkit-text-stroke-color: $brand-black;
	}

	&--black {
		section {
			background-color: $brand-black;
		}

		h1, h2, h3, h4, h5, h6, p, blockquote, a, figcaption {
			color: $brand-white;
		}

		.outline {
			-webkit-text-stroke-color: $brand-white;
		}

		hr {
			background-color: $brand-white;
		}

		.grid__line {
			background-color: rgba($brand-white, 0.05);
		}

		text {
			fill: $brand-white;
		}

		.icon {
			stroke: $brand-white;
		}
	}

	&--grey {
		section {
			background-color: $brand-grey-lightest;
		}

		h1, h2, h3, h4, h5, h6, p, blockquote, a {
			color: rgba($brand-grey-dark, 1);
		}
	}

}

h1, h2, h3, h4, p, blockquote {
	span {
		display: inline-block;
	}
}
