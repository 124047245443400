$baseclass: sidebar;

.#{$baseclass} {
	// height: 100vh;
	width: auto;
	position: fixed;
	pointer-events: none;
	display: none;
	align-items: center;
	justify-content: flex-end;
	top: 0px;
	background-blend-mode: multiply;
	transition: transform $duration-short $easing4;
	@include zIndexCloser();
	
	@for $i from 50 through 200 {
		&--length#{$i} {
			transform: translateX(#{42 + ($i)}px);
		}
	}
	
	&--left {
		left: 0;
		transform: translateX(-48px);
		
		.#{$baseclass}__dash {
			transform-origin: left;
		}
		
		.#{$baseclass}__border {
			-webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 60%, transparent);
		}
	}
	
	&--right {
		right: 0;
		
		.#{$baseclass}__dash {
			transform-origin: right;
		}
		
		.#{$baseclass}__border {
			-webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 60%, transparent);
		}
	}
	
	.active-section & {
		display: flex;
		pointer-events: auto;
		
		&__inner {
			pointer-events: auto;
		}
	}
	
	&__inner {
		pointer-events: none;
		list-style: none;
		margin-top: 60px;
		margin-left: 0;
		width: 100%;
		padding: 0;
		transition: all $easing4 $duration-short
	}
	
	&__item {
		width: 100%;
		height: 42px;
		border-bottom: none;
		overflow: hidden;
		display: block;
		position: relative;
		transition: height $easing4 $duration-short $duration-short;
		
		a {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			border: none;
			pointer-events: none;
			
			&.active {
				.sidebar__dash {
					transform: scaleX(1);
				}
			}
		}
	}
	
	&__label, &__number {
		color: darken($brand-black, 0%);
		// text-shadow: 0 1px $brand-white;
		opacity: 0;
		display: block;
	}
	
	&__number,
	&__indicator,
	&__dash {
		margin-right: 12px;
	}
	
	&__number {
		color: darken($brand-black, 0%);
		width: 24px;
	}
	
	&__label {
		color: darken($brand-black, 0%);
		white-space: nowrap;
	}
	
	&__indicator {
		opacity: 0;
		-webkit-text-stroke-width: 3px;
		font-family: $font-primary;
	}
	
	&__dash {
		width: 48px;
		height: 1px;
		background: darken($brand-black, 0%);
		display: inline-block;
		transform: scaleX(0.5);
		transition: all $easing4 $duration-short;
	}
	
	&__border {
		display: block;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 1px;
		background: rgba($brand-black, .06);
		opacity: 0;
	}
	
	&__border--top {
		bottom: auto;
		top: 0;
		display: none;
	}
	
	
	&--open {
		transform: none;
		transition: transform $duration-short $duration-short $easing3;
		
		.#{$baseclass}__inner {
			
			.#{$baseclass}__item {
				height: 60px;
				transition: height $easing4 $duration-short;
				
				a {
					pointer-events: auto;
					
					&.active {
						.#{$baseclass}__indicator {
							opacity: 1;
							color: $brand-red;
						}
					}
				}
				
				.#{$baseclass}__number,
				.#{$baseclass}__label,
				.#{$baseclass}__border {
					opacity: 1;
					transition: opacity $duration-short $duration-short $easing3;
				}
				.#{$baseclass}__number {
					transform: translate3d(0, 0, 0);
				}
				
				
				.#{$baseclass}__dash {
					transform: scaleX(1);
				}
			}
		}
		
		
	}
	
	.#{$scrolling-section-classname}--black & {
		.#{$baseclass}__dash {
			background: $brand-white;
		}
		&--open {
			.#{$baseclass}__inner {
				.#{$baseclass}__border {
					background: rgba($brand-white, .06);
				}
			}
		}
	}
	
	
	@media screen and (max-width: $bp-medium) {
		right: 0;
		left: auto;
		transform: translateX(176px);
		
		.active-section & {
			pointer-events: none;
			display: none;
		}
		
		.#{$baseclass}__inner {
			margin-top: 84px;
			pointer-events: none;
		}
		
		.#{$baseclass}__item {
			height: 30px;
		}
		
		.#{$baseclass}__dash {
			width: 32px;
			transform-origin: right;
		}
		
		.#{$baseclass}__label {
			margin-right: 0px;
		}
		
		&.#{$baseclass}--open {
			transform: none;
			
			.#{$baseclass}__inner {
				background: $brand-white;
			}
			
			.#{$baseclass}__item {
				height: 48px;
				padding-right: 24px;
			}
		}

		.#{$scrolling-section-classname}--black & {
			&--open {
				.#{$baseclass}__inner {
					background: $brand-black;
				}
			}
		}
	}
}
