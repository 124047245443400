@media screen and (max-width: $bp-xxlarge) {
	.h1 {
		font-size: px-to-rem(128px);
	}

	.h2 {
		font-size: px-to-rem(112px);
	}

	h3 {
		font-size: px-to-rem(96px);
	}

	section {
		padding: 104px 0;
	}

	.grid {
		padding: 0 72px;
	}

	@include grid-at-bp(xxlarge);
}

@media screen and (max-width: $bp-xlarge) {
	.h1 {
		font-size: px-to-rem(120px);
	}

	.h2 {
		font-size: px-to-rem(104px);
	}

	h3 {
		font-size: px-to-rem(88px);
	}

	section {
		padding: 108px 0;
	}

	.grid {
		padding: 0 72px;
	}

	@include grid-at-bp(xlarge);
}

@media screen and (max-width: $bp-large) {
	.h1 {
		font-size: px-to-rem(112px);
	}

	.h2 {
		font-size: px-to-rem(96px);
	}

	blockquote {
		font-size: px-to-rem(21px);
	}

	section {
		padding: 96px 0;
	}

	.grid {
		padding: 0 60px;
	}

	@include grid-at-bp(large);
}

@media screen and (max-width: $bp-medium) {
	body {
		cursor: auto !important;
	}

	.cursor {
		display: none;
	}

	.active-section .logo {
		display: none;
	}

	.grid {
		padding: 0 48px;
	}

	.clip-wrapper__left {
		width: 48px;
	}

	.grid {
		&--lines {
			// padding-right: 48px;
		}

		&__line {
			&:first-child {
				border-left: solid 1px;
			}
			&:last-child {
				border-right: solid 1px;
			}
		}

		&__row {
			margin-bottom: 24px;
		}
	}

	.#{$scrolling-section-classname} {
		&__number {
			transform: none;
			height: auto;
		}
	}

	section {
		padding: 60px 0;

		.spacer {
			&__sm {
				min-height: 12px;
			}
			&__md {
				min-height: 24px;
			}
			&__lg {
				min-height: 48px;
			}
		}

		.shift {
			&--left {
				transform: none;
			}
		}

		.mr {
			margin-right: 0;
		}
	}

	.h1 {
		font-size: px-to-rem(72px);
		margin-left: 0;
		margin-bottom: 24px;
		letter-spacing: $letter-spacing-xs;
	}

	.h2 {
		font-size: px-to-rem(60px);
		margin-left: 0;
		margin-bottom: 18px;
	}

	h3 {
		font-size: px-to-rem(40px);
		margin-bottom: 12px;
	}

	h4 {
		font-size: px-to-rem(32px);
		margin-bottom: 9px;
	}
	@include grid-at-bp(medium);
}


@media screen and (max-width: $bp-small) {
	.h1 {
		font-size: px-to-rem(56px);
	}

	.h2 {
		font-size: px-to-rem(48px);
	}

	h3 {
		font-size: px-to-rem(40px);
	}

	h4 {
		font-size: px-to-rem(30px);
	}
	
	h5 {
		font-size: px-to-rem(17px);
	}

	// h6 {
	// 	font-size: px-to-rem(12px);
	// }
		
	blockquote {
		font-size: px-to-rem(19px);
		margin-bottom: 26px;
	}

	p {
		font-size: px-to-rem(15px);
	}
}


@media screen and (max-width: $bp-xsmall) {
	.h1 {
		font-size: px-to-rem(48px);
	}

	.h2 {
		font-size: px-to-rem(44px);
	}

	h3 {
		font-size: px-to-rem(40px);
	}

	h4 {
		font-size: px-to-rem(28px);
	}
}
