$baseclass: nav-toggle;

@keyframes increasingNum {
	0% {
		opacity: 0;
		transform: translate3d(0,60px,0);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes decreasingNum {
	0% {
		opacity: 0;
		transform: translate3d(0,-60px,0);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

.#{$baseclass} {
	position: fixed;
	right: 0;
	top: 24px;
	// transform: translateY(-50%);
	width: 10 * $rem-base;
	height: 120px;
	pointer-events: auto;
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@include zIndexClosest();

	.active-section & {
		display: flex;
	}

	h4, h5, h6, p {
		line-height: 20px;
	}

	.#{$baseclass}__abbreviation, .#{$baseclass}__count {
		transition-timing-function: $easing2;
		transition-duration: $duration-short;
		transition-property: transform, opacity;
	}
	
	.#{$baseclass}__count {
		overflow: hidden;
		transition-delay: $delay-medium;

		span {
			transition: transform $easing2 $duration-long;
			display: inline-block;
			animation: none;

			&:last-child {
				transition-delay: $delay-short;
			}			
		}
	}

	.#{$baseclass}__count {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 20px;

		h4, h5, h6 {
			// margin: 0px 0;
		}
	}

	&__hamburger {
		display: flex;
		width: 100%;
		height: auto;
		flex-direction: column;
		justify-content: center;
		padding-top: 18px;
		padding-bottom: 24px;
	}

	&__line {
		transition: transform $easing2 $duration-medium, background-color $easing2 $duration-medium;
		background-color: $brand-black;
		height: 1px;
		border-radius: 1px;
		width: 100%;

		&:first-child, &:last-child {
			opacity: 0;
		}
	}


	.#{$scrolling-section-classname}--black & {
		.#{$baseclass}__line {
			background-color: $brand-white;
		}
	}

	&--hovering {
		.#{$baseclass}__hamburger {
			// padding: 0;
			// height: 18px;
			min-height: 18px;
		}
		.#{$baseclass}__line {					
			opacity: 1;

			&:first-child {
				transform: translate3d(0,-10px,0);
			}
			&:last-child {
				transform: translate3d(0,10px,0);
			}
		}

		.#{$baseclass}__abbreviation, .#{$baseclass}__count {
			opacity: 0;
		}
		.#{$baseclass}__abbreviation {
			transform: translate3d(0,-12px,0);
		}
		.#{$baseclass}__count {
			transform: translate3d(0,12px,0);
		}
	}
	

	&--menuOpen {
		.#{$baseclass}__hamburger {			
			.#{$baseclass}__line {
				background-color: rgba($brand-black, 1);
				// transition-duration: $duration-long;
				opacity: 1;
				// height: 2px;

				&:first-child {
					transform: translate3d(2px,0px,0) rotate(135deg) scaleX(.72);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:last-child {
					transform: translate3d(2px,-2px,0) rotate(45deg) scaleX(.72);
				}
			}
		}
		.#{$baseclass}__abbreviation, .#{$baseclass}__count {
			opacity: 0;
		}
		.#{$baseclass}__abbreviation {
			transform: translate3d(0,-12px,0);
		}
		.#{$baseclass}__count {
			transform: translate3d(0,12px,0);
		}
	}


	// &--secondaryPanelOpen.#{$baseclass}--menuOpen {
	// 	.#{$baseclass}__hamburger {
	// 		.line {
	// 			background: rgba($brand-black, 1);
	// 			opacity: 1;

	// 			&:first-child {
	// 				transform: translate3d(12px,6px,0) rotate(135deg) scaleX(0.2);
	// 			}
	// 			&:nth-child(2) {
	// 				transform: translate3d(4px,0,0) scaleX(0.3);
	// 				opacity: 1;
	// 			}
	// 			&:last-child {
	// 				transform: translate3d(12px,-6px,0) rotate(45deg) scaleX(0.2);
	// 			}
	// 		}
	// 	}
	// }

	

	@media screen and (max-width: $bp-medium) {
		top: 12px;
		width: 49px;
		height: 90px;

		.#{$baseclass}__hamburger {
			padding-top: 12px;
			padding-bottom: 16px;
		}
		.#{$baseclass}__abbreviation {
		}

		.#{$baseclass}__count {
		}
		
		.#{$baseclass}--menuOpen {
			opacity: 0;
		}
	}
}
