$baseclass: sandbox;

[class*="react-sandbox-editor"] {
	&.#{$baseclass} {
		&__header {
			background: lighten($brand-black, 6%);
		}
		&__tab {
			font-family: $font-primary;

			&:hover {
				color: $brand-red;
			}
		}
		&__tab--selected {
			color: $brand-red;
		}
		&__indicator {
			background-color: $brand-red;
			height: 3px;
			transition-timing-function: $easing2;
			transition-duration: $duration-medium;
		}
	}

	.ace_print-margin {
		display: none;
	}
}

