$baseclass: text-link;

.#{$baseclass} {
	position: relative;
	display: inline-flex;
	flex-direction: column;

	&__line-container {
		height: 1px;
		width: 100%;
		display: block;
		overflow: hidden;
		background: rgba($brand-black, .10);
		position: absolute;
		bottom: 0;
		left: 0;
	}

	&__line {
		background: $brand-black;
		height: 100%;
		width: 100%;
		display: block;
		transform: scaleX(0);
		transform-origin: right;
		transition: transform $easing2 $duration-long;
	}

	.#{$scrolling-section-classname}--black & {
		.#{$baseclass}__line-container {
			background: rgba($brand-white, .24);
		}
		.#{$baseclass}__line {
			background: $brand-white;
		}
	}

	&.#{$baseclass}--hide-underline {
		.#{$baseclass}__line-container {
			background: transparent;
		}
	}

	&.#{$baseclass}--hide-line {
		.#{$baseclass}__line-container {
			height: 0;
		}
	}

	&--hovered {
		.#{$baseclass}__line {
			transform: scaleX(1);
			transform-origin: left;
		}
	}

	&--disabled {
		pointer-events: none;
	}
}