$image-baseclass: image-wrapper;

.#{$image-baseclass} {
	position: relative;
	display: block;
	height: 0;
	overflow: hidden;
	display: flex;
	justify-content: center;
	border: solid 2px;

	img {
		display: block;
		height: 100%;
		width: auto;
		position: absolute;
		top: 0;
		transition: $easing2 $duration-long;
		opacity: 0;
	}

	.#{$scrolling-section-classname} & {
		background-color: mix($brand-white, $brand-black, 98%);
		border-color: mix($brand-white, $brand-black, 94%);
	}

	.#{$scrolling-section-classname}--black & {
		background-color: mix($brand-white, $brand-black, 2%);
		border-color: mix($brand-white, $brand-black, 8%);
	}

	&--loaded {
		.#{$scrolling-section-classname} & {
			border-color: transparent !important;
			background-color: transparent;
		}

		img {
			opacity: 1;
		}
	}
}