@keyframes drawInOut {
	0%, 30% {
		transform-origin: bottom;
		transform: scaleY(1);
	}
	45% {
		transform-origin: bottom;
		transform: scaleY(0);
	}
	65% {
		transform: scaleY(0);
		transform-origin: top;
	}
	80%, 100% {
		transform: scaleY(1);
		transform-origin: top;
	}
}

$baseclass: scroll-arrow;
$arrow-size: 40px;

.#{$baseclass} {
	pointer-events: auto;
	position: fixed;
	// top: calc(100vh - 90px);
	bottom: 0;
	left: 50%;
	height: 90px;
	width: 80px;
	transform: translateX(-40px);
	display: none;
	align-items: center;
	flex-direction: column-reverse;
	white-space: pre;
	@include zIndexClose();

	.active-section & {
		display: flex;
	}

	h1, h2, h3, h4, h5, h6 {
		span {
			display: inline-block;
		}

		> span {
			overflow: hidden;

			&:first-child {
				margin-right: 6px;
			}

			> span {
				transform: none;
				transition: transform $duration-long $easing2;

				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						transition-delay: (#{0.6 + ($i * 0.09)}s);
					}
				}
			}
		}
	}

	&__seperator-container {
		background: rgba($brand-black, 0);
		height: 60px;
		width: 1px;
		transform-origin: bottom;
		transform: scaleY(1);
		transition: transform $easing2 $duration-long $duration-short;
	}

	&__seperator {
		background: $brand-black;
		height: 100%;
		width: 100%;
		animation: drawInOut $easing5 3s infinite;
		animation-delay: $duration-longer;
	}

	.#{$scrolling-section-classname}--black & {
		.#{$baseclass}__seperator-container {
			background: rgba($brand-white, .24);
		}

		.#{$baseclass}__seperator {
			background: $brand-white;
		}
	}

	&--hidden {
		pointer-events: none;
		
		h1, h2, h3, h4, h5, h6 {			
			span > span {
				transition: transform $duration-long $easing1;
				transform: translateY(24px);

				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						transition-delay: (#{0.0 + ($i * 0.09)}s);
					}
				}
			}
		}
		.#{$baseclass}__seperator-container {
			transform: scaleY(0);
			transition: transform $easing3 $duration-long;
		}
		.#{$baseclass}__seperator {
			animation: none;
		}
	}

	@media screen and (max-width: $bp-medium) {
		position: fixed;

		h1, h2, h3, h4, h5, h6 {
			margin-bottom: 3px;
		}
		.#{$baseclass}__seperator-container {
			height: 48px;
		}
	}
}
