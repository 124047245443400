$baseclass: side-scroller;

$gradient: linear-gradient(to right, transparent, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, transparent);

.#{$baseclass} {
	width: 100%;

	&__items {
		overflow-x: scroll;
		flex-wrap: nowrap;
		width: 100%;
		margin: 0 auto;
		justify-content: flex-start;
		-webkit-mask-image: $gradient;
	}

	&__controls {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 24px;
	}
}
