$bp-xxlarge: 1336px;
$bp-xlarge: 1280px;
$bp-large: 1024px;
$bp-medium: 800px;
$bp-small: 480px;
$bp-xsmall: 320px;

$rem-base: 8px;

// $brand-red: #ff3636;
$brand-red: #f63f43;
// $brand-red: #ff483c;
// $brand-red: #ff4716;
// $brand-red: #e04e47;

// $brand-orange: #ff6d2f;
$brand-orange: #f67e0d;

// $brand-yellow: #F3B600;
$brand-yellow: #efbb29;
// $brand-yellow: #F4E285;

// $brand-green: #7EAF53;
$brand-green: #12ab2e;

$brand-purple: #7366FB;

// $brand-pink: darken(#FED1CF,6%);
$brand-pink: #ef9b97;

// $brand-blue: #00CAE0;
$brand-blue: #0b67de;

$brand-teal: #00D9B6;;

$brand-grey-dark: #232021;
$brand-white: lighten(#F8FBF7, 2%);

$brand-grey-darkest: darken(#232021, 3%);
$brand-black: darken(#232021, 6%);

$brand-grey: mix($brand-grey-dark, $brand-white, 82%);

$brand-grey-light: mix($brand-white, $brand-black, 88%);
$brand-grey-lightest: mix($brand-white, $brand-black, 94%);
// $brand-grey-lightest: #B6B7AC;


// $letter-spacing-xxs: -0.048em;
// $letter-spacing-xs: -0.036em;
// $letter-spacing-sm: -0.024em;
// $letter-spacing-md: -0.012em;
// $letter-spacing-lg: 0.012em;
// $letter-spacing-xl: 0.024em;
// $letter-spacing-xxl: 0.060em;

// rem spacing
$letter-spacing-xxs: px-to-rem(-1.6px);
$letter-spacing-xs: px-to-rem(-1.2px);
$letter-spacing-sm: px-to-rem(-0.8px);
$letter-spacing-md: px-to-rem(0px);
$letter-spacing-lg: px-to-rem(0.2px);
$letter-spacing-xl: px-to-rem(0.4px);
$letter-spacing-xxl: px-to-rem(1.6px);

$easing1: cubic-bezier(0.215, 0.61, 0.355, 1);
$easing2: cubic-bezier(.19,1,.22,1);
$easing3: cubic-bezier(0.86, 0, 0.07, 1);
$easing4: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easing5: cubic-bezier(0.7, 0, 0.3, 1);
$easing6: cubic-bezier(.77,0,.175,1);

$duration-short: 0.3s;
$duration-medium: 0.6s;
$duration-long: 0.9s;
$duration-longer: 1.2s;
$duration-longest: 1.8s;

$delay-short: 0.06s;
$delay-medium: 0.09s;
$delay-long: 0.12s;
$delay-longer: 0.18s;
$delay-longest: 0.24s;

$scrolling-section-classname: "scrolling-section";

