$baseclass: carousel;

.#{$baseclass} {
	position: relative;

	.#{$arrow-group-baseclass} {
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(0,-100%);
		padding-bottom: 16px;

		.#{$arrow-group-baseclass}__first,
		.#{$arrow-group-baseclass}__second {
			opacity: 0;
			transition: opacity $duration-medium $easing2;
		}

		@for $i from 1 through 2 {
			.#{$arrow-group-baseclass}__first, .#{$arrow-group-baseclass}__second {
				&:nth-child(#{$i}) {
					transition-delay: (#{0.6 + ($i * 0.18)}s);							
				}
			}
		}
	}

	&--nav-bottom {
		.arrow-group {
			left: 0;
			bottom: 0;
			right: auto;
			top: auto;
			transform: translate(0, 100%);
			padding-top: 16px;
		}
	}

	&__item-wrapper {
		overflow: hidden;
	}

	&__items, &__item {
		transition: transform $easing5 $duration-long;
	}

	&__items {
		display: flex;
		flex-wrap: nowrap;
	}

	&__item {
		min-width: 100%;
	}

	.active-section & {
		.arrow-group__first,
		.arrow-group__second {
			opacity: 1;
		}
	}
}
