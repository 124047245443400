$baseclass: project-card;

.#{$baseclass} {
	margin-bottom: 12px;
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	// opacity: 0.6;
	// overflow: hidden;
	// background: $brand-black;

	a {
		border: none;
		color: $brand-black;
		display: flex;
		align-items: center;
	}

	h1, h2, h3, h4, h5, h6 {
		color: transparent;
	}

	&__tags {
		opacity: 0;
		display: none;
	}

	&,
	&__tags, 
	&__asset {
		transition: all $easing4 $duration-medium;
	}

	&__bottom {
		position: relative;
		opacity: 0.6;
		display: block;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		@include zIndexClose;
	}

	&__arrow {
		position: absolute;
		right: 0;
		top: 0;
		transform: translateX(100%) rotate(-45deg);
	}

	&__asset {
		position: absolute;
		opacity: 0;
		pointer-events: none;
		width: 100%;
		@include zIndexFar;

		img, video {
			transition-delay: $delay-short;
		}
	}

	.active-section & {
		&__asset {
			img, video {
				transform: none;
			}
		}
	}

	&--hovered {

		.#{$baseclass}__asset {
			opacity: 0.9;
		}
		.#{$baseclass}__bottom {
			opacity: 1;

			h2, h3, h4, h5, h6 {
				color: $brand-white;
			}
		}
	}

	// &:nth-child(even) {
	// 	.#{$baseclass}__asset {
	// 		transform: translateX(37.5%);
	// 	}
	// }

	@media screen and (max-width: $bp-medium) {
		// margin: 0;

		.#{$baseclass}__bottom {
			// padding: 24px 0;
			// padding-bottom: 40px;
		}

		i {
			right: 0;
		}
	}
}
