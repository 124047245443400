$font-primary: 'Lausanne', sans-serif;
$font-secondary: 'Lausanne Extended', sans-serif;

$goldenRatio: 1.61803398875;

body, button, input, select, textarea {
	font-variant-ligatures: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeSpeed;
	font-family: $font-primary;
	letter-spacing: -0.006em;
}

html {
	font-size: $rem-base;
}

.h1, .h2, h3, h4, h5, h6, blockquote, p {
	color: inherit;

	&.uppercase {
		font-family: $font-secondary;
		letter-spacing: $letter-spacing-lg;
		text-transform: uppercase;
	}

	&.initial-caps {
		text-transform: capitalize;
	}

	&.mr {
		margin-right: 8.3333%;
	}

	&.light {
		font-weight: 400;
	}

	&.fade {
		.active-section section & {
			opacity: 0.48;
		}
	}

	.outline {
		color: transparent;
		-webkit-text-stroke: 1px;
	}
}

.h1, .h2, h3, h4, h5, h6 {
	position: relative;

	span {
		display: inline-block;
		white-space: pre;
	}
}

.h1, .h2, h3, h4 {
	font-weight: 400;
}

h5, h6 {
	font-family: $font-secondary;
}

.h1 {
	font-size: px-to-rem(128px);
	line-height: 1;
	margin-bottom: 48px;
	letter-spacing: $letter-spacing-xxs;
}

.h2 {
	font-size: px-to-rem(112px);
	line-height: 1;
	margin-bottom: 36px;
	letter-spacing: $letter-spacing-xs;
}

h3 {
	font-size: px-to-rem(104px);
	line-height: 1;
	margin-bottom: 24px;
	letter-spacing: $letter-spacing-sm;
}

h4 {
	font-size: px-to-rem(40px);
	line-height: 1;
	margin-bottom: 16px;
	letter-spacing: $letter-spacing-md;
}

h5 {
	font-size: px-to-rem(19px);
	line-height: 1.3;
	margin-bottom: 8px;
	letter-spacing: $letter-spacing-md;
}

h6 {
	font-size: px-to-rem(15px);
	line-height: 1.3;
	margin-bottom: 8px;
}

p, blockquote {
	white-space: pre-wrap;
}

p {
	font-size: px-to-rem(17px);
	line-height: $goldenRatio;
	margin-bottom: 24px;
	letter-spacing: $letter-spacing-lg;
}

blockquote {
	font-size: px-to-rem(22px);
	line-height: $goldenRatio;
	margin-bottom: 36px;
	letter-spacing: $letter-spacing-lg;
}

figcaption {
	font-size: px-to-rem(14px);
	margin-top: 24px;
}

.drop-caps {
	display: inline-block;

	&:first-letter {
		float: left;
		font-size: 60px;
		font-family: $font-primary;
		font-weight: 800;
		margin: 0 24px 0 0;
		padding-top: 9px;
		line-height: 48px;
	}
}
