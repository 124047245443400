$colors: (
  brand-red: $brand-red,
  brand-orange: $brand-orange,
  brand-yellow: $brand-yellow,
  brand-green: $brand-green,
  brand-purple: $brand-purple,
  brand-pink: $brand-pink,
  brand-blue: $brand-blue,
  brand-teal: $brand-teal
);

/*
create color utility classes in the format

.inherit--brand-[COLOR]
.inherit-bg--brand-[COLOR]
.inherit-outline--brand-[COLOR]
*/

.inherit {
  @each $key, $val in $colors {
    &--#{$key} {
      color: $val;

      .outline {
        -webkit-text-stroke-color: $val;
      }
    }
    &-bg--#{$key} {
      background-color: $val;
    }
  }
}

/* Create CSS Variables */
:root {
  @each $key, $val in $colors {
    --#{$key}: #{$val};
  }
}
